import { useState, useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./Refresh.css";

export const Refresh = ({ onClick }) => {
  const [isRotating, setIsRotating] = useState(false);

  const handleClick = () => {
    setIsRotating(true);
    onClick();
  };

  useEffect(() => {
    if (isRotating) {
      const timer = setTimeout(() => {
        setIsRotating(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isRotating]);

  return (
    <RefreshIcon
      onClick={handleClick}
      className={`cursor-pointer ${isRotating ? "rotate-on-click" : ""}`}
    />
  );
};
