const ignoreOrderCompareArrays = (a, b) => {
    if(!(a instanceof Array && b instanceof Array)) return false;
    if (a.length !== b.length) return false;
    const elements = new Set([...a, ...b]);
    for (const x of elements) {
        const count1 = a.filter(e => ignoreOrderCompare(e, x)).length;
        const count2 = b.filter(e => ignoreOrderCompare(e, x)).length;
        if (count1 !== count2) return false;
    }
    return true;
}

const ignoreOrderCompareObjects = (a, b) => {
    if(Object.keys(a).length !== Object.keys(b).length) return false;
    const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
    for (const key of keys) {
        const valueA = a[key];
        const valueB = b[key];
        const match = ignoreOrderCompare(valueA, valueB);
        if(!match) return false;
    }
    return true;
}

export const ignoreOrderCompare = (a, b) => {
    if(a instanceof Array && b instanceof Array) {
        return ignoreOrderCompareArrays(a, b);
    } else if(a instanceof Object && b instanceof Object) {
        return ignoreOrderCompareObjects(a, b);
    } else {        
        return a === b;
    }
}

export const ignoreOrderCompareWithPerformanceTest = (a, b) => {
    let startTime = performance.now();
    const isEqual = ignoreOrderCompare(a, b);
    let endTime = performance.now();
    let timeElapsed = endTime - startTime;
    console.log("compare", a, "to", b, "took", timeElapsed, "milliseconds");
    return isEqual;
}

export const testEqualityChecker = () => {

    console.log("------------------------------------------------------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
 
    if(!ignoreOrderCompare([1,3,6], [6,3,1])) console.log("FAIL array 1");
    if(ignoreOrderCompare([1,3,6,6], [6,3,1])) console.log("FAIL array 2");
    if(!ignoreOrderCompare([], [])) console.log("FAIL array empty");
    if(!ignoreOrderCompare([{test: "string"}], [{test: "string"}])) console.log("FAIL array objects 1");
    if(ignoreOrderCompare([{test: "string1"}], [{test: "string2"}])) console.log("FAIL array objects 2");
    if(ignoreOrderCompare([{key1: "string"}], [{key2: "string"}])) console.log("FAIL array objects 3");
    if(!ignoreOrderCompare([{array: ["string1", "string2"]}], [{array: ["string2", "string1"]}])) console.log("FAIL array object with arrays");
    if(ignoreOrderCompare([{array: ["string1", "string2"]}], [{array: ["string6", "string1"]}])) console.log("FAIL array object with arrays2");
    
    if(!ignoreOrderCompare("string", "string")) console.log("FAIL string compare")
    if(ignoreOrderCompare("string1", "string2")) console.log("FAIL string compare")
    if(!ignoreOrderCompare(3, 3)) console.log("FAIL int compare")
    if(ignoreOrderCompare(2, 4)) console.log("FAIL int compare 2")
    if(ignoreOrderCompare("string", 3)) console.log("FAIL string to int compare")
    if(ignoreOrderCompare("string1", {string: "string2"})) console.log("FAIL string to object compare")
    if(!ignoreOrderCompare({test: "string"}, {test: "string"})) console.log("FAIL string object compare 1")
    if(ignoreOrderCompare({test: "string1"}, {test: "string2"})) console.log("FAIL string object compare 2")
    if(!ignoreOrderCompare({array: ["string1", "string2"]}, {array: ["string2", "string1"]})) console.log("FAIL object with arrays");
    if(ignoreOrderCompare({array: ["string1", "string4"]}, {array: ["string2", "string1"]})) console.log("FAIL object with arrays 2");
    if(!ignoreOrderCompare({array: ["string1", {count: 4}]}, {array: [{count: 4}, "string1"]})) console.log("FAIL object with arrays with objects");
    if(ignoreOrderCompare({array: ["string1", {count: 4}]}, {array: [{count: 6}, "string1"]})) console.log("FAIL object with arrays with objects 2 ");
    if(ignoreOrderCompare({array: ["string1", {count: 4}]}, {array: ["string2", "string1", {count: 4}]})) console.log("FAIL object with arrays with objects 3");
    console.log("------------------------------------------------------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
}
