import { useEffect } from "react";

/**
 * Hook that triggers a callback when scrolling outside of the referenced element(s)
 *
 * @param {Array|Object} refs - Ref(s) of your element(s)
 * @param {Function} callback - Callback which can be used to handle the scroll event
 */
const useOutsideScroll = (refs, callback) => {
  useEffect(() => {
    const handleScrollOutside = (evt) => {
      var refsList = refs instanceof Array ? refs : [refs];
      var refScrolled = false;

      refsList.forEach((ref) => {
        if (ref.current && ref.current.contains(evt.target)) {
          refScrolled = true;
        }
      });

      if (!refScrolled) {
        callback();
      }
    };

    document.addEventListener("scroll", handleScrollOutside, true); // use capture phase

    return () => {
      document.removeEventListener("scroll", handleScrollOutside, true);
    };
  }, [refs, callback]);
};

export default useOutsideScroll;
