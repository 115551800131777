import { useEffect, useRef } from "react";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import useOutsideClick from "../../../helperFunctions/useOutsideClick";

export const DeepPopper = ({ children, open, anchorEl, closeHandler }) => {
  const popperRef = useRef(null);
  useOutsideClick(popperRef, closeHandler);
  useEffect(() => {
    if (open) {
      document.addEventListener("scroll", closeHandler, true);
      return () => {
        document.removeEventListener("scroll", closeHandler, true);
      };
    }
  }, [open, closeHandler]);

  return (
    <Popper
      open={open}
      onClose={closeHandler}
      placement="bottom"
      anchorEl={anchorEl}
      ref={popperRef}
    >
      <Paper>{children}</Paper>
    </Popper>
  );
};
