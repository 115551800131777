// used to interact with out object s3 storage on contabo
import AWS from 'aws-sdk';
import {
  getEnvironmentDependentStringVariable,
  getStringEnvVariable,
  isProduction,
} from "../../helperFunctions/envVars";

// TODO create small toasts to notify the user on success and error upload/delete and so on of objects (in the first case images, then df, ebook.. and so on)

// TODO make a class, so that we initialize the object with endpoint + bucket

/**
 * @param {string} endpoint - Should look like: https://eu2.contabostorage.com/bucketname
 * @param {string} accessKeyId - Access Key, found in env variable.
 * @param {string} secretKey - Secret Key, found in env variable. Key, found in env variable.
 */
class S3Storage {
  s3url = getStringEnvVariable("REACT_APP_S3_URL");
  fallBackBucketName = isProduction() ? "deepreadidea" : "deepreadtest";
  defaultBucketName = getEnvironmentDependentStringVariable(
    "REACT_APP_BUCKETNAME",
    this.fallBackBucketName
  );
  endpoint = this.s3url + `/` + this.defaultBucketName;

  constructor() {
    const accessKeyId = getStringEnvVariable("REACT_APP_S3_ACCESS_KEY");
    const secretAccessKey = getStringEnvVariable("REACT_APP_S3_SECRET_KEY");
    const endpoint = this.endpoint;

    this.s3 = new AWS.S3({
      endpoint, // e.g. https://eu2.contabostorage.com/bucketname
      accessKeyId,
      secretAccessKey,
      s3BucketEndpoint: true,
      signatureVersion: "v4", // needed for url generation
    });
  }

  /**
   * Gets the endpoint (with the default bucket)
   * @returns default endpoint
   */

  getEndpoint() {
    return this.endpoint;
  }

  // ---------------------------- Object Logic ------------------------------------ //

  /**
   * Gets the content of an object from an S3 bucket using default bucket name.
   *
   * @param {string} key - The key (path) to the object within the bucket.
   * @returns {Promise<string>} A promise that resolves with the content of the object.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async getObject(key) {
    return await this.getObject(this.defaultBucketName, key);
  }

  /**
   * Gets the content of an object from an S3 bucket.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} key - The key (path) to the object within the bucket.
   * @returns {Promise<string>} A promise that resolves with the content of the object.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async getObjectInBucket(bucketName, key) {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    try {
      const data = await this.s3.getObject(params).promise();
      return data.Body;
    } catch (error) {
      console.error("Error getting object from S3:", error);
      throw error;
    }
  }

  /**
   * Uploads an object to the default S3 bucket.
   *
   * @param {string} key - The key (path) to the object within the bucket.
   * @param {string} data - The data to be uploaded as the content of the object.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async putObject(key, data) {
    await this.putObjectInBucket(this.defaultBucketName, key, data);
  }

  /**
   * Uploads an object to an S3 bucket.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} key - The key (path) to the object within the bucket.
   * @param {string} data - The data to be uploaded as the content of the object.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async putObjectInBucket(bucketName, key, data) {
    const params = {
      Body: data,
      Bucket: bucketName,
      Key: key,
    };
    try {
      await this.s3.putObject(params).promise();
    } catch (error) {
      console.error("Error putting object to S3:", error);
      throw error;
    }
  }

  /**
   * Deletes an object from the default S3 bucket.
   *
   * @param {string} key - The key (path) to the object within the bucket.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async deleteObject(key) {
    await this.deleteObjectInBucket(this.defaultBucketName, key);
  }

  /**
   * Deletes an object from an S3 bucket.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} key - The key (path) to the object within the bucket.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async deleteObjectInBucket(bucketName, key) {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    try {
      await this.s3.deleteObject(params).promise();
    } catch (error) {
      console.error("Error deleting object from S3:", error);
      throw error;
    }
  }

  /**
   * Lists objects in an S3 bucket.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @returns {Promise<Array<Object>>} A promise that resolves with an array of objects in the bucket.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async listObjects(bucketName) {
    const params = {
      Bucket: bucketName,
    };

    try {
      const data = await this.s3.listObjects(params).promise();
      return data.Contents;
    } catch (error) {
      console.error("Error listing objects in S3 bucket:", error);
      throw error;
    }
  }

  /**
   * Generates the URL for accessing an object within an S3 bucket - Not to be used.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} key - The key (path) to the object within the bucket.
   * @returns {string} The URL for accessing the object.
   */
  getObjectUrl(bucketName, key) {
    return `${this.s3.config.endpoint}/${key}`;
  }

  /**
   * Generates the URL for default 15mins access to an object within an S3 bucket.
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} key - The key (path) to the object within the bucket.
   * @param {string} exprirationTime - Expiration time (in seconds) as optional parameter, default 900s.
   * @returns {string} The URL for accessing the object.
   */
  getSignedUrl(bucketName, key, exprirationTime = 900) {
    const signedUrl = this.s3.getSignedUrl("getObject", {
      Bucket: bucketName,
      Key: key,
      Expires: exprirationTime,
    });
    return signedUrl;
  }

  // ---------------------------- Bucket Logic ------------------------------------ //

  /**
   * Creates a new S3 bucket with the specified name.
   *
   * @param {string} bucketName - The name of the new S3 bucket.
   * @returns {Promise<void>} A promise that resolves once the bucket is created.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async createBucket(bucketName) {
    const params = {
      Bucket: bucketName,
      ACL: "private", // You can adjust the ACL (Access Control List) as needed
    };

    try {
      await this.s3.createBucket(params).promise();
    } catch (error) {
      console.error("Error creating S3 bucket:", error);
      throw error;
    }
  }

  /**
   * Deletes an S3 bucket and its contents.
   *
   * @param {string} bucketName - The name of the S3 bucket to delete.
   * @returns {Promise<void>} A promise that resolves once the bucket is deleted.
   * @throws {Error} If an error occurs during the S3 operation.
   */
  async deleteBucket(bucketName) {
    const params = {
      Bucket: bucketName,
    };

    try {
      await this.s3.deleteBucket(params).promise();
    } catch (error) {
      console.error("Error deleting S3 bucket:", error);
      throw error;
    }
  }

  // ---------------------------- Helpers ------------------------------------ //

  /**
   * Checks if link is storage link
   *
   * @param {string} link - The link to be checked
   * @returns {boolean} True for storage link, false otherwise
   */
  isS3Link(link) {
    return !link.includes("http") && !link.includes("data:image/");
  }

  /**
   * Gets S3 link if link is storage link
   *
   * @param {string} link - The link to be checked
   * @returns {string} S3 link if link is storage link, otherwise just link
   */
  getReloadImageLink = (link) => {
    return this.getReloadImageInBucketLink(this.defaultBucketName, link);
  };

  /**
   * Gets S3 link if link is storage link
   *
   * @param {string} bucketName - The name of the S3 bucket.
   * @param {string} link - The link to be checked
   * @returns {string} S3 link if link is storage link, otherwise just link
   */
  getReloadImageInBucketLink = (bucketName, link) => {
    // check if image contains the http or https in imageLink
    if (this.isS3Link(link)) {
      return this.getSignedUrl(bucketName, link, 10);
    } else {
      return link;
    }
  };
  /**
   * To check if key is in bucket
   *
   * @param {string} key - The key to be checked
   * @returns {list} List of objects with the key and the index where it was found.
   */
  async findObjectInBucket(bucketName, key) {
    const resultList = [];
    const data = await this.listObjects(bucketName);
    data.forEach((object, index) => {
      if (object.Key === key) {
        resultList.push({ key: object.Key, index: index });
      }
    });
    return resultList;
  }
}

export default S3Storage;
