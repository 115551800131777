// src/utils/gtm.js
export const pushToDataLayer = (event, eventData = {}) => {
    window.dataLayer = window.dataLayer || [];
    const dataLayerEvent = {
      event,
      environment: process.env.REACT_APP_NODE_ENV || 'undefined',
      ...eventData,
    };
  
    // Log only in development mode
    if (process.env.REACT_APP_NODE_ENV.toLowerCase() === 'dev') {
      console.log('Pushing to dataLayer:', dataLayerEvent);
    }
  
    window.dataLayer.push(dataLayerEvent);
  };