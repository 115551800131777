import { useEffect, useState, useRef, useCallback } from "react";
import {
  isFirefox,
  isIOS,
  isMobile,
  isSafari,
  isTablet,
} from "react-device-detect";
import { Dialog, DialogActions, Button, Paper, styled } from "@mui/material";

import { store } from "../Utils/Store/Store";
import { selectIdeaCard } from "../Utils/Features/librarySlice";
import { updatePersistentDrawer } from "../Utils/Features/persistentDrawerSlice";
import {
  setCurrentTab,
  updateIdentifyIdeaCardData,
} from "../Utils/Features/IdentifyIdeaCardSlice";

import { getLabelId } from "./getIdeacardIcons";

const useClickMenuNoContext = (isMobile || isTablet) && (isFirefox || isIOS);

let savedSelection = null;
let savedSelectionText = null;
let savedLocation = null;
let savedHighlightId = null;
let savedHighlightDataset = null;
let fullHighlightText = null;
let setCreateDialogOpen = null;

const clearSelection = () => {
  savedSelection = null;
  savedSelectionText = null;
  fullHighlightText = null;
  savedHighlightId = null;
  savedHighlightDataset = null;
  window.getSelection().removeAllRanges();
};

const saveSelection = () => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    savedSelection = selection.getRangeAt(0).cloneRange();
    savedSelectionText = selection.toString();
    const element = selection.anchorNode?.parentElement;
    savedLocation = element.getBoundingClientRect();

    // Save the full highlight text
    let highlightElement = savedSelection.startContainer;
    while (
      highlightElement &&
      !highlightElement.classList?.contains("highlightLi")
    ) {
      highlightElement = highlightElement.parentElement;
    }
    if (highlightElement) {
      const highlightSpan = highlightElement.querySelector(".highlightSpan");
      if (highlightSpan) {
        fullHighlightText = highlightSpan.textContent;
      }
    }
  }
};

const restoreUserSelection = () => {
  if (savedSelection) {
    const sel = window.getSelection();
    sel.removeAllRanges();
    if (isFirefox) {
      setTimeout(() => {
        sel.addRange(savedSelection);
      }, 5);
    } else {
      sel.addRange(savedSelection);
    }
  } else if (savedSelectionText) {
    findAndSelectText(savedSelectionText);
  }
};

const findAndSelectText = (text, isFullHighlight = false) => {
  const textNodes = [];
  const walk = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    null,
    false
  );
  let node = walk.nextNode();
  while (node) {
    textNodes.push(node);
    node = walk.nextNode();
  }

  for (let i = 0; i < textNodes.length; i++) {
    const node = textNodes[i];
    if (!isNodeInIdeaCard(node) || !isFullHighlight) {
      const index = node.textContent.indexOf(text);
      if (index >= 0) {
        const range = document.createRange();
        range.setStart(node, index);
        range.setEnd(node, index + text.length);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        return true;
      }
    }
  }
  return false;
};

const isNodeInIdeaCard = (node) => {
  let parent = node.parentElement;
  while (parent) {
    if (parent.classList.contains("ideacardDiv")) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
};

const isValidSelection = () => {
  const selection = window.getSelection();
  const isValid =
    selection && selection.toString().trim() !== "" && selection.rangeCount > 0;
  return isValid;
};

const handleTextSelect = (event) => {
  event.preventDefault();
  if (useClickMenuNoContext) {
    const highlightSpan = event.target;

    if (highlightSpan && highlightSpan.classList?.contains("highlightSpan")) {
      savedHighlightId = highlightSpan.id;
      savedHighlightDataset = highlightSpan.dataset;
      const range = document.createRange();
      range.selectNodeContents(highlightSpan);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      saveSelection();

      setCreateDialogOpen(true);
    }
  } else if (isValidSelection()) {
    // saving the selection will not work in chrome or firefox
    // if we save selection for chrome mobile the selection handles are lost
    if (!isMobile && !isTablet) {
      saveSelection();
      if (isFirefox) {
        const highlightSpan = event.target;
        savedHighlightDataset = highlightSpan.dataset;
        savedHighlightId = highlightSpan.id;
      }
    }

    if (setCreateDialogOpen) {
      setCreateDialogOpen(true);
    }
  }
};

export const closeCreateIdeaDialog = () => {
  if (setCreateDialogOpen) {
    setCreateDialogOpen(false);
  }
  cleanupTextSelection();
};

const createIdeaCard = (title) => {
  const createIdeaCardFromTextSelection = () => {
    const selection =
      window.getSelection().toString().length > 0
        ? window.getSelection()
        : savedSelection;
    let itemSelf = selection.anchorNode || savedSelection?.startContainer;
    // selection anchor node is the span element if select all is used
    // otherwise it is the text inside the span and we need to get the parent element
    if (itemSelf?.nodeName !== "SPAN") {
      itemSelf = itemSelf.parentElement;
    }
    const dataSet =
      Object.keys(itemSelf.dataset).length > 0
        ? itemSelf.dataset
        : savedHighlightDataset;

    const start = dataSet.start;
    const book_id = dataSet.book_id;
    const user_id = dataSet.user_id;
    const highlight_id = itemSelf.id || savedHighlightId;
    const context = itemSelf.textContent;
    const ideacardObj = {
      book_id,
      label_id: getLabelId("KEYWORDS"),
      highlight_id,
      title,
      start,
      user_id,
      my_notes: [],
      picture_link: "",
      rating: 0,
      tags: [],
      level: 0,
      end: null,
      description: [{ highlight_id, start, context }],
    };
    if (highlight_id?.length && start?.length) {
      store.dispatch(updateIdentifyIdeaCardData(ideacardObj));
      // remember to clear selection.. :E
      window.getSelection().removeAllRanges();
      store.dispatch(updatePersistentDrawer("identify Ideacard"));
    }
    store.dispatch(setCurrentTab(0));
  };

  if (!title) {
    return false;
  }
  if (title.length >= 0) {
    store.dispatch(selectIdeaCard(null));
    store.dispatch(updateIdentifyIdeaCardData(null));
    createIdeaCardFromTextSelection();
  }
};

const handleEscGlobal = (event) => {
  if (event.keyCode === 27) {
    window.getSelection().removeAllRanges();
  }
};

export const addTextSelection = (selectableRef, setDialogOpen) => {
  const selectableElement = selectableRef?.current;
  setCreateDialogOpen = setDialogOpen;

  if (selectableElement) {
    if (!isMobile && !isTablet) {
      selectableElement.addEventListener("mouseup", handleTextSelect);
    }

    if (useClickMenuNoContext) {
      selectableElement.addEventListener("click", handleTextSelect);
    }

    selectableElement.addEventListener("keydown", handleEscGlobal);

    window.addEventListener("scroll", closeCreateIdeaDialog, true);

    if ((isMobile || isTablet) && !useClickMenuNoContext) {
      document.addEventListener("contextmenu", handleTextSelect);
    }
  }
};

export const cleanupTextSelection = (selectableRef) => {
  const selectableElement = selectableRef?.current;

  if (selectableElement) {
    if (!isMobile && !isTablet) {
      selectableElement.removeEventListener("mouseup", handleTextSelect);
    }

    if (useClickMenuNoContext) {
      selectableElement.removeEventListener("click", handleTextSelect);
    }

    selectableElement.removeEventListener("keydown", handleEscGlobal);

    window.removeEventListener("scroll", closeCreateIdeaDialog, true);

    if ((isMobile || isTablet) && !useClickMenuNoContext) {
      document.removeEventListener("contextmenu", handleTextSelect);
    }
  }
};

const closedPositionStyle = {
  display: "none",
};

export const CreateIdeaDialog = ({ open, handleClose }) => {
  const [positionStyle, setPositionStyle] = useState(closedPositionStyle);
  const dialogRef = useRef();
  const [isPositioned, setIsPositioned] = useState(false);

  const updateDialogPosition = useCallback(() => {
    const selectionToUse =
      window.getSelection().toString().length > 0
        ? window.getSelection().getRangeAt(0)
        : savedSelection;

    if (selectionToUse) {
      const dialogRect = {
        width: 270,
        height: 41,
      };

      const highlightElement =
        window.getSelection().toString().length > 0
          ? window
              .getSelection()
              .anchorNode?.parentElement.getBoundingClientRect()
          : savedLocation;
      const selectionLocation = selectionToUse.getBoundingClientRect();

      const windowWidth = window.innerWidth;
      const dialogWidth = dialogRect.width;
      const dialogHeight = dialogRect.height;

      // Calculate position for the dialog box
      // calculate position halfway or 1/3 to right from the start of selection rect
      // (looked slightly better to me if it was bit more to the left if the selection is almost full highlight wide)
      const divider =
        selectionLocation.width <
        highlightElement.width - highlightElement.width / 4
          ? 2
          : 3;
      let leftPosition =
        selectionLocation.left +
        (selectionLocation.right - selectionLocation.left) / divider;

      // deduct half of the dialogwidth
      leftPosition -= dialogWidth / 2;

      // if dialog fits the window use original position, otherwise align right side of dialog with right side of window
      leftPosition =
        leftPosition + dialogWidth < windowWidth
          ? leftPosition
          : windowWidth - dialogWidth - 30;

      // if dialog fits the window use original position, otherwise align left side of dialog with left side of window
      leftPosition = leftPosition > 10 ? leftPosition : 20;

      //let topPosition = selectionLocation.top - dialogHeight - 10; // Position dialog slightly above the selected text
      let topPosition = highlightElement.top - dialogHeight - 10; // Position dialog slightly above the whole highlight text

      // Ensure dialog is visible and not overlapping text
      if (topPosition < 0) {
        topPosition = highlightElement.bottom + 10; // If too close to top, position below the selection
      }

      setPositionStyle({
        top: `${topPosition}px`,
        left: `${leftPosition}px`,
        height: "auto",
        width: "auto",
      });

      setIsPositioned(true);
    }
  }, []);

  const restoreAndPosition = useCallback(() => {
    restoreUserSelection();
    updateDialogPosition();
  }, [updateDialogPosition]);

  useEffect(() => {
    if (open) {
      // Delay rendering until position is calculated
      requestAnimationFrame(() => {
        updateDialogPosition();
        setIsPositioned(true);
        if (isSafari || isIOS) {
          setTimeout(restoreUserSelection, 0);
        } else {
          //already positioned couple lines up?
          //restoreAndPosition();
          restoreUserSelection();
        }
      });
    } else {
      setPositionStyle(closedPositionStyle);
      setIsPositioned(false);
      clearSelection();
    }
  }, [open, updateDialogPosition, restoreAndPosition]);

  // Add this effect to handle Safari/iOS specific behavior
  useEffect(() => {
    if (open && isPositioned && (isSafari || isIOS)) {
      const timeoutId = setTimeout(restoreUserSelection, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [open, isPositioned]);

  function PaperComponent(props) {
    return (
      <Paper
        {...props}
        sx={{
          margin: 0,
          maxHeight: "100%",
          border: "1px solid var(--fontColor)",
          background: "var(--cardBackgroundColor)",
          "& .MuiDialogActions-root": {
            gap: "10px",
          },
        }}
      />
    );
  }

  const ActionButton = styled(Button)(() => ({
    color: "var(--fontColor)",
    padding: 0,
    textTransform: "none",
    minWidth: "auto",
    whiteSpace: "nowrap",
  }));

  const selectAllText = () => {
    if (fullHighlightText) {
      if (!findAndSelectText(fullHighlightText, true)) {
        // If full highlight text is not found, fall back to the original selection
        restoreUserSelection();
      }
    } else if (
      (savedSelection && savedSelection.startContainer) ||
      window.getSelection().getRangeAt(0)
    ) {
      // we don't save selection for android chrome ?
      let highlightElement =
        savedSelection?.startContainer ||
        window.getSelection().getRangeAt(0).startContainer;
      while (
        highlightElement &&
        !highlightElement.classList?.contains("highlightLi")
      ) {
        highlightElement = highlightElement.parentElement;
      }
      if (highlightElement) {
        const highlightSpan = highlightElement.querySelector(".highlightSpan");
        if (highlightSpan) {
          const range = document.createRange();
          range.selectNodeContents(highlightSpan);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
    // save the selection again so the full highlight is used to create the ideacard not the saved selection
    saveSelection();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      disableEnforceFocus
      hideBackdrop
      PaperComponent={PaperComponent}
      sx={{
        "& .MuiPaper-elevation": {
          margin: 0,
          position: "absolute", // Set the paper to absolute positioning
          transform: "none", // Disable the default centering transform
          ...positionStyle,
        },
        // Ensure the dialog doesn't block interactions with the page
        pointerEvents: "none",
        "& .MuiDialog-paper": {
          pointerEvents: "auto",
        },
      }}
    >
      <div ref={dialogRef}>
        <DialogActions>
          <ActionButton
            onClick={() => {
              clearSelection();
              handleClose();
            }}
          >
            Cancel
          </ActionButton>
          <ActionButton
            onClick={() => {
              navigator.clipboard.writeText(
                savedSelectionText || window.getSelection().toString().trim()
              );
              handleClose();
            }}
          >
            Copy
          </ActionButton>
          {!useClickMenuNoContext && (
            <ActionButton onClick={selectAllText}>Select all</ActionButton>
          )}
          <ActionButton
            onClick={() => {
              createIdeaCard(
                savedSelectionText || window.getSelection().toString().trim()
              );
              handleClose();
            }}
            autoFocus
          >
            Create Idea
          </ActionButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};
