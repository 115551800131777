import { Fragment } from "react";
import { useSelector } from "react-redux";
import "./Overlay.css";

export function Overlay({ isOpen, children }) {
  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );
  
  return (
    <Fragment>
      {isOpen && (
        <div className="overlay">
          <div className="overlay__background" />
          <div className={`overlay__container ${`overlay-container-${lightDarkMode}`}`}>
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Overlay; 