import axios from "axios";
import { getApiUrl } from "./envVars";
import { getHeaders } from "../Utils/Features/authSlice";

const apiEndpoint = getApiUrl();

export const fetchAdjoiningHighlights = async (bookId, position, range = 5) => {
  const fullData = axios
    .get(
      `${apiEndpoint}/api/highlight/range?book_id=${bookId}&position=${position}&range=${range}`,
      getHeaders()
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log("error fetching adjoining higlights data, ", err);
      return null;
    });
  return fullData;
};

export const fetchIdeaCardData = async ({ cardId, title } = {}) => {
  let params = "";
  if (cardId) {
    params += "&_id=" + cardId;
  }
  if (title) {
    params += "&title=" + title;
  }
  const ideaCardData = axios
    .get(`${apiEndpoint}/api/ideas/fetch?${params}`, getHeaders())
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.log("error fetching idea card data, ", err);
      return null;
    });
  return ideaCardData;
};
