import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../pages/Pages_V2.css";

import CommonInput from "./CommonInput";

const Sort = (props) => {
  const {
    SortIcon,
    sortingItemList,
    hideDropDown,
    sethideDropDown,
    setShowItem,
    showItem,
    defaultItem,
    page,
    setSortFilter,
  } = props;

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const [selectedSortItem, setSelectedSortItem] = useState(defaultItem);
  const dispatch = useDispatch();

  // Handler for sorting change
  const handleSortChange = (event) => {
    const newSortItemId = +event.target.value;
    setSelectedSortItem(newSortItemId);
  };

  const getTitleForSortItem = useCallback(
    (sortItemId) => {
      const sortItem = sortingItemList.find((data) => data.id === sortItemId);
      return sortItem ? sortItem.title : "";
    },
    [sortingItemList]
  );

  const getSortFieldForItem = useCallback(
    (sortItemId) => {
      const sortItem = sortingItemList.find((data) => data.id === sortItemId);
      return sortItem ? sortItem.sortField : "";
    },
    [sortingItemList]
  );

  useEffect(() => {
    if (page === "feed") {
      const sortItem = sortingItemList.find(
        (data) => data.id === selectedSortItem
      );
      const sortInstruction = sortItem ? sortItem.sort : "";
      setSortFilter(sortInstruction);
    } else {
      setSortFilter(getSortFieldForItem(selectedSortItem));
    }
  }, [
    dispatch,
    page,
    selectedSortItem,
    sortingItemList,
    getSortFieldForItem,
    setSortFilter,
  ]);

  return (
    <div className="mt-[15px] mb-[15px] sortingList">
      <p className={`text-[length:var(--font-regular)] ${`text-primary-${lightDarkMode}`}`}>Sort :</p>

      <div
        className={`rounded-2xl mt-[8px] ${`background-filter-outer-${lightDarkMode}`}`}
      >
        <div
          className={`flex items-center justify-between py-[2.5px] pr-[10px] pl-[39px] rounded-2xl cursor-pointer h-[30px] ${`background-filter-heading-${lightDarkMode}`}`}
          onClick={() => {
            setShowItem(!showItem);
            sethideDropDown(true);
          }}
        >
          <div>
            <p
              className={`font-semibold text-[length:var(--font-regular)] ${`text-primary-${lightDarkMode}`}`}
            >
              {getTitleForSortItem(selectedSortItem)}
            </p>
          </div>
          <div>
            <SortIcon />
          </div>
        </div>
        {!showItem && hideDropDown && (
          <div className="list-wapper pl-8">
            {sortingItemList.map((data) => (
              <label
                className={`flex items-center justify-between  rounded-2xl mt-[5px] pl-8 cursor-pointer h-[30px] ${
                  selectedSortItem === data.id
                    ? ` ${`background-filter-heading-${lightDarkMode}`}`
                    : ""
                }`}
                key={data.id}
                htmlFor={data.id}
              >
                <div
                  className={`${`text-primary-${lightDarkMode}`} text-[length:var(--font-regular)] ${
                    selectedSortItem === data.id ? "font-semibold" : ""
                  }`}
                >
                  {data.title}
                </div>
                <div className="radio ">
                  <CommonInput
                    type="radio"
                    value={data.id}
                    onChange={(e) => {
                      handleSortChange(e);
                    }}
                    label="custom-style"
                    inputClass="filter-radio-wrapper"
                    wrapperClass="filter-radio"
                    name="name"
                    id={data.id}
                    checked={selectedSortItem === data.id}
                    labelClass={`input-label ${`input-label-${lightDarkMode}`}`}
                  />
                </div>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Sort);
