import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import SquareIcon from "@mui/icons-material/Square";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PublicIcon from "@mui/icons-material/Public";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import "../../pages/Pages_V2.css";

import {
  getIdeaIconElement,
  dynamicBulletHandler,
} from "../../helperFunctions/getIdeacardIcons";

import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { ignoreOrderCompare } from "../../helperFunctions/equalityChecker";
import Search from "./Search";
import CommonInput from "./CommonInput";
export const sortButtonsStyle = { fontSize: "18px" };
export const disabledButtonsStyle = {
  fontSize: "18px",
  color: "var(--disableColor)",
};
export const buttonsStyle = {
  fontSize: "20px",
  marginLeft: "6px",
  marginBottom: "3px",
};

const Filter = (props) => {
  const {
    filterItemList,
    filterLoadStatus,
    FilterIcons,
    title,
    filterByTitle,
    enableSearch,
    enableSelectAll,
    showFilter,
    setShowFilter,
    filterType,
    page,
    filterChange,
    setSelectedRoutes,
    disabledFilters,
  } = props;
  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );
  const [selectAllTags, setSelectAllTags] = useState(true); // State to track select all/clear all
  const [tagList, setTagList] = useState(null); // State to manage tags
  const [selectedTags, setSelectedTags] = useState(null); // State to manage selected tags
  const [searchQuery, setSearchQuery] = useState(""); // State to manage search input
  const dispatch = useDispatch();

  const disabled = disabledFilters?.includes(filterType);

  useEffect(() => {
    let tempSelectedTags = null;
    const updateItemListWithExistingChecked = filterItemList?.map((item) => {
      // find current element exist or not
      const findItem = tagList?.find((tag) => tag.id === item.id);
      if (findItem?.isChecked) {
        if (!tempSelectedTags) tempSelectedTags = [];
        tempSelectedTags.push(findItem);
      }
      if (findItem) {
        item.isChecked = findItem.isChecked;
      }
      return item;
    });

    if (!ignoreOrderCompare(tagList, updateItemListWithExistingChecked)) {
      setTagList(updateItemListWithExistingChecked);
    }
    if (tempSelectedTags) {
      setSelectedTags(tempSelectedTags);
    }
    if (filterType === "ListView") {
      setSelectedRoutes(tagList);
    }
  }, [filterItemList, tagList, filterType, setSelectedRoutes]);

  // Handler for toggling select all/clear all
  const HandleSelectAllToggle = () => {
    if (tagList) {
      setSelectAllTags(!selectAllTags);
      const updatedTags = tagList.map((tag) => ({
        ...tag,
        isChecked: selectAllTags,
      }));
      setTagList(updatedTags);
      setSelectedTags(selectAllTags ? updatedTags : []);
    }
  };

  // Handler for individual tag checkbox change
  const HandleTagCheckboxChange = (tagId) => {
    if (tagList) {
      const updatedTags = tagList.map((tag) =>
        tag.id === tagId ? { ...tag, isChecked: !tag.isChecked } : tag
      );

      setTagList(updatedTags);
      const selectedTag = updatedTags.find((tag) => tag.id === tagId);
      const changedTags = [selectedTag];

      // when main category is selected/unselected, select/unselect also all subcategories
      if (selectedTag.parentId) {
        updatedTags.forEach((tag) => {
          if (tag.childOf === selectedTag.parentId) {
            tag.isChecked = selectedTag.isChecked;
            changedTags.push(tag);
          }
        });
      }

      // when subcategory selected, select also main category
      if (selectedTag.childOf) {
        if (selectedTag.isChecked) {
          updatedTags.forEach((tag) => {
            if (tag.parentId === selectedTag.childOf) {
              tag.isChecked = selectedTag.isChecked;
              changedTags.push(tag);
            }
          });
        }
      }

      if (selectedTag && !selectedTag.isChecked) {
        if (selectedTags) {
          const result = selectedTags?.filter(
            (tag) => tag.id !== tagId && tag.isChecked
          );
          setSelectedTags(result);
        }
      } else {
        if (changedTags.length > 0)
          setSelectedTags(
            selectedTags
              ? [
                  ...selectedTags,
                  ...changedTags?.filter((tag) => tag.isChecked),
                ]
              : [selectedTag]
          );
      }
    }
  };

  useEffect(() => {
    if (selectedTags && filterChange) {
      const checkedTitles = selectedTags.map((item) => item.title); // Map to an array of titles
      const checkedIds = selectedTags.map((item) => item.id);

      filterChange(filterType !== "tags" ? checkedIds : checkedTitles);
    }
  }, [dispatch, selectedTags, filterType, filterChange]);

  // Check if any tag checkbox is selected
  const anyTagSelected = tagList?.some((tag) => tag.isChecked);

  // check for the Icon""}
  const getIconBasedOnName = (icon) => {
    if (icon?.startsWith("IDEAICON_")) {
      return getIdeaIconElement(icon.slice(9));
    }
    switch (icon) {
      case "DiamondOutlinedIcon":
        return <DiamondOutlinedIcon sx={sortButtonsStyle} />;
      case "SquareIcon":
        return <SquareIcon sx={sortButtonsStyle} />;
      case "TipsAndUpdatesIcon":
        return <LightbulbOutlinedIcon />;
      case "PublicIcon":
        return <PublicIcon sx={buttonsStyle} />;
      case "DiamondIcon":
        return <DiamondOutlinedIcon sx={buttonsStyle} />;
      case "BookmarkIcon":
        return <BookmarkIcon sx={sortButtonsStyle} />;
      default:
        return "";
    }
  };

  const handleSearchChange = (event) => {
    const query = event?.target?.value?.toLowerCase() ?? "";
    setSearchQuery(query);
  };

  return (
    <div className="mt-[15px] filterList">
      <p className="text-[length:var(--font-regular)]">{title}</p>
      <div
        className={`mt-[8px] rounded-2xl pb-[10px] ${
          !showFilter && !disabled
            ? `${`background-filter-outer-${lightDarkMode}`}`
            : "bg-transparent"
        }`}
      >
        <div
          className={`flex items-center justify-between h-[30px] py-[2.5px] pl-[10px] pr-[14px] rounded-2xl cursor-pointer ${
            (anyTagSelected || !showFilter) && !disabled
              ? `${`background-filter-heading-${lightDarkMode}`}`
              : ""
          }`}
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <div className="flex items-center ">
            {FilterIcons && (
              <FilterIcons
                sx={disabled ? disabledButtonsStyle : sortButtonsStyle}
              />
            )}
            <p className={`filterTitle ${disabled ? "disabled" : ""}`}>
              {filterByTitle}
            </p>
          </div>
          <div className="pr-[2px]">
            {anyTagSelected ? (
              <FilterAltOutlinedIcon
                sx={disabled ? disabledButtonsStyle : sortButtonsStyle}
              />
            ) : (
              <FilterAltOffIcon
                sx={disabled ? disabledButtonsStyle : sortButtonsStyle}
              />
            )}
          </div>
        </div>
        {!disabled &&
          (!showFilter ? (
            <div className="rounded-2xl">
              {filterType === "tags" && filterItemList.length === 0 ? (
                <p className="ml-[14px] py-2">
                  {filterLoadStatus === ApiStatus.Rejected ? (
                    <>Oops, failed to load tags. Try again later.</>
                  ) : (
                    <>No tags yet defined on your books</>
                  )}
                </p>
              ) : (
                <>
                  <div className="ml-8 mt-2">
                    {enableSearch && (
                      <div className="relative w-full">
                        <Search
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          placeholder={"search"}
                          handleSearchChange={handleSearchChange}
                        />
                      </div>
                    )}
                  </div>

                  {enableSelectAll && (
                    <div className="ml-[70px] mt-2">
                      <p
                        className="text-[#336699] italic cursor-pointer text-[length:var(--font-regular)] h-[30px] flex items-center"
                        onClick={HandleSelectAllToggle}
                      >
                        {selectAllTags ? "Select all" : "Clear all"}
                      </p>
                    </div>
                  )}
                </>
              )}

              <div
                className={`h-full max-h-[300px] overflow-hidden  ${
                  filterItemList?.length >= 8
                    ? "overflow-y-auto filter-listing"
                    : ""
                }`}
              >
                {tagList
                  ?.filter((tag) =>
                    tag.title?.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((data, index) => {
                    return (
                      <div
                        className="list-wapper pl-[32px] cursor-pointer"
                        id={data.id}
                        key={data.id + data.title}
                        onClick={(e) => {
                          e.stopPropagation();
                          HandleTagCheckboxChange(data.id);
                        }}
                      >
                        <div
                          className={`flex items-center justify-start h-[30px]  pr-[4px] rounded-2xl mt-[5px] pl-8  ${
                            data?.isChecked
                              ? `${`background-filter-heading-${lightDarkMode}`}`
                              : ""
                          } 

                      ${data.childOf ? "ml-[35px]" : ""}
                      ${
                        page === "feed" || page === "ListView" ? "pl-[0px]" : ""
                      }
                      `}
                        >
                          <div className="mr-2 ">
                            <span
                              className={`${
                                (page === "feed" && title === "Filter:") ||
                                page === "ListView"
                                  ? "icons-wrapper"
                                  : ""
                              }
                          ${
                            page === "feed" && data.id === "Highlights"
                              ? "circled-icon"
                              : ""
                          }
                          ${
                            page === "feed" && data.id === "Ideas"
                              ? "circled-icon-orange"
                              : ""
                          }
                          ${
                            data.icon?.startsWith("IDEAICON_")
                              ? "icons-wrapper"
                              : ""
                          }
                          `}
                            >
                              {page === "ListView" ? (
                                <>
                                  {dynamicBulletHandler(data?.icon, "medium")}
                                </>
                              ) : (
                                <>{getIconBasedOnName(data?.icon)}</>
                              )}
                            </span>

                            {data?.img && (
                              <div
                                className={`${
                                  page === "feed" && filterByTitle === "Books"
                                    ? "circled-book-image"
                                    : ""
                                }`}
                              >
                                <img
                                  src={data.img}
                                  alt={data?.title}
                                  className="feedListsImg w-[18px]"
                                />
                              </div>
                            )}
                          </div>
                          {/* display icon based on the props */}
                          <div
                            className={`capitalize text-[length:var(--font-regular)] ${
                              data?.isChecked ? "font-semibold" : ""
                            }`}
                          >
                            {data?.title.length > 25
                              ? data?.title.slice(0, 25) + "..."
                              : data?.title}
                          </div>

                          {!data?.isChecked ? (
                            <div className="checkbox ml-auto">
                              <CommonInput
                                type="checkobx"
                                value={data.id}
                                label="custom-style"
                                inputClass="filter-checkbox-wrapper"
                                wrapperClass="filter-checkbox"
                                name="name"
                                id={data.id}
                                checked={data.isChecked}
                                labelClass="input-label"
                              />
                            </div>
                          ) : (
                            <div className="pr-[12px] ml: 1 cursor-pointer h-[30px] flex items-center justify-center ml-auto">
                              <CloseIcon sx={sortButtonsStyle} />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div
              className={`list-wapper pl-8 ${
                selectedTags &&
                selectedTags?.filter((tag) => tag.isChecked).length > 8
                  ? "h-[300px] overflow-hidden overflow-y-auto"
                  : ""
              }`}
            >
              {selectedTags?.map((tag, index) => {
                return (
                  <div
                    className={`flex items-center justify-start  h-[auto] rounded-2xl mt-[5px] selected-tag cursor-pointer  ${`background-filter-heading-${lightDarkMode}`} ${
                      tag.childOf ? "ml-[35px]" : ""
                    }
                  ${
                    page === "feed" || page === "ListView" ? "pl-[0px]" : "pl-8"
                  }
                  `}
                    key={tag.id}
                    onClick={() => HandleTagCheckboxChange(tag.id)}
                  >
                    {tag?.img && (
                      <div
                        className={`${
                          page === "feed" && filterByTitle === "Books"
                            ? "circled-book-image"
                            : ""
                        }`}
                      >
                        <img
                          src={tag.img}
                          alt={tag?.title}
                          className="feedListsImg w-[18px]"
                        />
                      </div>
                    )}
                    <div
                      className={` mr-2 ${
                        page === "feed"
                          ? filterType === "content"
                            ? "icons-wrapper-small"
                            : "icons-wrapper"
                          : ""
                      }
                    ${
                      page === "feed" && tag.id === "Highlights"
                        ? "circled-icon"
                        : ""
                    }
                    ${
                      page === "feed" && tag.id === "Ideas"
                        ? "circled-icon-orange"
                        : ""
                    }
                    `}
                    >
                      {page === "ListView" ? (
                        <> {dynamicBulletHandler(tag?.icon, "medium")}</>
                      ) : (
                        <>{getIconBasedOnName(tag?.icon)}</>
                      )}
                    </div>
                    <div
                      className={`text capitalize font-semibold text-[length:var(--font-regular)]`}
                    >
                      {tag?.title?.length > 25
                        ? tag?.title.slice(0, 25) + "..."
                        : tag?.title}
                    </div>
                    <div
                      className={` ml: 1 cursor-pointer h-[30px] flex items-center justify-center ml-auto ${
                        tagList?.filter((tag) => tag.isChecked).length > 8
                          ? "pr-[9px]"
                          : "pr-[16px]"
                      }`}
                    >
                      <CloseIcon sx={sortButtonsStyle} />
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(Filter);
