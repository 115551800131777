// NewsPage.js
import "./NewsPage.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostsByCategory } from "../../Utils/Features/wordpressSlice";
import NewsPost from "./NewsPost";

const NewsPage = ({ categorySlug }) => {
  const dispatch = useDispatch();
  const { posts, status, error } = useSelector((state) => state.wordpress);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchPostsByCategory(categorySlug));
    }
  }, [dispatch, status, categorySlug]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="news-page">
      {posts.map((post) => (
        <NewsPost
          key={post.id}
          title={post.title.rendered}
          date={post.date}
          content={post.content.rendered}
        />
      ))}
    </div>
  );
};

export default NewsPage;
