import React from "react";
import "../../inputType.css";

const CommonInput = (props) => {
    return (
      <>
        <div
          className={`form-control  ${
            props.wrapperClass ? props.wrapperClass : ""
          }`}
        >
          <input
            type={props.type || "text"}
            name={props.name || ""}
            id={props.id}
            placeholder={props.placeHolder}
            disabled={props.disabled || false}
            onChange={props.onChange}
            onClick={(e) => {
              e.stopPropagation();
            }}
            defaultValue={props.value}
            className={`common-input ${
              props.inputClass ? props.inputClass : ""
            } ${props.suffix ? "issuffix" : ""} ${
              props.preffix ? "ispreffix" : ""
            } `}
            autoFocus={props.autoFocus || false}
            defaultChecked={props.checked}
          />
          {props.label === "custom-style" && (
            <label className={props.labelClass} htmlFor={props.id}></label>
          )}
        </div>
      </>
    );
};

export default CommonInput;
