import React from "react";
import { useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const sortButtonsStyle = {
  fontSize: "18px",
};

const Search = ({
  searchQuery,
  setSearchQuery,
  placeholder,
  handleSearchChange,
  handlePressEnter,
}) => {
  return (
    <div className="flex items-center w-full h-[30px]  border-1 rounded-full border overflow-hidden relative bg-[var(--submenuBackgroundColor)]">
      <div className="grid place-items-center h-full w-12">
        <SearchIcon />
      </div>
      <input
        className="peer w-full outline-none text-sm h-[30px] pr-2 placeholder:italic bg-[var(--submenuBackgroundColor)]"
        type="text"
        id="search"
        placeholder={placeholder}
        value={searchQuery.replace(/\n/g, " ")}
        onChange={handleSearchChange}
        onKeyDown={handlePressEnter}
        autoComplete="off"
      />
      {searchQuery?.length > 0 && (
        <CloseIcon
          sx={sortButtonsStyle}
          className={`mr-[14px] absolute right-0`}
          onClick={() => {
            setSearchQuery("");
            handleSearchChange();
          }}
        />
      )}
    </div>
  );
};

export default Search;
