/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: sort the eslint warnings when this is redesigned/refactored */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import AnchorIcon from "@mui/icons-material/Anchor";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/Visibility";

import "../../pages/MyLibrary/MyLibrary.css";

import { anchorIconStyle, editIconStyle } from "./styled";

import MyCheckbox from "../../helperFunctions/checkbox";
import { fetchAdjoiningHighlights } from "../../helperFunctions/apiFunctions";
import {
  getIdeaCardById,
  updateLinkedHighlights,
} from "../../Utils/Features/librarySlice";
import SquareIcon from "@mui/icons-material/Square";

export const LinkedHighlights = ({ ideaCardId, tabHeight }) => {
  const dispatch = useDispatch();
  const ideaCard = useSelector((state) => getIdeaCardById(state, ideaCardId));

  const [beforeHighlights, setBeforeHighlights] = useState(null);
  const [afterHighlights, setAfterHighlights] = useState(null);
  const [linkedHighlights, setLinkedHighlights] = useState(null);
  const [newHighlightIdList, setNewHighlightIdList] = useState([]);
  const [tempEditedHighlights, setTempEditedHighlights] = useState([]);
  const [beforeRange, setBeforeRange] = useState(12);
  const [afterRange, setAfterRange] = useState(12);
  const fullAdjoiningHighlighstData = useRef(null);
  const [isEditModeON, setIsEditModeON] = useState(false);

  const linkedHighlightRef = useRef(null);

  useEffect(() => {
    fetchAndSetHighlightlist();
  }, [isEditModeON, ideaCard?.description]);

  useEffect(() => {
    if (newHighlightIdList?.length && isEditModeON) {
      const uniqueArray = Array.from(new Set(newHighlightIdList));

      const highlightDataArray = uniqueArray.map((item) =>
        getHighlightData(item)
      );
      const tempHighlights = [...highlightDataArray];
      tempHighlights.sort((first, second) => first?.start - second?.start);
      const filteredArray = tempHighlights.filter(
        (item) => item !== null && item !== undefined
      );

      setTempEditedHighlights(filteredArray);
    }
  }, [newHighlightIdList]);

  useEffect(() => {
    if (!isEditModeON) {
      if (tempEditedHighlights?.length) setAfterRange(12);
      setBeforeRange(12);
      updateHighlightData();
      const titlecolor = document.getElementById(
        "panel1a-header highlightColourChange"
      );
      if (titlecolor) {
        titlecolor.style.color = "var(--fontColor)";
      }
    }
    if (isEditModeON) {
      const titlecolor = document.getElementById(
        "panel1a-header highlightColourChange"
      );
      if (titlecolor) {
        titlecolor.style.color = "#ff6600";
      }
    }
  }, [isEditModeON]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [tempEditedHighlights]);

  const filterHiglights = (adjoiningHighlightData, linkedHighlightsData) => {
    return adjoiningHighlightData?.filter((highlight) => {
      return linkedHighlightsData?.every((linkedHighlight) => {
        return highlight?.context.length > 0;
      });
    });
  };

  const getHighlightData = (highlightId) => {
    if (isEditModeON) {
      const highlights = [
        ...(beforeHighlights || []),
        ...(afterHighlights || []),
        ...(linkedHighlights || []),
      ];
      const filteredHighlight = highlights.filter(
        (highlight) =>
          (highlight?._id || highlight?.highlight_id) === highlightId
      );
      if (filteredHighlight.length > 0) {
        return {
          highlight_id: highlightId,
          start: filteredHighlight[0].start,
          context: filteredHighlight[0].context,
        };
      }
      return null;
    }
  };

  const filterAdjoiningHighlights = (linkedHighlightsData) => {
    const tempBeforeHighlights = filterHiglights(
      fullAdjoiningHighlighstData?.current?.before,
      linkedHighlightsData
    );
    setBeforeHighlights(
      tempBeforeHighlights?.slice(
        tempBeforeHighlights.length - beforeRange,
        tempBeforeHighlights.length
      )
    );
    const tempAfterHighlights = filterHiglights(
      fullAdjoiningHighlighstData?.current?.after,
      linkedHighlightsData
    );
    setAfterHighlights(tempAfterHighlights?.slice(0, afterRange));
  };

  const handleChange = (e, value) => {
    e.stopPropagation();
    if (e.target.checked) {
      setNewHighlightIdList([...newHighlightIdList, value]);
    }
    if (!e.target.checked) {
      const updatedList = newHighlightIdList
        .filter((item) => item !== value) // Filter out the specified value
        .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
      setNewHighlightIdList(updatedList);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      Math.floor((e.target.scrollHeight - parseInt(e.target.scrollTop)) / 10) *
        10 <=
      e.target.clientHeight;
    if (e.target.scrollTop === 0) {
      setBeforeRange(beforeRange + 12);
    }
    if (bottom) {
      setAfterRange(afterRange + 12);
    }
  };

  const handleOutsideClick = (event) => {
    const element = linkedHighlightRef.current;

    if (isEditModeON && element && !element.contains(event.target)) {
      setIsEditModeON(false);
    }
  };

  const updateHighlightData = () => {
    if (tempEditedHighlights && tempEditedHighlights.length && !isEditModeON) {
      const filteredArray = tempEditedHighlights.filter(
        (item) => item !== null
      );

      dispatch(
        updateLinkedHighlights({
          ideaCardId: ideaCardId,
          newData: { description: filteredArray },
        })
      );
      setTempEditedHighlights([]);
      setNewHighlightIdList([]);
    }
  };

  const fetchAndSetHighlightlist = () => {
    if (isEditModeON) {
      let mainHighlight;
      let resultArray;
      fetchAdjoiningHighlights(ideaCard.book_id, ideaCard.start).then(
        (adjoiningData) => {
          if (adjoiningData) {
            fullAdjoiningHighlighstData.current = adjoiningData[0];

            mainHighlight = adjoiningData[0].target[0];
            resultArray = adjoiningData[0].result;

            if (ideaCard?.description?.length > 0 && !isEditModeON) {
              setLinkedHighlights([]);
              const objectsWithStart = ideaCard.description.filter(
                (item) =>
                  item.hasOwnProperty("start") &&
                  item.start !== null &&
                  item.start !== undefined
              );
              // If there are objects with valid "start" values, setLinkedHighlights
              if (objectsWithStart.length > 0) {
                setLinkedHighlights(objectsWithStart);
              }
            }
            if (
              (!ideaCard?.description || ideaCard?.description.length === 0) &&
              mainHighlight
            ) {
              setLinkedHighlights([
                {
                  highlight_id: mainHighlight?._id,
                  start: mainHighlight?.start,
                  context: mainHighlight?.context,
                },
              ]);
              setNewHighlightIdList([mainHighlight?._id]);
            }
            if (resultArray.length > 0 && isEditModeON) {
              setLinkedHighlights([]);
              setLinkedHighlights(resultArray);
            }
            if (ideaCard.description) {
              filterAdjoiningHighlights(ideaCard.description);
              const highlightIds = ideaCard.description.map(
                (item) => item.highlight_id
              );

              let newList = [...highlightIds, mainHighlight?._id];
              newList = newList.filter((element, index) => {
                return newList.indexOf(element) === index;
              });

              setNewHighlightIdList(newList);
            }
          }
        }
      );
    } else {
      setLinkedHighlights(ideaCard.description);
    }
  };

  const sortedHighlights =
    linkedHighlights &&
    linkedHighlights.length &&
    linkedHighlights?.slice()?.sort((a, b) => a?.start - b?.start);

  return (
    <>
      <div className="iconTopContainer">
        {!ideaCard?.read_only && (
          <Tooltip
            placement="bottom-start"
            arrow
            title={
              <span>
                <strong>Click</strong> to add or remove <br />
                linked highlights
              </span>
            }
          >
            <span
              className=" editSquareHighlight cursor-pointer"
              onClick={(e) => {
                setIsEditModeON(!isEditModeON);
                // e.stopPropagation();
              }}
            >
              {isEditModeON ? (
                <span className="bg-[color:var(--fontColor)] rounded-full flex justify-center items-center !w-[22px] !h-[22px]">
                  <EditIcon sx={editIconStyle} />
                </span>
              ) : (
                <span className="bg-[color:var(--fontColor)] rounded-full flex justify-center items-center !w-[22px] !h-[22px]">
                  <RemoveRedEyeOutlinedIcon sx={editIconStyle} />
                </span>
              )}
            </span>
          </Tooltip>
        )}
      </div>
      <div
        className="overflow-auto flex flex-col gap-4"
        style={{
          height: tabHeight,
        }}
        onScroll={handleScroll}
        ref={linkedHighlightRef}
      >
        {sortedHighlights?.length > 0 &&
          sortedHighlights?.map((item, index) => {
            if (item !== null && item !== undefined) {
              return (
                <label
                  key={"linked-" + (item?._id || item?.highlight_id)}
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "start",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  htmlFor={item?._id}
                >
                  <span
                    style={{ height: "fit-content", padding: "0" }}
                    className="mt-[3px]"
                  >
                    {!isEditModeON ? (
                      <span className="rounded-full flex justify-center items-center !w-[22px] !h-[22px] ">
                        {ideaCard.highlight_id ===
                        (item?._id || item?.highlight_id) ? (
                          <AnchorIcon sx={anchorIconStyle} />
                        ) : (
                          <SquareIcon
                            sx={{
                              color: "var(--fontColor)",
                              fontSize: "12px",
                            }}
                          />
                        )}
                      </span>
                    ) : ideaCard.highlight_id ===
                      (item?._id || item?.highlight_id) ? (
                      <AnchorIcon sx={anchorIconStyle} />
                    ) : (
                      <MyCheckbox
                        label={item?.context}
                        isChecked={
                          newHighlightIdList.includes(item?._id) ? true : false
                        }
                        handleCheckboxChange={(e) => handleChange(e, item?._id)}
                      />
                    )}
                  </span>
                  <span
                    style={{
                      fontWeight:
                        item?._id ||
                        item?.highlight_id === ideaCard.highlight_id
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {(!isEditModeON || item?._id === ideaCard.highlight_id) &&
                      item?.context}
                  </span>
                </label>
              );
            } else return null;
          })}
      </div>
    </>
  );
};
