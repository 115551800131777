import { useState, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import S3Storage from "../../components/S3storage/s3storage";

import "../MyLibrary/MyLibrary.css";
import {
  dynamicBulletHandler,
  getLabelId,
  getIdeaIconElement,
  getIdeacardIcons,
} from "../../helperFunctions/getIdeacardIcons";
import { PexelImageSelect } from "../../components/PexelImage/PexelImageSelect";
import {
  getCurrentIdeaCardId,
  getIdeaCardById,
  updateIdeaCard,
  updateIdeaCardLabel,
  addIdeaCard,
  deleteIdeaCard,
  getBookById,
  fetchAllTags,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { store } from "../../Utils/Store/Store";

import { updatePersistentDrawer } from "../../Utils/Features/persistentDrawerSlice";
import {
  setCurrentTab,
  updateIdentifyIdeaCardData,
} from "../../Utils/Features/IdentifyIdeaCardSlice";
import { handleImageLoad } from "../../helperFunctions/imgHelper";
import { MyNotes } from "../../components/Insights/MyNotes";
import { Topics } from "../../components/Insights/Topics";
import { LinkStructure } from "../../components/Insights/LinkIdeaCard/LinkStructure";
import { LinkedHighlights } from "../../components/Insights/LinkedHighlights";
import { isNullOrUndefinedOrEmpty } from "../../helperFunctions/isNullOrUndefinedOrEmpty";
import { Ownership } from "../../components/Common/Ownership/Ownership";
import { DeepTabs } from "../../components/Common/Tabs";
import { useWindowSize } from "../../helperFunctions/useWindowSize";

const s3 = new S3Storage();

const MenuItemStyles = {
  margin: "5px 1px",
  borderRadius: "30px",
};
const inactiveLabelIconStyle = {
  backgroundColor: "var(--greyColor)",
  borderRadius: "33px",
  padding: "3px",
};

const TitleTextField = styled(Typography)(() => ({
  "&&": {
    paddingTop: "4px",
    paddingBottom: "10px",
    color: "var(--fontDarkColor)",
    fontSize: "1.2rem",
    fontWeight: 700,
    lineHeight: "1.1",
  },
}));

const EditableTextField = styled(TextField)(({ theme }) => ({
  width: "78%",
  "& .MuiInputBase": {
    padding: 0,
  },
  "& .MuiInputBase-input": {
    fontSize: "1.2rem",
    fontWeight: 700,
    color: "var(--fontDarkColor)",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiInput-underline:before": {
    border: "none",
  },
  "& .MuiInput-underline:hover:before": {
    border: "none !important",
  },
}));

export default function IdeaCardPage() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const selectedIdeaCardId = useSelector((state) =>
    getCurrentIdeaCardId(state)
  );
  const ideaCardData = useSelector((state) =>
    getIdeaCardById(state, selectedIdeaCardId)
  );

  let identifyIdeaCard = useSelector(
    (state) => state.IdentifyIdeaCardReducer.value
  );

  const identifyIdeaCurrentTab = useSelector(
    (state) => state?.IdentifyIdeaCardReducer?.currentTab
  );

  const allTagsStatus = useSelector((state) => state.library.allTagsStatus);

  const notesItem = useSelector((state) =>
    getIdeaCardById(state, ideaCardData?._id)
  );

  const ideaCardLinkedStructure = useSelector((state) =>
    getIdeaCardById(state, ideaCardData?._id)
  );

  const ideaCardTags = useSelector((state) => {
    return getIdeaCardById(state, ideaCardData?._id);
  });

  const [ideaCard, setIdeaCard] = useState(ideaCardData);
  const [title, setTitle] = useState();
  const [activeLabel, setActiveLabel] = useState(getLabelId());
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(true);
  const [tabHeight, setTabHeight] = useState(null);
  const topRef = useRef(null);
  const ideacardContainerRef = useRef(null);
  const windowSize = useWindowSize();

  const imageLink = ideaCard?.picture_link;

  const open = Boolean(anchorEl);
  const menuIcons = getIdeacardIcons();

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const IdeaTabs = [
    { label: "Media" },
    { label: "Notes" },
    { label: "Tags" },
    { label: "Highlights" },
    { label: "Idea Cards" },
  ];

  useLayoutEffect(() => {
    const ideaElement = ideacardContainerRef.current;
    const topElement = topRef.current;
    if (ideaElement && topElement) {
      var height =
        ideaElement.getBoundingClientRect().height -
        topElement.getBoundingClientRect().height;
      height -= 100;
      setTabHeight(height);
    }
    // ideaCard._id dependency cause, when ideacard is just freshly created the elements don't exist
    // ==> recalculate when the elements are created
  }, [topRef, ideacardContainerRef, windowSize, ideaCard?._id]);

  useEffect(() => {
    setIdeaCard(ideaCardData);

    setTitle(ideaCardData?.title);
    if (ideaCardData?.picture_link?.length > 0) {
      setSearchQuery("");
      setShow(false);
    }
  }, [ideaCardData, selectedIdeaCardId]);

  useEffect(() => {
    if (allTagsStatus === ApiStatus.NotRun) {
      dispatch(fetchAllTags());
    }
  }, [dispatch, allTagsStatus]);

  useEffect(() => {
    if (identifyIdeaCard && !ideaCard?._id) {
      dispatch(addIdeaCard({ data: identifyIdeaCard }));
      setTitle(identifyIdeaCard?.title);
      setSearchQuery(identifyIdeaCard?.title);
    }
    if (identifyIdeaCard && ideaCard?._id && !ideaCard?.book) {
      // add book data to idea cards added this session (they will only have it after fetched from backend)
      // so that book ownership can be shown correctly
      if (ideaCard && ideaCard.book_id && !ideaCard?.book) {
        const book = getBookById(store.getState(), ideaCard.book_id);
        if (book) {
          const ideaCardTemp = { ...ideaCard };
          ideaCardTemp.book = [book];
          setIdeaCard(ideaCardTemp);
        }
      }
    }
  }, [dispatch, ideaCard, identifyIdeaCard]);

  const handleImageSelect = (imageUrl) => {
    dispatch(
      updateIdeaCard({
        ideaCardId: ideaCard._id,
        newData: {
          picture_link: imageUrl,
        },
      })
    );
  };

  const handleTitleChange = (e) => {
    e.stopPropagation();
    if (title !== undefined) {
      dispatch(
        updateIdeaCard({
          ideaCardId: ideaCard._id,
          newData: {
            title: title,
          },
        })
      );
    }
  };

  const handleTitleFieldKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const { selectionStart, selectionEnd } = inputRef.current;

      // Insert a newline character at the current cursor position
      setTitle((prevValue) => {
        const start = prevValue.substring(0, selectionStart);
        const end = prevValue.substring(selectionEnd);
        return `${start}\n${end}`;
      });

      // Set the cursor position after the inserted newline
      setTimeout(() => {
        inputRef.current.setSelectionRange(
          selectionStart + 1,
          selectionStart + 1
        );
      }, 0);
    }
  };
  const handleClick = (event, labelId) => {
    setAnchorEl(event.currentTarget);
    setActiveLabel(labelId);
  };

  const handleDeleteIdeaCard = () => {
    dispatch(
      deleteIdeaCard({
        ideaCardId: ideaCard._id,
      })
    );
    dispatch(updateIdentifyIdeaCardData(null));
    dispatch(updatePersistentDrawer(null));
  };

  const handleClose = (iconLabelId) => {
    setAnchorEl(null);

    if (iconLabelId) {
      dispatch(
        updateIdeaCardLabel({
          ideaCardId: ideaCard._id,
          newData: {
            label_id: iconLabelId,
          },
        })
      );
    }
  };

  const memoizedReloadImageLink = useMemo(() => {
    if (ideaCard?.picture_link) {
      return s3.getReloadImageLink(ideaCard.picture_link);
    }
    return null;
  }, [ideaCard]);

  const handleTabChange = (newValue) => {
    dispatch(setCurrentTab(newValue));
  };

  const updateHandler = (id, newData) => {
    dispatch(
      updateIdeaCard({
        ideaCardId: id,
        newData: newData,
      })
    );
  };

  return (
    <>
      {ideaCard ? (
        <>
          {" "}
          <div
            className="ideacardParentContainer"
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={ideacardContainerRef}
          >
            <div className="ideacard-Title" ref={topRef}>
              {/* //Shared by */}
              <div className="ideacardTopRowContainer">
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  spacing={0}
                  sx={
                    isSmDown
                      ? { paddingLeft: "0.7rem", paddingRight: "0.5rem" }
                      : { paddingLeft: "1.4rem", paddingRight: "0.5rem" }
                  }
                >
                  <Ownership ownership={ideaCard?.ownership} />
                </Stack>
                {!ideaCard?.read_only && (
                  <span className="iconStyle cursor-pointer">
                    <DeleteIcon
                      fontSize={"small"}
                      onClick={handleDeleteIdeaCard}
                    />
                  </span>
                )}
              </div>
              <Stack
                direction="row"
                justifyContent="left"
                alignItems="flex-start"
                spacing={1.5}
                my={1}
                sx={
                  isSmDown
                    ? { paddingLeft: "0.7rem", paddingRight: "0.5rem" }
                    : { paddingLeft: "1.4rem", paddingRight: "0.5rem" }
                }
              >
                <span
                  id="ideaCardLabels"
                  aria-controls={open ? "ideaCardLabelsMenu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(e) => {
                    if (!ideaCard?.read_only) {
                      handleClick(e, ideaCard.label_id);
                      e.stopPropagation();
                    }
                  }}
                  className="cursor-pointer"
                >
                  {getIdeaIconElement(ideaCard.label_id, "large")}
                </span>
                {ideaCard?.highlight_id ? (
                  !ideaCard?.read_only ? (
                    <EditableTextField
                      multiline
                      value={title}
                      placeholder="Enter Title"
                      onChange={(e) => {
                        e.stopPropagation();
                        setTitle(e.target.value);
                      }}
                      onBlur={(e) => handleTitleChange(e)}
                      onKeyDown={handleTitleFieldKeyDown}
                      onPaste={(e) => handleTitleChange(e)}
                      variant="standard"
                      autoFocus={isMdUp ? true : false}
                      maxRows={isMdUp ? null : "3"}
                      inputRef={inputRef}
                    />
                  ) : (
                    <TitleTextField>{title}</TitleTextField>
                  )
                ) : null}
              </Stack>
            </div>
            <div>
              {/* //Graphics */}
              <div
                style={
                  isSmDown
                    ? { marginLeft: "0.7rem", paddingRight: "+.55rem" }
                    : { marginLeft: "4.3rem", paddingRight: "5rem" }
                }
                className={`tabWrapper
                ${
                  isNullOrUndefinedOrEmpty(ideaCard?.picture_link)
                    ? "disabled_first"
                    : null
                }
                ${
                  isNullOrUndefinedOrEmpty(notesItem?.my_notes)
                    ? "disabled_second"
                    : null
                }
                ${
                  isNullOrUndefinedOrEmpty(
                    ideaCardLinkedStructure?.linked_structure
                  )
                    ? "disabled_fifth"
                    : null
                }
                ${
                  isNullOrUndefinedOrEmpty(ideaCardTags?.tags)
                    ? "disabled_third"
                    : null
                }
                `}
              >
                <DeepTabs
                  tabsInfo={IdeaTabs}
                  startTab={identifyIdeaCurrentTab}
                  handleTabChange={handleTabChange}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "12px",
                      position: "relative",
                    }}
                  >
                    <div
                      className={`w-full max-h-[600px] overflow-y-auto pb-[50px]
                          ${
                            ideaCard?.picture_link
                              ? "googleImgSelectWrapper  overflow-auto relative"
                              : ""
                          }`}
                    >
                      {!ideaCard?.read_only ? (
                        <>
                          <div
                            className={
                              !show && imageLink?.length > 0 ? "hidden" : ""
                            }
                          >
                            <div
                              className={`w-full ${
                                ideaCard?.picture_link ? "absolute" : ""
                              } `}
                            >
                              <PexelImageSelect
                                onImageSelect={handleImageSelect}
                                image={ideaCard?.picture_link}
                                titleForSuggestion={
                                  ideaCard?.picture_link ? "" : ideaCard.title
                                }
                                setLoading={setLoading}
                                loading={loading}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                setShow={setShow}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              show && imageLink?.length > 0 ? "mt-[125px]" : ""
                            }
                          >
                            {loading ? (
                              <div
                                className={
                                  show && imageLink?.length > 0
                                    ? "mt-[125px]"
                                    : ""
                                }
                              >
                                Uploading the image...
                              </div>
                            ) : (
                              <>
                                {" "}
                                <div
                                  className={` ${
                                    !show && imageLink?.length > 0
                                      ? "overflow-hidden rounded-[10px] flex justify-start "
                                      : "mt-[125px]"
                                  }
                              ${
                                searchQuery && ideaCard?.picture_link
                                  ? "h-[500px]  z-[-1]"
                                  : "max-h-[500px]"
                              }
                        `}
                                >
                                  {ideaCard?.picture_link && (
                                    <div className="w-full flex justify-start ">
                                      <img
                                        src={memoizedReloadImageLink}
                                        srcSet={memoizedReloadImageLink}
                                        alt={ideaCard.title}
                                        className={`object-cover object-center rounded-[8px] img-contain`}
                                        onLoad={handleImageLoad}
                                        onClick={() => {
                                          setShow(!show);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        ideaCard?.picture_link && (
                          <div className="w-full flex justify-start ">
                            <img
                              src={s3.getReloadImageLink(ideaCard.picture_link)}
                              alt={ideaCard.title}
                              className={`object-cover object-center rounded-[8px] img-contain`}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {/* Notes */}
                  <MyNotes
                    id={ideaCard._id}
                    type={"ideaCard"}
                    updateHandler={updateHandler}
                    tabHeight={tabHeight}
                  />
                  {/* Tags */}
                  <div className="max-h-[600px] min-h-[400px] overflow-y-auto pb-[50px]">
                    <Topics
                      id={ideaCard._id}
                      type={"ideaCard"}
                      updateHandler={updateHandler}
                    />
                  </div>
                  {/* Linked HIghlights */}

                  <LinkedHighlights
                    ideaCardId={ideaCard._id}
                    tabHeight={tabHeight}
                  />
                  {/* Linked Strucure */}
                  <LinkStructure
                    ideaCardId={ideaCard._id}
                    tabHeight={tabHeight}
                  />
                </DeepTabs>
              </div>
            </div>
          </div>
          <Menu
            id="ideaCardLabelsMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            MenuListProps={{
              "aria-labelledby": "ideaCardLabels",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: 28,
            }}
          >
            {menuIcons?.map((item, index) => {
              return (
                <MenuItem
                  key={index + item._id}
                  sx={MenuItemStyles}
                  onClick={() => handleClose(item._id)}
                >
                  {item._id === activeLabel ? (
                    <>
                      {dynamicBulletHandler(item.label, "medium")} &nbsp;
                      <strong>&nbsp;{item.label}</strong>
                    </>
                  ) : (
                    <>
                      {dynamicBulletHandler(
                        item.label,
                        "medium",
                        inactiveLabelIconStyle
                      )}
                      &nbsp; &nbsp;{item.label}
                    </>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        <div className="centered">
          <span>Opening Idea</span>
        </div>
      )}
    </>
  );
}
