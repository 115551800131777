import { useState } from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { Box, Tab, Tabs } from "@mui/material";

import { CustomTabPanel } from "./CustomTabPanel";

/* Using DeepTabs ;) make sure that the children components are in the same order and equal number
 * to the tab labels in the tabsInfo. Also disabled tabs need some content. With tabs without any
 * content the SwipeableViews doesn't work correctly.
 * Example usage:
 *
 * const FeedTabs = [
 *    { label: "Picture" },
 *    { label: "Notes", disabled: !feedDataList?.my_notes?.length > 0 },
 *    { label: "Highlights" },
 *  ];
 *  <DeepTabs tabsInfo={FeedTabs}>
 *    <img..>
 *    { feedDataList?.my_notes?.length > 0 ?
 *      (<FeedNotes..>) :
 *      (<>No notes yet!</>)
 *    }
 *    <FeedHiglights..>
 *  </DeepTabs>
 */

export const DeepTabs = ({
  tabsInfo,
  children,
  startTab,
  handleTabChange,
  disableSwipe,
  largeFontSize = false,
}) => {
  const [currentTab, setCurrentTab] = useState(
    startTab ?? tabsInfo.findIndex((tab) => !tab.disabled)
  );

  const handleChangeIndex = (index) => {
    setCurrentTab(index);
    if (handleTabChange) {
      handleTabChange(index);
    }
  };

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    if (handleTabChange) {
      handleTabChange(newValue);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%", padding: "0px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "var(--fontColor)",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#979797",
            },
          }}
          sx={{
            "& .MuiButtonBase-root.MuiTab-root": {
              color: "var(--fontColor)",
              padding: "12px 15px",
              textTransform: "none",
              fontSize: largeFontSize ? "18px" : "inherit",
              minWidth: "auto",
              "@media only screen and (max-width: 430px)": {
                fontSize: "15px",
                padding: "10px",
              },
            },
            "& .MuiButtonBase-root.MuiTab-root.Mui-disabled": {
              color: "var(--disableColor)",
            },
            "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
              color: "var(--fontColor)",
              fontWeight: "700",
            },
            "& .MuiTabs-indicator": {
              borderBottom: `2px solid var(--fontColor)`,
            },
          }}
        >
          {tabsInfo.map((info, index) => (
            <Tab
              key={info.label}
              label={info.label}
              {...a11yProps(index)}
              disabled={info.disabled}
            />
          ))}
        </Tabs>
      </Box>
      {!disableSwipe ? (
        <SwipeableViews
          axis={"x"}
          index={currentTab}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents={true}
          style={{
            overflowX: "hidden",
          }}
        >
          {children.map((child, index) => (
            <CustomTabPanel key={index} value={currentTab} index={index}>
              {child}
            </CustomTabPanel>
          ))}
        </SwipeableViews>
      ) : (
        children.map((child, index) => (
          <CustomTabPanel key={index} value={currentTab} index={index}>
            {child}
          </CustomTabPanel>
        ))
      )}
    </Box>
  );
};
