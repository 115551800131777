import React from "react";

export default function IdentifyWhite() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 1000.000000 1000.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
        fill="#717171"
        stroke="none"
      >
        <path
          d="M6091 9831 c-65 -66 -117 -121 -115 -124 3 -2 -77 -85 -176 -183 -99
-98 -180 -182 -180 -186 0 -4 -81 -90 -180 -190 -473 -481 -580 -591 -580
-597 0 -4 -79 -86 -175 -183 -96 -96 -175 -178 -175 -182 0 -3 -80 -87 -177
-187 -391 -397 -517 -527 -521 -538 -2 -6 -84 -92 -183 -190 -98 -98 -179
-181 -179 -185 0 -4 -40 -48 -90 -96 -49 -49 -92 -100 -96 -114 -3 -14 -9 -26
-13 -26 -5 0 -14 -18 -21 -40 -7 -22 -16 -40 -20 -40 -4 0 -11 -16 -14 -35 -4
-19 -11 -35 -16 -35 -5 0 -7 -7 -4 -15 4 -8 1 -15 -4 -15 -6 0 -14 -16 -18
-35 -3 -19 -10 -35 -15 -35 -4 0 -14 -18 -20 -40 -7 -22 -16 -40 -20 -40 -5 0
-11 -16 -15 -35 -3 -19 -10 -35 -15 -35 -5 0 -8 -3 -8 -7 2 -12 -25 -73 -32
-73 -3 0 -12 -21 -20 -47 -7 -27 -19 -54 -26 -61 -7 -7 -13 -20 -13 -28 0 -8
-4 -14 -10 -14 -5 0 -7 -7 -4 -15 4 -8 2 -15 -4 -15 -5 0 -13 -16 -16 -35 -4
-19 -11 -35 -16 -35 -5 0 -15 -18 -21 -40 -7 -22 -15 -40 -19 -40 -4 0 -11
-16 -14 -35 -4 -19 -11 -35 -16 -35 -6 0 -9 -3 -8 -7 2 -11 -25 -67 -37 -75
-5 -4 -6 -8 -1 -8 5 0 4 -6 -2 -12 -5 -7 -13 -28 -17 -45 -3 -18 -11 -33 -16
-33 -5 0 -14 -20 -21 -45 -7 -25 -15 -45 -19 -45 -4 0 -10 -13 -14 -30 -4 -16
-11 -30 -17 -30 -6 0 -8 -3 -5 -6 3 -4 0 -16 -8 -28 -8 -11 -15 -28 -15 -38 0
-10 -4 -18 -9 -18 -4 0 -14 -20 -21 -45 -7 -25 -16 -45 -21 -45 -5 0 -11 -11
-15 -25 -3 -14 -10 -25 -14 -25 -5 0 -6 -9 -3 -20 3 -11 1 -20 -5 -20 -6 0
-13 -13 -17 -30 -4 -16 -11 -30 -16 -30 -5 0 -14 -20 -21 -45 -7 -25 -15 -45
-19 -45 -4 0 -10 -13 -14 -30 -4 -16 -10 -30 -15 -30 -4 0 -6 -9 -3 -20 3 -11
1 -20 -5 -20 -5 0 -13 -11 -16 -25 -4 -14 -10 -25 -15 -25 -5 0 -14 -20 -21
-45 -7 -25 -16 -45 -20 -45 -4 0 -11 -16 -14 -35 -4 -19 -11 -35 -17 -35 -6 0
-7 -4 -4 -10 3 -5 2 -10 -4 -10 -5 0 -12 -14 -16 -30 -4 -17 -10 -30 -14 -30
-4 0 -12 -20 -19 -45 -7 -25 -16 -45 -22 -45 -5 0 -12 -16 -16 -35 -3 -19 -10
-35 -16 -35 -5 0 -6 -5 -2 -11 3 -6 3 -14 -2 -18 -14 -10 -64 -114 -64 -134 0
-9 -4 -17 -9 -17 -5 0 -11 -11 -15 -24 -3 -13 -239 -256 -532 -550 l-527 -526
69 -69 69 -70 438 437 c397 396 441 443 469 502 17 36 50 106 74 157 24 50 44
99 44 107 0 9 5 16 11 16 5 0 8 4 5 9 -3 5 2 15 11 22 14 12 15 11 8 -11 -4
-14 -12 -26 -16 -28 -5 -2 -9 -10 -9 -17 0 -7 -22 -60 -49 -117 -26 -56 -60
-128 -74 -158 -22 -46 -97 -126 -461 -490 -240 -239 -436 -436 -436 -438 0 -3
18 -22 41 -44 22 -22 39 -42 36 -44 -2 -3 33 -42 79 -87 88 -86 99 -110 64
-142 -11 -10 -20 -22 -20 -26 0 -5 -23 -31 -52 -59 -59 -57 -62 -45 36 -147
l50 -53 -25 -26 c-99 -108 -269 -277 -269 -269 0 6 62 74 139 152 l139 141
-35 34 c-20 18 -48 50 -64 71 l-28 39 -76 -74 c-41 -41 -75 -78 -75 -82 0 -4
-58 -66 -130 -138 -71 -71 -130 -133 -130 -137 0 -4 -67 -75 -150 -157 -82
-82 -150 -152 -150 -156 0 -4 -61 -69 -136 -143 -74 -74 -132 -135 -128 -135
4 0 -59 -66 -140 -146 -80 -80 -146 -151 -146 -156 0 -5 -10 -18 -22 -28 l-23
-19 43 -7 c24 -4 49 -12 55 -19 7 -6 32 -17 57 -23 l45 -11 68 67 c37 36 67
61 67 55 0 -6 -3 -13 -7 -15 -5 -2 -32 -29 -62 -60 l-53 -56 56 -13 c31 -7 56
-15 56 -19 0 -4 25 -13 55 -20 30 -7 55 -16 55 -20 0 -4 17 -11 37 -15 21 -4
43 -10 48 -14 6 -5 38 -14 72 -21 35 -7 63 -16 63 -21 0 -4 25 -13 55 -20 30
-7 55 -16 55 -20 0 -5 23 -11 50 -15 28 -4 50 -10 50 -14 0 -4 25 -12 55 -19
30 -7 55 -16 55 -20 0 -5 27 -14 60 -21 33 -7 60 -16 60 -20 0 -4 20 -10 45
-13 25 -4 65 -16 90 -27 24 -11 51 -20 59 -20 9 0 16 -4 16 -9 0 -4 28 -14 63
-21 34 -7 67 -16 72 -20 6 -4 26 -11 45 -15 19 -4 54 -16 77 -26 23 -11 49
-19 57 -19 9 0 16 -4 16 -9 0 -5 29 -14 65 -21 36 -7 65 -16 65 -20 0 -4 20
-11 45 -14 25 -4 51 -14 59 -21 8 -8 32 -17 55 -21 23 -4 41 -10 41 -14 0 -4
29 -13 65 -20 36 -7 65 -15 65 -19 0 -4 24 -13 53 -21 28 -7 55 -18 59 -23 6
-10 348 321 348 336 0 14 371 377 385 377 14 0 215 -196 215 -210 0 -4 39 -46
87 -93 l87 -87 44 43 c24 23 258 257 520 519 262 263 483 478 490 478 6 0 12
4 12 10 0 5 16 12 35 16 19 3 35 10 35 14 0 4 18 12 40 19 22 6 40 15 40 20 0
4 23 13 50 20 28 7 50 16 50 21 0 4 16 11 35 14 19 4 35 11 35 15 0 5 20 15
45 22 25 7 45 16 45 20 0 3 21 12 48 19 26 8 49 16 52 19 8 9 70 38 113 52 20
7 37 17 37 21 0 4 8 8 19 8 10 0 39 11 65 25 26 14 56 25 67 25 10 0 19 4 19
10 0 5 19 15 43 22 23 6 44 15 47 19 3 3 22 12 43 18 20 7 37 17 37 22 0 5 10
9 21 9 12 0 36 9 53 19 17 10 46 22 64 26 17 3 32 10 32 15 0 4 20 13 45 19
25 6 45 15 45 20 0 5 13 12 30 16 16 3 53 19 82 33 29 15 72 34 96 41 23 8 42
17 42 21 0 3 23 12 50 19 28 7 50 16 50 21 0 4 16 11 35 14 19 4 35 11 35 16
0 6 20 15 45 22 25 7 45 15 45 19 0 4 23 13 50 20 28 7 50 16 50 20 0 4 11 10
25 13 14 4 25 10 25 15 0 5 23 14 50 21 28 7 50 16 50 21 0 5 8 9 17 9 10 0
40 11 68 24 104 50 142 66 158 66 9 0 17 4 17 8 0 5 18 14 40 21 22 6 40 16
40 20 0 5 16 12 35 15 19 4 35 11 35 15 0 5 23 14 50 21 28 7 50 16 50 21 0 5
8 9 17 9 10 0 640 623 1419 1401 l1401 1402 -110 109 -110 110 -779 -779
c-428 -428 -781 -777 -784 -774 -7 7 1548 1561 1563 1561 6 0 59 -47 117 -105
58 -58 108 -105 113 -105 4 0 37 29 72 64 l64 64 -183 184 -183 184 -773 -773
c-627 -627 -778 -773 -799 -773 -21 0 -110 84 -455 429 -237 237 -430 433
-430 437 0 3 -231 238 -514 520 -283 283 -516 519 -518 525 -2 5 -210 217
-462 470 -405 405 -461 458 -475 445 -9 -7 -26 -22 -38 -32 -13 -10 -23 -22
-23 -26 0 -10 -49 -62 -55 -57 -2 3 21 34 53 69 31 35 57 67 57 71 0 10 -81
96 -101 107 -8 5 -18 27 -21 49 -6 48 -20 32 248 305 101 103 185 192 187 197
2 6 86 93 188 195 101 101 184 187 184 191 0 3 83 89 185 191 102 102 185 189
185 193 0 4 83 90 185 192 101 101 185 189 185 194 0 12 -168 182 -194 197 -9
5 -49 42 -89 83 -41 41 -76 75 -80 75 -4 0 -60 -54 -126 -119z m221 -244 c46
-46 94 -97 108 -113 l25 -29 -25 20 c-13 11 -65 60 -114 109 l-89 89 -95 -99
c-92 -96 -147 -153 -606 -628 -121 -124 -221 -224 -224 -221 -3 3 6 15 19 28
13 13 112 115 219 226 107 112 264 272 348 357 83 85 152 157 152 160 0 12
175 184 187 184 7 0 50 -38 95 -83z m-1040 -909 c-7 -7 -12 -8 -12 -2 0 14 12
26 19 19 2 -3 -1 -11 -7 -17z m-168 -171 c-77 -78 -166 -171 -199 -205 -150
-159 -392 -400 -344 -342 30 35 125 135 212 222 86 87 157 160 157 164 0 10
303 314 308 309 3 -3 -57 -69 -134 -148z m-604 -623 c0 -3 -28 -34 -62 -69
-35 -35 -103 -104 -152 -154 -49 -50 -91 -89 -93 -87 -5 5 -6 5 166 180 129
132 141 143 141 130z m555 -264 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m416 -421 c217 -220 565 -573 774 -783 600 -605 708
-716 703 -721 -2 -3 -274 268 -604 602 -329 334 -752 762 -939 951 -187 189
-342 345 -344 348 -2 2 0 4 6 4 6 0 188 -180 404 -401z m-1423 213 c-74 -75
-164 -168 -199 -206 -112 -121 -403 -412 -380 -381 41 56 703 736 710 729 3
-3 -56 -66 -131 -142z m767 -1 c82 -82 151 -155 154 -162 2 -6 244 -251 537
-545 294 -293 534 -536 534 -540 0 -4 220 -228 488 -498 1033 -1039 1092
-1099 1092 -1129 0 -22 -68 -95 -395 -422 -298 -299 -400 -395 -418 -395 -34
0 -2987 2952 -2987 2986 0 23 20 45 400 433 105 106 190 196 190 201 0 18 215
220 235 220 13 0 74 -54 170 -149z m-1464 -723 c-50 -51 -91 -98 -91 -105 0
-7 -4 -13 -9 -13 -5 0 -12 -12 -16 -27 -3 -14 -13 -36 -21 -47 -11 -17 -14
-18 -14 -4 0 9 4 19 9 23 5 3 12 19 16 35 4 17 11 30 16 30 5 0 9 6 9 13 0 15
180 200 188 192 3 -2 -36 -46 -87 -97z m-168 -267 c-6 -3 -14 -17 -18 -31 -7
-22 -38 -91 -106 -236 -10 -23 -19 -46 -19 -53 0 -6 -4 -11 -9 -11 -5 0 -12
-11 -15 -24 -6 -24 -61 -147 -122 -271 -19 -38 -31 -59 -28 -45 3 14 21 57 39
95 70 148 94 202 100 228 4 15 11 27 16 27 5 0 9 5 9 11 0 7 9 30 19 53 78
167 99 214 106 235 9 31 29 53 34 39 2 -6 -1 -13 -6 -17z m545 -368 c81 -80
146 -150 144 -155 -1 -4 2 -8 8 -8 14 0 90 -75 90 -89 0 -6 6 -11 13 -11 15 0
48 -36 40 -44 -4 -3 -1 -6 5 -6 17 0 52 -32 52 -47 0 -7 5 -13 11 -13 14 0
155 -141 152 -152 -2 -4 2 -8 8 -8 14 0 215 -201 212 -212 -2 -4 1 -8 7 -8 14
0 90 -75 90 -89 0 -6 5 -11 11 -11 14 0 139 -125 139 -139 0 -6 5 -11 11 -11
14 0 579 -564 579 -579 0 -6 5 -11 12 -11 13 0 58 -48 58 -62 0 -4 7 -8 16 -8
9 0 18 -9 21 -20 3 -11 9 -20 15 -20 13 0 358 -345 358 -359 0 -6 5 -11 11
-11 15 0 59 -42 59 -57 0 -7 7 -13 16 -13 9 0 18 -9 21 -20 3 -11 10 -20 15
-20 6 0 100 -90 209 -199 157 -157 199 -204 199 -225 0 -21 -5 -26 -25 -26
-14 0 -25 -4 -25 -8 0 -5 -19 -15 -42 -23 -24 -7 -59 -22 -78 -31 -19 -10 -42
-18 -52 -18 -10 0 -18 -4 -18 -9 0 -5 -12 -12 -26 -15 -15 -4 -33 -14 -41 -21
-8 -8 -25 -15 -38 -15 -13 0 -30 -7 -38 -15 -8 -8 -36 -20 -61 -27 -25 -7 -46
-16 -46 -20 0 -4 -16 -10 -35 -14 -19 -3 -35 -10 -35 -14 0 -4 -18 -13 -40
-20 -22 -7 -40 -17 -40 -22 0 -4 -8 -8 -17 -8 -16 0 -54 -16 -158 -66 -28 -13
-58 -24 -68 -24 -9 0 -17 -4 -17 -9 0 -5 -12 -12 -27 -16 -16 -4 -59 -22 -98
-40 -38 -19 -82 -37 -97 -40 -16 -4 -28 -10 -28 -14 0 -4 -20 -12 -45 -19 -25
-7 -45 -16 -45 -21 0 -5 -22 -14 -50 -21 -27 -7 -50 -16 -50 -21 0 -4 -16 -11
-35 -15 -19 -3 -35 -9 -35 -13 0 -4 -17 -13 -37 -20 -40 -13 -145 -60 -203
-90 -19 -10 -50 -22 -67 -26 -18 -3 -33 -10 -33 -15 0 -4 -12 -11 -27 -15 -15
-3 -46 -15 -70 -26 -23 -10 -49 -19 -57 -19 -9 0 -16 -4 -16 -10 0 -5 -18 -15
-40 -21 -22 -7 -40 -16 -40 -20 0 -4 -15 -10 -32 -14 -18 -4 -42 -13 -53 -19
-11 -7 -31 -16 -45 -19 -15 -4 -178 -160 -395 -377 -406 -407 -395 -399 -455
-340 -16 16 -30 33 -30 37 0 5 -52 60 -115 123 -63 63 -115 118 -115 123 0 5
-56 64 -125 132 -69 68 -125 128 -125 132 0 4 -49 58 -109 118 -60 61 -168
173 -240 250 -72 77 -181 191 -241 253 -61 62 -110 116 -110 120 0 4 -52 59
-115 122 -63 62 -115 120 -115 127 0 8 -7 16 -15 19 -8 4 -15 18 -15 34 0 23
59 87 364 392 280 280 366 372 375 401 7 20 16 37 21 37 5 0 12 16 16 35 3 19
10 35 15 35 5 0 8 3 8 8 -3 14 23 72 32 72 5 0 9 9 9 20 0 19 19 70 30 80 9 8
34 64 32 73 -1 4 2 7 7 7 5 0 12 16 15 35 4 19 10 35 15 35 4 0 13 18 20 40 6
22 16 40 21 40 6 0 10 9 10 21 0 11 15 48 33 82 18 34 39 81 46 105 8 23 17
42 22 42 4 0 10 12 14 28 7 29 46 114 56 122 3 3 12 22 18 43 7 20 17 37 22
37 5 0 9 8 9 17 0 24 23 83 32 83 4 0 10 16 14 35 3 19 10 35 14 35 4 0 13 18
20 40 7 22 17 40 21 40 5 0 11 16 15 35 3 19 10 35 15 35 5 0 8 3 8 8 -2 12
25 72 32 72 4 0 13 23 20 50 7 28 16 50 19 50 4 0 13 18 20 40 7 22 16 40 20
40 4 0 11 16 14 35 4 19 11 35 16 35 6 0 9 3 9 8 -2 11 25 72 32 72 4 0 10 16
13 35 4 19 11 35 16 35 5 0 7 7 4 15 -4 8 -2 15 4 15 5 0 13 11 16 25 4 14 11
25 16 25 5 0 7 7 4 15 -4 8 -2 15 4 15 5 0 13 16 16 35 4 19 11 35 15 35 5 0
12 11 15 25 5 20 13 25 37 25 25 0 52 -22 177 -147z m-887 -379 c-10 -21 -26
-55 -34 -74 -13 -28 -17 -30 -17 -13 0 12 4 24 9 27 5 3 12 18 16 33 7 30 34
77 41 71 2 -2 -5 -22 -15 -44z m4155 -26 l29 -33 -32 29 c-31 28 -38 36 -30
36 2 0 16 -15 33 -32z m64 -60 c0 -6 -6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1
15 -2 8 -4 15 -11 15 -16z m-4273 -35 c-2 -5 -11 -24 -19 -43 -9 -19 -30 -64
-46 -100 -16 -36 -43 -94 -58 -130 -16 -36 -31 -67 -35 -70 -3 -3 -14 -27 -24
-55 -11 -27 -29 -69 -41 -93 -12 -23 -28 -58 -34 -77 -14 -41 -25 -51 -27 -24
-1 10 2 19 8 19 5 0 9 6 9 13 0 6 13 37 29 67 15 30 36 79 47 108 10 28 22 52
26 52 4 0 8 4 8 10 0 10 33 85 86 196 19 38 34 73 34 77 0 10 31 57 37 57 2 0
2 -3 0 -7z m4696 -390 c413 -416 487 -493 487 -503 0 -4 7 -10 15 -14 8 -3 15
-11 15 -18 0 -6 -37 26 -81 73 -45 46 -238 242 -430 435 -311 314 -359 364
-345 364 2 0 154 -152 339 -337z m-4998 -278 c-10 -19 -20 -33 -23 -31 -2 3 3
22 13 41 9 20 19 34 22 32 2 -3 -3 -22 -12 -42z m-32 -73 c-3 -12 -9 -22 -14
-22 -5 0 -9 -6 -9 -12 0 -7 -11 -35 -25 -63 -26 -55 -28 -32 -1 27 9 21 16 42
16 48 0 5 4 10 9 10 5 0 11 10 14 22 4 12 8 20 11 17 3 -3 3 -15 -1 -27z
m-108 -227 c-19 -39 -34 -63 -34 -54 -1 8 6 24 14 35 8 10 15 26 15 34 0 16
30 64 36 58 2 -2 -12 -35 -31 -73z m-389 -2090 c38 -41 121 -127 184 -192 63
-64 114 -120 113 -123 -1 -4 52 -59 118 -124 65 -64 119 -121 119 -124 0 -4
27 -34 60 -67 35 -34 60 -68 60 -80 0 -12 -32 -52 -80 -100 -44 -44 -80 -83
-80 -87 0 -7 -265 -275 -303 -306 -13 -11 -26 -11 -74 -1 -32 7 -60 16 -63 19
-3 4 -29 13 -58 20 -28 6 -54 16 -57 20 -3 5 -23 11 -45 15 -22 4 -42 10 -45
15 -3 4 -28 14 -56 20 -29 7 -64 19 -78 26 -14 8 -35 14 -46 14 -11 0 -33 7
-48 15 -15 8 -43 17 -62 21 -19 3 -35 10 -35 14 0 4 -25 12 -55 19 -31 7 -68
19 -83 27 -15 8 -36 14 -47 14 -11 0 -33 7 -48 15 -15 8 -43 17 -62 21 -19 3
-35 10 -35 14 0 4 -29 14 -65 21 -61 12 -65 14 -65 41 0 21 19 46 85 111 47
46 85 88 85 93 0 5 61 70 135 143 74 74 135 138 135 142 0 4 72 79 160 166 88
86 160 161 160 166 0 15 74 85 91 86 8 0 47 -33 85 -74z m-876 -289 c-62 -63
-116 -120 -120 -126 -4 -6 -60 -66 -126 -133 -65 -68 -184 -192 -264 -276
-183 -193 -171 -181 -177 -181 -14 0 181 210 434 466 66 68 123 128 125 133 5
17 231 241 236 236 3 -3 -46 -56 -108 -119z"
        />
      </g>
    </svg>
  );
}
