import { useSelector, useDispatch } from "react-redux";
import "./DrawerModal.css";
import ListViewMenu from "../../pages/ListViewMenu";
import TilesViewMenu from "../../pages/TilesViewMenu";
import LibraryViewMenu from "../../pages/LibraryViewMenu";
import FeedMenu from "../../pages/FeedMenu";
import { Pages, setShowFilter } from "../../Utils/Features/currentPageTab";

export default function DrawerModal() {
  const dispatch = useDispatch();
  const closeHandle = () => {
    dispatch(setShowFilter(false));
  };

  const currentPageTab = useSelector(
    (state) => state?.currentPageTab?.currentPageTab
  );

  const NavigationHandler = () => {
    switch (currentPageTab) {
      case Pages.ListView:
        return <ListViewMenu />;
      case Pages.TilesView:
        return <TilesViewMenu />;
      case Pages.Library:
        return <LibraryViewMenu />;
      case Pages.Feed:
        return <FeedMenu />;
      default:
        return <span>Please Select Proper tab</span>;
    }
  };
  return (
    <div className="DrawerModalParent">
      <div className="headingSection">
        <span id="heading">{currentPageTab}</span>
        <span
          className="material-symbols-outlined"
          onClick={closeHandle}
          style={{ cursor: "pointer" }}
        >
          close
        </span>
      </div>
      <div className="contentsection">{NavigationHandler()}</div>
    </div>
  );
}
