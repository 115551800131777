import React, { useEffect, useState, useRef } from "react";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { styled } from "@mui/system";
import { typeEnum } from "../Insights/Topics";
import useOutsideClick from "../../helperFunctions/useOutsideClick";

const Input = styled("input")(({ isDropdown }) => ({
  width: 279,
  backgroundColor: "var(--tagPickerInputBackgroundColor)",
  borderRadius: "16px",
  paddingLeft: "14px",
  height: "30px",
  fontSize: "14px",
  fontWeight: "600",
  color: "var(--fontColor)",
  paddingBottom: "2px",
  zIndex: isDropdown ? 2 : "auto", // Adjust zIndex based on the isDropdown prop
  position: "relative",
  "&:focus-visible": {
    outline: "0px",
    background: "var(--tagPickerInputBackgroundColor)",
  },
  "&::placeholder": {
    color: "var(--fontColor)",
    fontSize: "12px",
    fontStyle: "italic",
  },
}));

const Listbox = styled("ul")(({ isDropdown }) => ({
  width: 279,
  margin: 0,
  padding: 0,
  zIndex: isDropdown ? 1 : "auto", // Adjust zIndex based on the isDropdown prop
  position: isDropdown ? "absolute" : "static", // Adjust position based on the isDropdown prop

  listStyle: "none",
  overflow: "auto",
  maxHeight: 135,
  borderRadius: "16px",
  backgroundColor: "var(--tagPickerInputBackgroundColor)",
  paddingLeft: "32px",
  paddingTop: "27px",
  marginTop: "-22px",
  "&::-webkit-scrollbar": {
    background: "transparent",
    width: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "transparent",
  },

  "&:focus-visible": {
    background: "var(--tagPickerBackgroundColor)",
  },
  "& li": {
    height: "30px",
    fontSize: "14px",
    color: "var(--fontColor)",
    display: "flex",
    paddingLeft: "32px",
    borderRadius: "16px",
    cursor: "pointer",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "var(--tagPickerBackgroundColor)",
      fontWeight: "600",
    },
  },
  '& li[data-focus="true"]': {
    backgroundColor: "var(--tagPickerBackgroundColor)",
    fontWeight: "600",
  },
  "& li:active": {
    backgroundColor: "var(--tagPickerBackgroundColor)",
    fontWeight: "600",
  },
}));

export default function TagPicker({
  setTags,
  presentTags,
  setListOpen,
  listOpen,
  type,
}) {
  const [tagList, setTagList] = useState(presentTags);
  const [searchTag, setSearchTag] = useState("");
  const tagPickerRef = useRef(null);

  useEffect(() => {
    if (presentTags) {
      setTagList(presentTags);
    }
    // Update the listOpen state when openTagList changes
  }, [presentTags]);

  useOutsideClick(tagPickerRef, setListOpen); //Change my dropdown state to close when clicked outside

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: tagList,
    open: listOpen,
    freeSolo: true,
    onClose: () => {
      // Close the list by updating the listOpen state
      // setListOpen(false);
    },
    getOptionLabel: (option) => option,
    onChange: (_, value) => {
      console.log("on change happens");
      setTags(value);
      setListOpen(false);
      setSearchTag("");
    },
    onKeyDown: (event, newValue) => {
      setSearchTag("");
    },
    onInputChange: (event, value) => {
      setSearchTag(value);
    },
  });

  return (
    <div
      className="mt-3 relative"
      onClick={(e) => {
        e.stopPropagation();
        if (typeEnum.CHAPTER === type) {
          // setListOpen(false);
        } else {
          setListOpen(!listOpen);
        }
      }}
      ref={tagPickerRef}
    >
      <div {...getRootProps()}>
        <Input
          placeholder="# Type here"
          {...getInputProps()}
          value={searchTag}
          isDropdown={type === typeEnum.CHAPTER}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value) {
              setTags(e.target.value);
              setSearchTag("");
            }
          }}
        />
      </div>
      <div>
        {groupedOptions.length > 0 ? (
          <Listbox
            {...getListboxProps()}
            isDropdown={type === typeEnum.CHAPTER}
          >
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>{option}</li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </div>
  );
}
