import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import Container from "@mui/material/Container";

import { createCheckoutSession } from "../../Utils/Payment/paymentSlice";
import LinkButton from "../../components/Common/Buttons/PrimaryButton";

import "./CancelPage.css";
import { buttonItems } from "../Success/SuccessPage";

const CancelPage = () => {
  const dispatch = useDispatch();
  const lastCheckoutSubscriptionType = useSelector(
    (state) => state?.payment.lastCheckoutSubscriptionType
  );

  const handleCheckout = () => {
    dispatch(
      createCheckoutSession({
        subscriptionType: lastCheckoutSubscriptionType,
      })
    );
  };

  const handleRedirect = (path) => {
    window.location.href = path;
  };

  return (
    <Container className="feedParentContainer feedWrapperOverflow">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <CardContent
              style={{
                textAlign: "center",
              }}
            >
              <Typography variant="h5">Payment cancelled!</Typography>
              <Typography variant="h6">
                If you did this on purpose, no hard feelings. If something went
                wrong, please try again or contact our support team for
                assistance. We´re here to help!
              </Typography>
              <Typography variant="body1" sx={{ pt: 2 }}>
                No payment processed
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={5} md={5} lg={4}>
          <Card style={{ height: "90%" }}>
            <CardContent>
              <img
                src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExMms4aGxtNWdpNGI0cmdqbHpndm9henlpOHB1ejhhcHQzd2x1Mmg1eCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/nWZOsARnPxlK0/giphy.gif"
                alt="Cancel page gif"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={5} md={5} lg={4}>
          <Card
            style={{
              height: "90%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent
              onClick={handleCheckout}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
              className="tryPaymentHover"
            >
              <Typography variant="h5" textAlign="center">
                Try the payment
                <br /> process again
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Card>
            <CardContent>
              <Typography variant="body1" textAlign="center">
                <a href="mailto: hi@deepread.com">
                  If issues persist, reach out to us <br />
                  hi@deepread.com
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
          >
            {buttonItems?.map((item, index) => (
              <LinkButton
                key={index}
                isPrimaryColor="true"
                isOpen={true}
                item={{
                  ...item,
                  onClick: () => handleRedirect(item.path),
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CancelPage;
