import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import PeopleAlt from "@mui/icons-material/PeopleAlt";

import DeepReadLogoCircle from "../../../Assets/LogoCircle.png";
import "./Ownership.css";

export const Ownership = ({ ownership, bigStyle, narrowStyle }) => {
  const buttonsStyle = bigStyle ? "buttonStyleBig" : "buttonStyle";
  const logoStyle = bigStyle ? "logoStyleBig" : "logoStyle";

  return (
    <>
      <span className="style">
        {typeof ownership === "string" && ownership?.startsWith("DeepRead") ? (
          <img src={DeepReadLogoCircle} alt="DeepRead" className={logoStyle} />
        ) : ownership === "Own" ? (
          <DiamondOutlinedIcon className={buttonsStyle} />
        ) : ownership === "Other" ? (
          <PeopleAlt className={buttonsStyle} />
        ) : (
          <PeopleAlt className={buttonsStyle} />
        )}
      </span>
      <span className="style">
        {ownership === "Own" ? (
          <span>
            <b>My own content</b>
          </span>
        ) : (
          <span>
            {!narrowStyle && <>Shared By:</>} <b>{ownership}</b>
          </span>
        )}
      </span>
    </>
  );
};
