import { useCallback, useEffect, useRef } from "react";

/**
 * Custom hook for determining if the component is currently mounted.
 * @returns {boolean} A function that returns a boolean value indicating whether the component is mounted.
 */
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
