import { useEffect } from 'react';

/**
 *
 * @param {*} ref - Ref of your parent div
 * @param {*} callback - Callback which can be used to change your maintained state in your component
 * 
 */
const useOutsideClick = (refs, setListOpen) => {
  useEffect(() => {
    const handleClickOutside = (evt) => {
      var refsList = refs instanceof Array ? refs : [refs];
      var refClicked = false;
      refsList.forEach((ref) => {
        if (ref.current && ref.current.contains(evt.target)) {
          refClicked = true;
        }
      });
      if (!refClicked) {
        setListOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export default useOutsideClick;