import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

const MyCheckbox = ({ label, isChecked, handleCheckboxChange }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
      label={<Typography variant="body1">{label}</Typography>}
    />
  );
};

export default MyCheckbox;
