import S3Storage from "../components/S3storage/s3storage";
import { getStringEnvVariable } from "./envVars";
const s3 = new S3Storage();

function createImage(options) {
  options = options || {};
  const img = Image ? new Image() : document.createElement("img");
  if (options.src) {
    img.src = options.src;
  }
  return img;
}

function convertToPng(imgBlob) {
  try {
    const imageUrl = window.URL.createObjectURL(imgBlob);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageEl = createImage({ src: imageUrl });
    imageEl.onload = (e) => {
      canvas.width = e.target.width;
      canvas.height = e.target.height;
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(copyToClipboard, "image/png", 1);
    };
  } catch (error) {
    console.error(error);
  }
}

async function copyImg(src) {
  try {
    const isS3Image = src.includes(new S3Storage().getEndpoint());
    const isPexelImage = src.includes("images.pexels.com");
    const corsProxy = getStringEnvVariable(
      "REACT_APP_CORS_PROXY",
      "https://corsproxy.io/?"
    );
    // no need to use proxy for the s3 storage images
    const fetchUrl = isS3Image || isPexelImage ? src : corsProxy + src;
    const img = await fetch(fetchUrl)
      .then((res) => {
        if (!res.ok) {
          console.error("Fetch not ok");
          return false;
        } else {
          return res;
        }
      })
      .catch((err) => {
        console.error("Fetch failed", err);
        return false;
      });
    if (img) {
      const imgBlob = await img.blob();
      const extension = src.split(".").pop().split("?").shift();
      const supportedToBeConverted = ["jpeg", "jpg", "gif", "png"];
      if (
        supportedToBeConverted.indexOf(extension.toLowerCase()) > -1 ||
        isS3Image // s3 storage images need to be converted even if png
      ) {
        convertToPng(imgBlob);
      } else {
        console.error("Format unsupported");
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
}

async function copyToClipboard(pngBlob) {
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        [pngBlob.type]: pngBlob,
      }),
    ]);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Copies the image to clipboard
 * (checks if image is s3 stored and creates the url for it, otherwise use src as is)
 *
 * @param {string} src - s3 image name / image url
 * @returns {Promise} Promise resulting to true or false depending on if the copy to clipboard was successful
 */

export function copyImageToClipboard(src) {
  if (src?.length > 0) {
    return copyImg(s3.getReloadImageLink(src));
  }
  return Promise.resolve(false);
}
