import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import "./Pages_V2.css";
import { iconProvider } from "../helperFunctions/iconProvider";
import Filter from "../components/Common/Filter";
import { updateListViewFilter } from "../Utils/Features/userConfigSlice";
import { ignoreOrderCompare } from "../helperFunctions/equalityChecker";
import CloseIcon from "@mui/icons-material/Close";
import { sortButtonsStyle } from "../components/Common/Filter";
import CommonInput from "../components/Common/CommonInput";
import { getAllIdeaIcons } from "../helperFunctions/getIdeacardIcons";

const routes = [
  {
    path: "/",
    title: "Highlights",
    icon: "highlights",
  },
  {
    path: "/users",
    title: "Idea cards",
    icon: "ideaCards",
    subRoutes: [
      {
        title: "KEYWORDS",
        icon: "vpn_key",
        isChecked: true,
      },
    ],
  },
];

let listLevels = [
  {
    title: "1st Level:Chapter",
    icon: "Playlist_Add",
    isChecked: true,
    className: "level-1",
  },
];

export const collectSelectedLevels = (data) => {
  const filteredDataTrue = data.filter((item) => item.isChecked === true);
  const filteredDataFalse = data.filter((item) => item.isChecked === false);
  filteredDataTrue.forEach((item) => {
    const divChilds = document.querySelectorAll(`.${item.className}`);
    divChilds.forEach((div) => {
      div.classList.remove("d-none");
    });
  });
  filteredDataFalse.forEach((item) => {
    const divChilds = document.querySelectorAll(`.${item.className}`);
    divChilds.forEach((div) => {
      div.classList.add("d-none");
    });
  });
};

const Structurerenderer = () => {
  const dispatch = useDispatch();
  let levelCount = useSelector((state) => state.levelCounterReducer.value);
  const { listViewFilter: filter } = useSelector((state) => state.userConfig);
  const [listLevelState, setListLevelState] = useState(
    filter?.showChapters ?? listLevels
  );

  const selectedList = (index) => {
    const tempList = JSON.parse(JSON.stringify(listLevelState));
    tempList[index].isChecked = !tempList[index].isChecked;
    setListLevelState(tempList);
    dispatch(updateListViewFilter({ showChapters: [...tempList] }));
  };

  const levelObjectConstructor = (level) => {
    switch (level) {
      case 1:
        return "1st Level:Chapter";
      case 2:
        return "2st Level:Sub-Chapter";
      case 3:
        return "3rd Level:Section";
      case 4:
        return "4th Level:Sub-Section";
      case 5:
        return "5th Level:Sub-Sub-Section";
      default:
        return "nth Level";
    }
  };
  useEffect(() => {
    if (levelCount - 1 !== listLevels.length) {
      listLevels = [];
      for (let i = 1; i < levelCount; i++) {
        const obj = {
          id: i,
          title: levelObjectConstructor(i),
          icon: "Playlist_Add",
          isChecked: true,
          className: `level-${i}`,
        };
        listLevels.push(obj);
        setListLevelState(listLevels);
      }
    }
  }, [levelCount]);

  return (
    <>
      <div className="pt-[15px]">
        <span className="text-sm textPrimary">Structure by:</span>
      </div>
      <div className="viewMenuSpacing">
        {/* <StructureBySvg /> */}
        <div className="flex flex-col gap-[5px]">
          <div className="radioInputs ">
            {listLevelState?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={
                    item.isChecked
                      ? `activeState_Modified selected textPrimary`
                      : `link_Modified bg-[var(--BackgroundColor)] textPrimary`
                  }
                  onClick={() => selectedList(i)}
                  style={{
                    gap: "4px",
                    width: "185px",
                    marginLeft: `${14 * i}px`,
                  }}
                >
                  {iconProvider(item.icon)}
                  <div
                    style={{ textTransform: "capitalize" }}
                    className={`link_text ellipsis_Style`}
                  >
                    {item.title}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export const collectSelectedIdeas = (data) => {
  const filteredDataTrue = data.filter((item) => item.isChecked === true);
  const filteredDataFalse = data.filter((item) => item.isChecked === false);
  filteredDataTrue.forEach((item) => {
    const liChilds = document.querySelectorAll(`.ideacard-${item.id}`);
    liChilds.forEach((li) => {
      li.classList.remove("d-none");
    });
  });
  filteredDataFalse.forEach((item) => {
    const liChilds = document.querySelectorAll(`.ideacard-${item.id}`);
    liChilds.forEach((li) => {
      li.classList.add("d-none");
    });
  });
};

const ContentListRenderer = () => {
  const dispatch = useDispatch();

  const { listViewFilter: filter } = useSelector((state) => state.userConfig);

  const [bookmarkState, setBookmarkState] = useState(false);
  const [ideaCardActiveState, setIdeaCardActiveState] = useState(
    filter.showIdeacards
  );
  const [counter, setCounter] = useState(0);
  const [selectedRoutes, setSelectedRoutes] = useState(routes[1].subRoutes);
  const allIcons = getAllIdeaIcons();

  const stateCheckerLoopContent = () => {
    let selectCounter = 0;
    for (let i = 0; i < routes[1].subRoutes.length; i++) {
      if (routes[1].subRoutes[i].isChecked) {
        selectCounter++;
      }
    }

    if (selectCounter) {
      setIdeaCardActiveState(true);
    } else {
      setIdeaCardActiveState(false);
    }
  };

  const bookmarkClicked = () => {
    if (counter === 0) {
      stateCheckerLoopContent();
      setBookmarkState(true);
      setCounter(1);
    } else if (counter === 1) {
      setBookmarkState(false);
      setCounter(0);
      stateCheckerLoopContent();
    }
  };

  useEffect(() => {
    stateCheckerLoopContent();
    if (selectedRoutes?.length > 0) {
      const showIdeacards = {};
      selectedRoutes?.forEach((ideacardtype) => {
        showIdeacards[ideacardtype.title] = {
          isChecked: ideacardtype.isChecked,
          id: ideacardtype.id,
        };
      });
      if (!ignoreOrderCompare(filter.showIdeacards, showIdeacards)) {
        dispatch(
          updateListViewFilter({
            showIdeacards: showIdeacards,
          })
        );
      }
    }
    // adding the stateCheckerLoopContent causes infinite loop,
    // TODO: is there a way to do this without infinite loop with the correct dependency ??
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoutes]);

  const showMenuClickHandler = (menuItem, id) => {
    if (menuItem === "Highlights") {
      let showHighlights = !filter.showHighlights;
      dispatch(updateListViewFilter({ showHighlights: showHighlights }));
      const highlightButton = document.querySelector(
        `#${id}                                                                  `
      );

      if (showHighlights) {
        highlightButton.classList.remove(`link_Modified`, `bg-transparent`);
        highlightButton.classList.add(`activeState_Modified`, `selected`);
      } else {
        highlightButton.classList.add(`link_Modified`, `bg-transparent`);
        highlightButton.classList.remove(`activeState_Modified`, `selected`);
      }
    }
  };

  useEffect(() => {
    const stateFullAllIcons = allIcons?.map((item) => ({
      title: item.label.toLowerCase(),
      icon: item.label,
      isChecked:
        filter.showIdeacards && filter.showIdeacards[item.label.toLowerCase()]
          ? filter.showIdeacards[item.label.toLowerCase()].isChecked
          : true,
      id: item._id,
    }));
    routes[1].subRoutes = stateFullAllIcons;
    if (selectedRoutes === null) {
      setSelectedRoutes(routes[1].subRoutes);
    }

    // we want this effect to run only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pt-[15px]">
        <span className="text-sm textPrimary">Filter:</span>
      </div>
      <div className="viewMenuSpacing">
        <div className="flex flex-col gap-[5px]">
          {routes.map((route, index) => {
            if (route.subRoutes?.length) {
              return (
                <div key={route.title}>
                  <Filter
                    filterItemList={route.subRoutes}
                    FilterIcons={LightbulbOutlinedIcon}
                    filterByTitle={"Idea cards"}
                    enableSearch={false}
                    enableSelectAll={true}
                    showFilter={bookmarkState}
                    setShowFilter={setBookmarkState}
                    filterType={"ListView"}
                    setSelectedRoutes={setSelectedRoutes}
                    page={"ListView"}
                  />
                </div>
              );
            }

            return (
              <button
                key={index}
                className={
                  filter.showHighlights
                    ? `activeState_Modified selected textPrimary`
                    : `link_Modified textPrimary`
                }
                id={route.title + "-" + index}
                onClick={() =>
                  showMenuClickHandler(route.title, route.title + "-" + index)
                }
              >
                {iconProvider(route.icon)}
                <div className="link_text">{route.title}</div>
                {filter.showHighlights ? (
                  <div className="pr-[6px] ml: 1 cursor-pointer h-[30px] flex items-center justify-center ml-auto">
                    <CloseIcon sx={sortButtonsStyle} />
                  </div>
                ) : (
                  <div className="checkbox ml-auto">
                    <CommonInput
                      type="checkobx"
                      label="custom-style"
                      inputClass="filter-checkbox-wrapper"
                      wrapperClass="filter-checkbox filter-checkbox-no-margin"
                      name="name"
                      labelClass="input-label"
                    />
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

const ListViewMenu = () => {
  return (
    <div>
      <Structurerenderer />
      <div className="mt-[15px]">
        <hr />
      </div>
      <ContentListRenderer />
    </div>
  );
};
export default ListViewMenu;
