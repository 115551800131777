import { toast } from "react-toastify";

export const toastType = {
  ERROR: "error",
  SUCCESS: "success",
  EXTENSION: "extension",
  RELOGG: "relogg",
  INFO: "info",
  WARNING: "warning",
};
export default function toastMessage(type, time, msg) {
  if (toastType.ERROR === type) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: time,
    });
  }
  if (toastType.SUCCESS === type) {
    toast.success(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: time,
    });
  }
  if (toastType.INFO === type) {
    toast.info(msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: time,
    });
  }
  if (toastType.WARNING === type) {
    toast.warning(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: time,
    });
  }
}
