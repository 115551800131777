import { useDispatch } from "react-redux";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useMediaQuery } from "@mui/material";

import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { like, dislike } from "../../../Utils/Features/suggestionSlice";

const SuggestionBottom = ({ feeditem }) => {
  const dispatch = useDispatch();

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const socialStyle = { fontSize: isSmDown ? "1.5rem" : "2rem" };

  const onLike = () => {
    dispatch(like(feeditem._id));
  };
  const onDislike = () => {
    dispatch(dislike(feeditem._id));
  };

  return (
    <div className="pt-[20px]">
      <div className="flex justify-evenly">
        <PrimaryButton
          onClick={onLike}
          buttonClass="text75Primary backgroundGreyNoBorder"
        >
          <FavoriteIcon
            sx={{
              fontSize: isSmDown ? "1.5rem" : "2rem",
              fontColor: "--var(primaryColor)",
            }}
          />
        </PrimaryButton>
        <PrimaryButton
          onClick={onDislike}
          buttonClass="textBlack backgroundGreyNoBorder"
        >
          <CloseRoundedIcon sx={socialStyle} />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SuggestionBottom;
