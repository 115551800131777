import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { useMediaQuery } from "@mui/material";

import { DeepPopper } from "../../Common/DeepPopper/DeepPopper";
import { Share } from "../../../components/Common/Share";
import { like } from "../../../Utils/Features/feedSlice";
import { FollowBook } from "../../Book/FollowBook";

const FeedBottom = ({ feeditem }) => {
  const [shareOpen, setShareOpen] = useState(false);
  const shareRef = useRef(null);
  const dispatch = useDispatch();

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const socialStyle = { fontSize: isSmDown ? "1.8rem" : "2.2rem" };
  const LikeIconComponent = feeditem.likedByMe
    ? FavoriteIcon
    : FavoriteBorderIcon;
  return (
    <div className="pt-[20px]">
      <div className="flex justify-evenly">
        <span className="flex items-baseline">
          <LikeIconComponent
            className={`socialButtonStyle ${
              feeditem.likeCount && "primaryColor"
            } `}
            sx={socialStyle}
            onClick={() => {
              dispatch(like({ feeditem, like: !feeditem.likedByMe }));
            }}
          />
          <span>{feeditem.likeCount || ""}</span>
        </span>
        <span>
          <FollowBook
            bookData={feeditem?.book[0]}
            iconStyle={socialStyle}
            showCount={true}
          />
        </span>
        <span>
          <ShareIcon
            className="socialButtonStyle"
            sx={socialStyle}
            onClick={() => {
              setShareOpen(true);
            }}
            ref={shareRef}
          />
          <DeepPopper
            anchorEl={shareRef?.current}
            open={shareOpen}
            closeHandler={() => {
              setShareOpen(false);
            }}
          >
            <Share
              closeHandler={() => {
                setShareOpen(false);
              }}
              text={`"${feeditem?.title}" \n\n -- ${feeditem?.book[0]?.title} (${feeditem?.book[0]?.author})`}
              pictureLink={feeditem.picture_link}
            />
          </DeepPopper>
        </span>
      </div>
    </div>
  );
};

export default FeedBottom;
