import React from "react";
import { Badge, Box } from "@mui/material";

const IconWithActivity = ({ icon: IconComponent, hasActivity = false }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Badge
        variant="dot"
        sx={{
          "& .MuiBadge-dot": {
            backgroundColor: `var(--primaryColor)`,
          },
        }}
        invisible={!hasActivity}
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <IconComponent />
      </Badge>
    </Box>
  );
};

export default IconWithActivity;
