import { useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import "../MyLibrary/MyLibrary.css";

import { getCurrentBook, updateBook } from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";

import { BookIntro } from "../../components/Book/Intro";
import { MyNotes } from "../../components/Insights/MyNotes";
import { Topics } from "../../components/Insights/Topics";
//import { Recommendation } from "../../components/Insights/Recommendation";
import { isNullOrUndefinedOrEmpty } from "../../helperFunctions/isNullOrUndefinedOrEmpty";
import { DeepTabs } from "../../components/Common/Tabs";
import { Loading } from "../../components/Loading";
import { useWindowSize } from "../../helperFunctions/useWindowSize";

export default function BookView() {
  const dispatch = useDispatch();
  const [tabHeight, setTabHeight] = useState(null);
  const introRef = useRef(null);
  const viewContainerRef = useRef(null);
  const windowSize = useWindowSize();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const bookData = useSelector((state) => getCurrentBook(state));

  const library = useSelector((state) => state.library);
  const { libraryStatus } = library;

  const BookTabs = [
    { label: "Notes" },
    { label: "Tags" },
    /*{ label: "Recomended", disabled: true },*/
  ];

  useLayoutEffect(() => {
    const viewElement = viewContainerRef.current;
    const topElement = introRef.current;
    if (viewElement && topElement) {
      var height =
        viewElement.getBoundingClientRect().height -
        topElement.getBoundingClientRect().height;
      if (isSmDown) {
        height -= 160;
      } else {
        height -= 110;
      }
      setTabHeight(height);
    }
  }, [viewContainerRef, introRef, isSmDown, windowSize]);

  const updateHandlerBooks = (id, newData) => {
    dispatch(
      updateBook({
        bookId: id,
        newData: newData,
      })
    );
  };

  return (
    <div className="feedParentContainer" ref={viewContainerRef}>
      {libraryStatus === ApiStatus.Pending ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="feedBoxLayout">
            <div
              key={bookData?.id}
              id={bookData?.title}
              className="libraryListsContainer bookViewContainer"
            >
              <Stack direction={"column"} sx={{ width: "100%" }}>
                <div ref={introRef}>
                  <BookIntro bookData={bookData} />
                </div>
                <div
                  className={`tabWrapper  px-[11px]
             ${
               isNullOrUndefinedOrEmpty(bookData?.tags)
                 ? "disabled_second"
                 : null
             }
             ${
               isNullOrUndefinedOrEmpty(bookData?.my_notes)
                 ? "disabled_first"
                 : null
             }
            ${
              isNullOrUndefinedOrEmpty(bookData?.recomendation)
                ? "disabled_third"
                : null
            }
              `}
                >
                  {bookData && (
                    <DeepTabs tabsInfo={BookTabs}>
                      {/* Notes */}
                      <MyNotes
                        id={bookData?._id}
                        type={"book"}
                        updateHandler={updateHandlerBooks}
                        tabHeight={tabHeight}
                      />
                      {/* Tags */}
                      <div className="max-h-[240px] overflow-y-auto">
                        <Topics
                          id={bookData?._id}
                          type={"book"}
                          updateHandler={updateHandlerBooks}
                        />
                      </div>
                      {/* recomendation */}
                      {/* <Recommendation id={bookData?._id} /> */}
                    </DeepTabs>
                  )}
                </div>
              </Stack>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
