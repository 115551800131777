import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { fetchTags, follow, unfollow } from "./librarySlice";
import { getHeaders } from "./authSlice";
import { ApiStatus } from "./ApiStatus";
import { setFeedFilter } from "./userConfigSlice";
import { getApiUrl } from "../../helperFunctions/envVars";
import {
  like as likeSuggestion,
  dislike as dislikeSuggestion,
} from "./suggestionSlice";

const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  feedList: [],
  page: 1,
  tags: [],
  tagsStatus: ApiStatus.NotRun,
  paginationInfo: null,
};

export const feed = createAsyncThunk("feed/list", async (filter, thunkAPI) => {
  const page = thunkAPI.getState().feed.page;
  const response = await axios.post(
    `${apiRoot}/feed/list?page=${page}`,
    filter,
    getHeaders()
  );
  return response.data;
});

export const fetchFeedTags = createAsyncThunk(
  "feed/fetchFeedTags",
  async (type, thunkAPI) => {
    return fetchTags("idea,highlight", thunkAPI);
  }
);

export const like = createAsyncThunk(
  "feed/like",
  async ({ feeditem, like }) => {
    const data = { id: feeditem._id, type: feeditem.type, like };
    const response = await axios.put(
      `${apiRoot}/feed/like`,
      data,
      getHeaders()
    );
    return {
      ...data,
      ...response.data,
    };
  }
);

export const feedSlice = createSlice({
  name: "feedSlice",
  initialState,
  reducers: {
    incrementPage: (state, action) => {
      state.page++;
    },
  },
  extraReducers: (builder) => {
    // new feed filter is set in userConfigSlice => reset feedlist and page values for new feed
    builder.addCase(setFeedFilter, (state) => {
      state.feedList = [];
      state.page = 1;
    });
    builder.addCase(feed.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(feed.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.paginationInfo = payload.pagination;
      if (state.page > 1) {
        state.feedList = [...state.feedList, ...payload.data];
      } else {
        state.feedList = payload.data;
      }
    });
    builder.addCase(feed.rejected, (state) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder
      .addCase(fetchFeedTags.pending, (state, action) => {
        state.tagsStatus = ApiStatus.Pending;
      })
      .addCase(fetchFeedTags.fulfilled, (state, action) => {
        state.tagsStatus = ApiStatus.Fulfilled;
        state.tags = action.payload;
      })
      .addCase(fetchFeedTags.rejected, (state, action) => {
        state.tagsStatus = ApiStatus.Rejected;
      });
    builder
      .addCase(like.fulfilled, (state, action) => {
        const { id, like, likeCount } = action.payload;
        state.feedList.forEach((feeditem) => {
          if (feeditem._id === id) {
            feeditem.likedByMe = like;
            feeditem.likeCount = likeCount;
          }
        });
      })
      .addCase(follow.fulfilled, (state, action) => {
        updateFeedBook(state, action.payload.bookId, action.payload.updateData);
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        updateFeedBook(state, action.payload.bookId, action.payload.updateData);
      })
      .addCase(likeSuggestion.fulfilled, (state, action) => {
        removeItem(state, action.payload.id);
      })
      .addCase(dislikeSuggestion.fulfilled, (state, action) => {
        removeItem(state, action.payload.id);
      });
  },
});

const updateFeedBook = (state, bookId, updateData) => {
  state.feedList.forEach((feeditem) => {
    if (feeditem.book[0]?._id === bookId) {
      feeditem.book[0] = { ...feeditem.book[0], ...updateData };
    }
  });
};

const removeItem = (state, id) => {
  state.feedList = state.feedList.filter((item) => item._id !== id);
};

export const { incrementPage } = feedSlice.actions;
export const feedSliceReducer = feedSlice.reducer;
export default feedSliceReducer;
