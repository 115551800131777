import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { itemIdCreator } from "../../pages/MyLibrary/MyLibrary";
import { useNavigate } from "react-router-dom";

import {
  fetchBookList,
  fetchListViewData,
  selectBook,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus";
import useOutsideClick from "../../helperFunctions/useOutsideClick";
import { handleDrawerClose } from "../Drawer/Drawer";
import useOutsideScroll from "../../helperFunctions/useOutsideScroll";

// Define the regular expression pattern to match the desired format
const regexPattern = /\/library\/([^/]+)\/(tileview|listview)/;

const AllowBookSelect = ({
  pageTitle,
  isOpenBookSelectionDropDown,
  setIsOpenBookSelectionDropDown,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bookList, bookListStatus } = useSelector((state) => state.library);
  const tileViewData = useSelector((state) => state.library.listViewData);
  const currentBook = useSelector((state) => state.library.currentBook);
  const currentLocation = window.location.pathname;

  // Check if the current location matches the desired format
  const match = currentLocation.match(regexPattern);

  const inputRef = useRef(null);
  const dropDownPickerRef = useRef(null);

  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  useOutsideClick(dropDownPickerRef, setIsOpenBookSelectionDropDown); //Change my dropdown state to close when clicked outside
  useOutsideScroll(dropDownPickerRef, setIsOpenBookSelectionDropDown); //Change my dropdown state to close when scrolled outside

  useEffect(() => {
    if (!isOpenBookSelectionDropDown) {
      setQuery("");
    }
  }, [isOpenBookSelectionDropDown]);

  useEffect(() => {
    if (bookList?.length === 0 && bookListStatus !== ApiStatus.Fulfilled) {
      dispatch(fetchBookList());
    }
    if (selectedOption) {
      if (selectedOption?._id !== currentBook?._id) {
        // need to close the possibly open ideacard, because in the case
        // that it was newly created ideacard switching books would trigger
        // recreation (resave) of that ideacard (and in any case it would
        // be weird for an ideacard from another book to stay open):
        handleDrawerClose();
        dispatch(selectBook(selectedOption._id));
      }

      if (!tileViewData && selectedOption) {
        dispatch(fetchListViewData(selectedOption?._id));
      }

      if (match) {
        const newMiddlePart = itemIdCreator(
          selectedOption && selectedOption?.title
        );

        // Replace the middle part of the path
        const newLocation = currentLocation.replace(
          regexPattern,
          "/library/" + newMiddlePart + "/$2"
        );
        navigate(newLocation);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
    // we want the eventlistener to be added only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOption = (option) => {
    setSelectedOption(option);
    setQuery(() => "");
    setIsOpenBookSelectionDropDown(
      (isOpenBookSelectionDropDown) => !isOpenBookSelectionDropDown
    );
  };

  function toggle(e) {
    setIsOpenBookSelectionDropDown(!isOpenBookSelectionDropDown);
  }

  const getDisplayValue = () => {
    if (query) return query;

    return "";
  };

  const filter = (options, query) => {
    const filteredOptions = options?.filter(
      (option) =>
        option?.title?.toLowerCase()?.indexOf(query?.toLowerCase()) > -1
    );

    if (filteredOptions.length === 0) {
      return ["No data found"];
    } else {
      return filteredOptions;
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div
      className={`w-full flex justify-center ${
        !isOpenBookSelectionDropDown && "custom-search"
      }`}
      ref={dropDownPickerRef}
    >
      <div
        className={`dropdown w-full min-w-[330px]  ${
          pageTitle === "bookDetails"
            ? "bookDetailsWrapper w-full"
            : "max-w-[400px]"
        } `}
      >
        <div className="control">
          <div className="selected-value">
            <input
              ref={inputRef}
              type="text"
              value={getDisplayValue()}
              name="searchTerm"
              onChange={(e) => handleChange(e)}
              onClick={toggle}
              autoComplete="off"
              className="border textDarkWithBackgroundAndBorder"
              placeholder="Select book..."
            />
          </div>
          <div
            className={`arrow ${isOpenBookSelectionDropDown ? "open" : ""}`}
          ></div>
        </div>

        <div
          className={`options rounded-[4px] textDarkWithBackgroundAndBorder ${
            isOpenBookSelectionDropDown ? "open" : ""
          }`}
        >
          {filter(bookList, query).map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={`py-2 option ${
                  option?.title === selectedOption?.title ? `selected` : ""
                }`}
                key={`${option?._id}`}
              >
                <div className="flex gap-x-2 items-center pl-2">
                  {option?.img_path && (
                    <div className="w-[20px] basis-[10%] max-w-[20px]">
                      <img
                        src={option?.img_path}
                        className="w-[20px] h-[35px]"
                        alt="img"
                      />
                    </div>
                  )}
                  <div className="w-[20px] basis-[90%] text-left">
                    {option?.title?.length ? (
                      <>{option?.title}</>
                    ) : (
                      <>{option}</>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AllowBookSelect;
