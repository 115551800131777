import { useSelector } from "react-redux";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import Container from "@mui/material/Container";

import successMessages from "./successMessages";
import LinkButton from "../../components/Common/Buttons/PrimaryButton";

import "./SuccessPage.css";

export const buttonItems = [
  {
    path: "/feed",
    linkText: "feed",
    name: "Feed",
    icon: "DynamicFeedOutlined",
  },
  {
    path: "/library",
    linkText: "library",
    name: "Library",
    icon: "LibraryBooksOutlined",
  },
  {
    path: "/profile",
    page: "Profile",
    linkText: "profile",
    name: "Profile",
    icon: "profile_icon",
  },
];

const SuccessPage = () => {
  const { profileData } = useSelector((state) => state?.profileSliceReducer);

  const getSuccessMessage = () => {
    const today = new Date().getDay();
    return successMessages[today];
  };
  const handleRedirect = (path) => {
    window.location.href = path;
  };
  const successMessage = getSuccessMessage();

  return (
    <Container className="feedParentContainer feedWrapperOverflow">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={0}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="h5">{successMessage}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    paddingRight: "25px",
                    paddingTop: "15px",
                  }}
                >
                  <Typography>Payment succeeded</Typography>
                </Grid>
                <Grid item xs={6} className="leftLabel">
                  <Typography>Subscription:</Typography>
                </Grid>
                <Grid item xs={6} className="rightValue">
                  <Typography>{profileData?.subscriptionType}</Typography>
                </Grid>
                <Grid item xs={6} className="leftLabel">
                  <Typography>Status:</Typography>
                </Grid>
                <Grid item xs={6} className="rightValue">
                  <Typography>{profileData?.paymentStatus}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={5}>
          <Card>
            <CardContent>
              <img
                src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExbDN1aGt5cmVxNTZ2dTYwOXg4c25qcGpjdjNtd3ZpZ2hqb3Z3djlzMyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/dH4eBrNQXB8S4/giphy.gif"
                alt="Success page gif"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
          >
            {buttonItems?.map((item, index) => (
              <LinkButton
                key={index}
                isPrimaryColor="true"
                isOpen={true}
                item={{
                  ...item,
                  onClick: () => handleRedirect(item.path),
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuccessPage;
