import { useDispatch, useSelector } from "react-redux";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

import { follow, unfollow } from "../../Utils/Features/librarySlice";

export const FollowBook = ({ bookData, showCount, iconStyle }) => {
  const IconComponent =
    showCount && !bookData?.followedByMe ? BookmarkBorderIcon : BookmarkIcon;
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const isPrimaryColor =
    (showCount && bookData?.followCount > 0) || bookData?.followedByMe;

  return (
    bookData?.user_id !== userId && (
      <span className="flex items-baseline">
        <IconComponent
          className={`socialButtonStyle ${isPrimaryColor && "primaryColor"}`}
          sx={iconStyle}
          onClick={(e) => {
            e.stopPropagation();
            if (bookData?.followedByMe) {
              dispatch(unfollow(bookData?._id));
            } else {
              dispatch(follow(bookData?._id));
            }
          }}
        />
        {showCount && <span>{bookData?.followCount || ""}</span>}
      </span>
    )
  );
};
