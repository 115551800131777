import { useEffect, useCallback, useState } from "react";
import {
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { isMobile } from "react-device-detect";

import { copyImageToClipboard } from "../../helperFunctions/copyToClipboard";

export const Share = ({ closeHandler, text, pictureLink }) => {
  const [imageIsOnClipboard, setImageIsOnClipboard] = useState(false);
  const shareUrl = "https://www.deepread.com";
  const title = "Using DeepRead to share my knowledge.";
  const via = "deepread_com";
  const wholeText = text ? `${text}\n\n${title}` : title;

  const shareButton = {
    padding: "5px 4px 0px",
  };
  const infoText = {
    padding: "0px 4px",
  };
  const shareIconSize = 28;

  const copyToClipboard = useCallback(() => {
    copyImageToClipboard(pictureLink).then((success) => {
      setImageIsOnClipboard(success);
    });
  }, [pictureLink]);

  useEffect(() => {
    copyToClipboard();
  }, [copyToClipboard]);

  return (
    <>
      <div onClick={closeHandler}>
        <WhatsappShareButton
          url={shareUrl}
          title={wholeText}
          style={shareButton}
        >
          <WhatsappIcon size={shareIconSize} round />
        </WhatsappShareButton>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body={text}
          style={shareButton}
        >
          <EmailIcon size={shareIconSize} round />
        </EmailShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={wholeText}
          style={shareButton}
        >
          <TelegramIcon size={shareIconSize} round />
        </TelegramShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={wholeText}
          style={shareButton}
          via={via}
        >
          <XIcon size={shareIconSize} round />
        </TwitterShareButton>
      </div>
      {imageIsOnClipboard & !isMobile ? (
        <div style={infoText}>
          Image on clipboard
          <br /> and ready to paste!
        </div>
      ) : null}
    </>
  );
};
