import { createSlice } from "@reduxjs/toolkit";

export const IdentifyIdeaCardSlice = createSlice({
  name: "IdentifyIdeaCardData",
  initialState: {
    value: null,
    currentTab: 0,
  },
  reducers: {
    updateIdentifyIdeaCardData: (state, action) => {
      if (action.payload !== null) {
        state.value = action.payload;
      }
    },
    // used to set the current tab of ideaCard drawer
    setCurrentTab: (state ,action) =>{
      if (action.payload !== null) {
        state.currentTab = action.payload;
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateIdentifyIdeaCardData, setCurrentTab } = IdentifyIdeaCardSlice.actions;

export default IdentifyIdeaCardSlice.reducer;


