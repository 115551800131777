   const successMessages = {
    1: "Thanks for the purchase! Our coffee fund just got a boost, and our developers can now code with extra zest (and caffeine)!​",
    2: "Hats off to you! Your purchase means our QA team can afford more sticky notes for bug tracking. Every little bit helps!​",
    3: "Thank you! You've just contributed to our snack stash – and happy developers mean happier code for you!​",
    4: "High five! Your purchase has brought us one step closer to upgrading our office plants. Happy plants, happy team!​!​",
    5: "Cheers for the support! Your contribution keeps the lights on and our code crunchers crunching. Stay awesome!​​",
    6: "You're a hero! Thanks to your purchase, we can now afford another round of celebratory donuts. Code fueled by sugar!​!​",
    7: "A big thank you from the founders and the entire team! Your purchase means we're one step closer to upgrading our home offices – perhaps with an ergonomic chair or a faster internet connection!​!​",
  };

  export default successMessages;