import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export const Loading = ({ small, cssColorVariable }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100vh" }}
    >
      <div
        style={{
          // TODO if this is the right location (add the pointer functionality, making it clear, that this is a button able to being pressed)
          cursor: "pointer", // Change cursor to pointer on hover
        }}
      >
        <CircularProgress
          size={small ? "1.2rem" : undefined}
          sx={{
            color: cssColorVariable
              ? `var(--${cssColorVariable})`
              : "var(--primaryColor)",
          }}
        />
      </div>
    </Stack>
  );
};
