// wordpressSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the base URL for the WordPress REST API
const wordpressApiUrl = "https://deepread.com/wp-json/wp/v2";

// Async thunk for fetching posts by category slug -> each post is categorized for example as 'News' in our example
export const fetchPostsByCategory = createAsyncThunk(
  "wordpress/fetchPostsByCategory",
  async (categorySlug, thunkAPI) => {
    try {
      // First, get the category ID by slug
      const categoryResponse = await axios.get(`${wordpressApiUrl}/categories?slug=${categorySlug}`);
      if (categoryResponse.data.length === 0) {
        throw new Error(`Category with slug "${categorySlug}" not found`);
      }
      const categoryId = categoryResponse.data[0].id;

      // Now fetch the posts for the given category ID
      const postsResponse = await axios.get(`${wordpressApiUrl}/posts?categories=${categoryId}`);
      return postsResponse.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const initialState = {
  posts: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

const wordpressSlice = createSlice({
  name: "wordpress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostsByCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPostsByCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload;
      })
      .addCase(fetchPostsByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const wordpressReducer = wordpressSlice.reducer;
export default wordpressReducer;