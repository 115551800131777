import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../Assets/avatar.jpg";

import { ApiStatus } from "../../Utils/Features/ApiStatus";
import { fetchProfile } from "../../Utils/Features/profileSlice";

import S3Storage from "../S3storage/s3storage";
const s3 = new S3Storage();

export const ProfileImage = ({ isIcon }) => {
  const dispatch = useDispatch();
  const profileImageRef = useRef(null);

  const { profileData, profileStatus } = useSelector(
    (state) => state?.profileSliceReducer
  );

  useEffect(() => {
    if (profileStatus !== ApiStatus.Fulfilled) dispatch(fetchProfile());

    // we want this effect to run only on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <img
      src={
        profileData?.profileImageUrl
          ? s3.getReloadImageLink(profileData?.profileImageUrl)
          : avatar
      }
      alt="profile"
      className={isIcon ? "profileImgIcon" : "profileImg"}
      ref={profileImageRef}
      onError={() => {
        if (profileImageRef.current) profileImageRef.current.src = avatar;
      }}
    />
  );
};
