const EnvTypes = {
  Prod: "prod",
  Staging: "staging",
  Dev: "dev",
  Demo: "demo",
};

const Postfixes = {
  Prod: "_PROD",
  Staging: "_STAGING",
  Dev: "_TEST",
};

export const getCurrentEnvironment = () => {
  checkEnv();
  return getEnv();
};

const getEnv = () => {
  return process.env.REACT_APP_NODE_ENV?.toLowerCase();
};

const checkEnv = () => {
  const env = getEnv();
  if (!env) {
    throw Error("REACT_APP_NODE_ENV variable is not set!");
  }
  if (!Object.values(EnvTypes).includes(env)) {
    throw Error(`Invalid value for REACT_APP_NODE_ENV: ${env}`);
  }
};

export const isDevelopment = () => {
  return getCurrentEnvironment() === EnvTypes.Dev ? true : false;
};

export const isStaging = () => {
  return getCurrentEnvironment() === EnvTypes.Staging ? true : false;
};

export const isProduction = () => {
  return getCurrentEnvironment() === EnvTypes.Prod ? true : false;
};

export const getApiUrl = () => {
  const apiUrlVarName = "REACT_APP_API_URL";
  const env = getCurrentEnvironment();
  if (env === EnvTypes.Dev) {
    return getStringEnvVariable(apiUrlVarName + Postfixes.Dev);
  }
  if (env === EnvTypes.Staging || env === EnvTypes.Demo) {
    return getStringEnvVariable(apiUrlVarName + Postfixes.Staging);
  }
  if (env === EnvTypes.Prod) {
    return getStringEnvVariable(apiUrlVarName + Postfixes.Prod);
  }
};

const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

const getVariableNameWithPostfix = (variableName) => {
  checkEnv();
  if (getCurrentEnvironment() === EnvTypes.Prod) {
    return variableName + Postfixes.Prod;
  }
  return variableName + Postfixes.Dev;
};

export const getNumericEnvVariable = (variableName, defaultValue) => {
  const value = process.env[variableName];
  if (isNumber(value)) {
    return value;
  }
  if (isNumber(defaultValue)) {
    return defaultValue;
  }
  const errorString = `Environenment variable ${variableName} or default value needs to be numeric`;
  console.error(errorString);
  throw Error(errorString);
};

export const getStringEnvVariable = (variableName, defaultValue) => {
  const value = process.env[variableName];
  if (value?.trim().length > 0) {
    return value;
  }
  if (defaultValue?.trim().length > 0) {
    return defaultValue;
  }
  const errorString = `Environenment variable ${variableName} or default value needs to be non-empty string`;
  console.error(errorString);
  throw Error(errorString);
};

export const getEnvironmentDependentStringVariable = (
  variableName,
  defaultValue
) => {
  return getStringEnvVariable(
    getVariableNameWithPostfix(variableName),
    defaultValue
  );
};
