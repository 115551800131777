import { styled } from "@mui/material/styles";

import UpgradeIcon from "@mui/icons-material/Upgrade";
import HeightIcon from "@mui/icons-material/Height";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Delete from "@mui/icons-material/Delete";

export const ParentLinkIcon = styled(UpgradeIcon)(() => ({
  backgroundColor: "grey",
  borderRadius: "33px",
  color: "white",
  width: 19,
  height: 19,
}));

export const ChildLinkIcon = styled(UpgradeIcon)(() => ({
  backgroundColor: "grey",
  borderRadius: "33px",
  color: "white",
  width: 19,
  height: 19,
  transform: "rotateZ(180deg)",
}));

export const NeutralLinkIcon = styled(HeightIcon)(() => ({
  backgroundColor: "grey",
  borderRadius: "33px",
  color: "white",
  width: 19,
  height: 19,
  transform: "rotateZ(90deg)",
}));

export const IdeaCardIcon = styled(VpnKeySharpIcon)(
  ({ fontSize = "small" }) => ({
    backgroundColor: "#FF6600",
    borderRadius: "33px",
    color: "white",
    width: 19,
    height: 19,
    padding: "3px",
  })
);

export const RemoveIcon = styled(RemoveCircle)(({ fontSize = "medium" }) => ({
  color: "red",
  width: 19,
  height: 19,
}));

export const RemoveIconB = styled(RemoveCircle)(({ fontSize = "medium" }) => ({
  color: "red",
  width: 22,
  height: 22,
}));

export const DeleteIcon = styled(Delete)(({ color }) => ({
  color: color ?? "var(--fontColor)",
  width: 24,
  height: 24,
}));
