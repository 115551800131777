import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import SyncIcon from "@mui/icons-material/Sync";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import DrawerModal from "../DrawerModal/DrawerModal";
import { ReactComponent as FullLogo } from "../../Assets/logoFullSvg.svg";
import { ReactComponent as DarkModeLogo } from "../../Assets/DeepReadDarkMode.svg";
import { iconProvider } from "../../helperFunctions/iconProvider";
import { urlChecker } from "../../helperFunctions/urlChecker";

import ChromeExtensionConnector from "../Connectors/ChromeExtensionCommunication";
import { installExtensionToast } from "../Connectors/ExtensionInstallationPrompt.js";

import { updatePersistentDrawer } from "../../Utils/Features/persistentDrawerSlice";

import { handleLogout } from "../../helperFunctions/global";
import {
  Pages,
  setCurrentPageTabData,
  setShowFilter,
  pageHasFilter,
} from "../../Utils/Features/currentPageTab";
import { CircularProgress } from "@mui/material";
import LinkButton from "../Common/Buttons/PrimaryButton.jsx";

import {
  hasNewNews,
  hasNewSuggestions,
} from "../../Utils/Features/userConfigSlice";
import { store } from "../../Utils/Store/Store";

export const menuItems = [
  {
    path: "/feed",
    page: Pages.Feed,
    linkText: "feed",
    name: "Feed",
    icon: "DynamicFeedOutlined",
    hasActivity: () => {
      return hasNewSuggestions(store.getState());
    },
  },
  {
    path: "/library",
    page: Pages.Library,
    linkText: "library",
    name: "Library",
    icon: "LibraryBooksOutlined",
  },
  {
    path: "/views",
    page: "Views",
    linkText: "views",
    name: "Book",
    icon: "BookIcon",
  },
  {
    path: null,
    page: null,
    name: "Filter",
    icon: "TuneIcon",
  },
  {
    path: "/notices",
    page: "News",
    linkText: "news",
    name: "Notices",
    icon: "Notifications",
    onlySidebar: true,
    hasActivity: () => {
      return hasNewNews(store.getState());
    },
  },
  {
    path: "/profile",
    page: "Profile",
    linkText: "profile",
    name: "Profile",
    icon: "profile_icon",
    onlySidebar: true,
  },
];

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  const { currentPageTab, showFilter } = useSelector(
    (state) => state.currentPageTab
  );

  const extensionInstalled =
    localStorage.getItem("extensionInstalled") === "true";

  const { amazonSyncDisabled, bookSyncMessage, syncType } = useSelector(
    (state) => state.amazonSync
  );

  const showSyncProgress = () => {
    return amazonSyncDisabled && syncType?.bookId === null;
  };

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (showFilter === true) {
      setIsOpen(false);
    }
  }, [showFilter]);

  const handleNavigation = (item, i) => {
    if (item.path) {
      navigate(item.path);
      dispatch(updatePersistentDrawer(null));
    }
    if (item.name === "Filter") {
      dispatch(setShowFilter(!showFilter));
    } else {
      dispatch(setCurrentPageTabData(item.page));
    }
  };

  const handleAmazonSync = async () => {
    // notify the user, that this is only doable using the extension :D
    if (!extensionInstalled) {
      installExtensionToast();
      return;
    }

    try {
      ChromeExtensionConnector.SyncAmazonBooks(userId);
    } catch (error) {
      console.log("Error handleAmazonSync: ", error);
    }
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <>
      <div
        className={`main-container`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <motion.div
          animate={{
            width: isOpen ? "208px" : "59px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className="sidebar"
          style={{ padding: isOpen ? "0 10px 0 5px" : "0" }}
        >
          <div>
            <a
              className="sidebarUppper"
              href="https://deepread.com/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="sidebarLogo">
                <AnimatePresence>
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className={!isOpen ? "logoColapse" : null}
                  >
                    {lightDarkMode === "dark" ? <DarkModeLogo /> : <FullLogo />}
                  </motion.div>
                </AnimatePresence>
              </div>
            </a>
            <div
              className={
                isOpen
                  ? "routes mainmenu"
                  : "routesCollapsible mainmenu collapse-1 "
              }
            >
              {menuItems?.map((item, index) => (
                <LinkButton
                  key={index}
                  isOpen={isOpen}
                  item={{
                    ...item,
                    active: urlChecker(item.linkText, true),
                    disabled:
                      item.name === "Filter" && pageHasFilter(currentPageTab),
                    onClick: () => handleNavigation(item, index),
                  }}
                />
              ))}
            </div>
          </div>

          <div className={isOpen ? "sidebarLower" : "sidebarLowerCollapse"}>
            <div
              className={`${isOpen ? "link" : "linkCollapsible"}`}
              onClick={toggle}
            >
              <div>{iconProvider("MenuRounded")}</div>
              <AnimatePresence>
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className={!isOpen ? "link_text_collapse" : null}
                >
                  Toggle
                </motion.div>
              </AnimatePresence>
            </div>
            <div>
              <div
                className={`${isOpen ? "link" : "linkCollapsible"}`}
                onClick={handleLogout}
              >
                <LogoutOutlinedIcon />{" "}
                <AnimatePresence>
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className={!isOpen ? "link_text_collapse" : null}
                  >
                    Logout
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
            {/* Sync with Amazon button */}
            <div
              id="loginWithAmazon"
              className="sidebarBottom"
              onClick={handleAmazonSync}
            >
              <button
                className={`${isOpen ? "link" : "linkCollapsible"}`}
                disabled={amazonSyncDisabled}
                title={bookSyncMessage}
                //style={{color: amazonSyncDisabled ? "#f44336" : "#000000"}}
                style={{
                  color: "var(--white)", // White text color
                  backgroundColor: "#FF6601", // DeepRead Orange: FF6601, Amazon yellow: #FF9900
                  border: "none", // Remove border
                  padding: `${
                    isOpen ? (showSyncProgress() ? "5px 5px" : "8px 25px") : ""
                  }`, // Adjust padding as needed
                  borderRadius: `${isOpen ? "50px" : "50%"} `, // Rounded corners
                  cursor: amazonSyncDisabled ? "not-allowed" : "pointer", // Show not-allowed cursor when disabled
                  transition: "background-color 0.3s", // Add transition effect for color change
                  width: `${isOpen ? "" : "40px"}`,
                  height: `${isOpen ? "" : "40px"}`,
                  display: `${isOpen ? "" : "flex"}`,
                  alignItems: `${isOpen ? "" : "center"}`,
                  justifyContent: `${isOpen ? "" : "center"}`,
                  gap: `${showSyncProgress() ? "5px" : ""}`,
                }}
                // Apply hover effect
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#FF9900"; // Amazon Yellow: #FF9900
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#FF6601"; // Back to DeepRead Orange
                }}
              >
                {showSyncProgress() ? (
                  <>
                    <span
                      style={{
                        paddingTop: "5px",
                        paddingLeft: isOpen ? "5px" : 0,
                      }}
                    >
                      <CircularProgress
                        size="1.1rem"
                        sx={{ color: "var(--white)" }}
                      />
                    </span>
                  </>
                ) : (
                  !isOpen && (
                    <SyncIcon
                      sx={{
                        fontSize: "1.5rem",
                        "&:focus": {
                          background: "transparent",
                          outline: "none",
                        },
                      }}
                    />
                  )
                )}
                {isOpen && <>Sync with Amazon</>}
              </button>
            </div>
          </div>
        </motion.div>
        {currentPageTab && showFilter && (
          <div className="sidebarLayer2">
            <DrawerModal />
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
