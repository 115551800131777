import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";

import "../../pages/MyLibrary/MyLibrary.css";
import ChromeExtensionConnector from "../Connectors/ChromeExtensionCommunication";
import { SyncOrigin } from "../Connectors/ChromeExtensionCommunication";

import {
  formatDateMonthYear,
  formatDateString,
} from "../../helperFunctions/timing";
import { customLog } from "../../helperFunctions/customLogger";

import SyncIcon from "@mui/icons-material/Sync";
import { Loading } from "../Loading";
import { getNumericEnvVariable } from "../../helperFunctions/envVars";
import { installExtensionToast } from "../Connectors/ExtensionInstallationPrompt";

export const AmazonSingleSync = ({ bookData, originView }) => {
  const [recentlySynced, setRecentlySynced] = useState(false);

  const { amazonSyncDisabled, singleBookSyncMessage, syncType } = useSelector(
    (state) => state.amazonSync
  );

  useEffect(() => {
    const today = new Date();
    const past = new Date(bookData?.updated_at);
    if (
      past.getDate() <=
      today.getDate() -
        getNumericEnvVariable("REACT_APP_SYNC_BOOK_ALLOW_TIME_BETWEEN", 7)
    ) {
      setRecentlySynced(false);
    } else {
      setRecentlySynced(true);
    }
  }, [bookData?.updated_at]);
  const bookSyncInfoMessage = `Gets updateted book information for your book\n${bookData?.title}.`;
  const highlightSyncInfoMessage = `Gets full highlights for your book\n${bookData?.title}.`;

  const getSyncInfoMessage = () => {
    if (originView === SyncOrigin.Book) return bookSyncInfoMessage;
    if (originView === SyncOrigin.Highlight) return highlightSyncInfoMessage;
    return "";
  };

  const fillUpdatedTimeForSyncMessage = (message) => {
    if (message && bookData?.updated_at)
      return message.replace(
        "#TIME#",
        formatDateString(new Date(bookData?.updated_at))
      );
    return "";
  };

  const showBookSyncProgress = () => {
    return amazonSyncDisabled && syncType?.bookId === bookData?._id;
  };

  const extensionInstalled =
  localStorage.getItem("extensionInstalled") === "true";

  const handleAmazonSingleSync = async (bookId) => {
    customLog(`AmazonSingleSync extensionInstalled: ${extensionInstalled}`);

    if (!extensionInstalled) {
      installExtensionToast();
      return;
    }

    try {
        ChromeExtensionConnector.SyncAmazonSingleBook(bookId, originView);
    } catch (error) {
      console.log("Error handleAmazonSingleBookSync: ", error);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="left" alignItems="center">
        <button
          disabled={amazonSyncDisabled}
          className={`  flex gap-[5px] px-[8px] h-[30px] rounded-full items-center text-[14px] ${
            recentlySynced
              ? " bg-[color:var(--selectedBackgroundColor)] text-[color:var(--fontColor)]"
              : "bg-[#FF6601] text-[color:var(--white)]"
          } ${amazonSyncDisabled ? "cursor-not-allowed" : "pointer"} `}
          title={
            amazonSyncDisabled
              ? `${fillUpdatedTimeForSyncMessage(singleBookSyncMessage)}`
              : `${getSyncInfoMessage()} \nLast update: ${formatDateMonthYear(
                  bookData?.updated_at
                )}`
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAmazonSingleSync(bookData?._id);
          }}
        >
          {showBookSyncProgress() ? (
            <span className="pt-1">
              <Loading
                small="true"
                cssColorVariable={recentlySynced ? "fontColor" : "white"}
              />
            </span>
          ) : (
            <span>
              <SyncIcon />
            </span>
          )}
          <span className="">Get Highlights</span>
        </button>
      </Stack>
    </>
  );
};
