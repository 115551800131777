import { configureStore } from "@reduxjs/toolkit";
import profileSliceReducer from "../Features/profileSlice";

import levelCounterReducer from "../Features/levelCounterSlice";
import persistentDrawerReducer from "../Features/persistentDrawerSlice";
import IdentifyIdeaCardReducer from "../Features/IdentifyIdeaCardSlice";
import libraryReducer from "../Features/librarySlice";
import authReducer from "../Features/authSlice";
import amazonSyncSlice from "../Features/amazonSyncSlice";
import { feedSliceReducer } from "../Features/feedSlice";
import { userConfigSliceReducer } from "../Features/userConfigSlice";
import lightDarkModeSlice from "../Features/lightDarkModeSlice";
import currentPageTabReducer from "../Features/currentPageTab";
import contentReducer from "../Features/contentSlice";
import paymentReducer from "../Payment/paymentSlice";
import wordpressReducer from "../Features/wordpressSlice";
import suggestionSliceReducer from "../Features/suggestionSlice";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { isDevelopment } from "../../helperFunctions/envVars";

export const libraryPersistConfig = {
  key: "lib",
  storage,
};
export const authPersistConfig = {
  key: "auth",
  storage,
};
export const amazonSyncPersistConfig = {
  key: "amazon",
  storage,
};
export const userConfigPersistConfig = {
  key: "userConf",
  storage,
};

export const lightDarkModePersistConfig = {
  key: "lightDarkMode",
  storage,
};

export const persistedCurrentPageTabConfig = {
  key: "bookCurrentTab",
  storage,
};

export const profilePersistConfig = {
  key: "profile",
  storage,
};

export const paymentPersistConfig = {
  key: "payment",
  storage,
};

const persistedLibraryReducer = persistReducer(
  libraryPersistConfig,
  libraryReducer
);
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedAmazonReducer = persistReducer(
  amazonSyncPersistConfig,
  amazonSyncSlice
);
const persistedUserConfigSliceReducer = persistReducer(
  userConfigPersistConfig,
  userConfigSliceReducer
);

const persistedLightDarkModeReducer = persistReducer(
  lightDarkModePersistConfig,
  lightDarkModeSlice
);

const persistedCurrentPageTabReducer = persistReducer(
  persistedCurrentPageTabConfig,
  currentPageTabReducer
);

const devToolsEnabled = isDevelopment();

const persistedPaymentReducer = persistReducer(
  paymentPersistConfig,
  paymentReducer
);

export const store = configureStore({
  reducer: {
    levelCounterReducer,
    persistentDrawerReducer,
    IdentifyIdeaCardReducer,
    library: persistedLibraryReducer,
    auth: persistedAuthReducer,
    amazonSync: persistedAmazonReducer,
    feed: feedSliceReducer,
    userConfig: persistedUserConfigSliceReducer,
    profileSliceReducer: profileSliceReducer,
    lightDarkModeSlice: persistedLightDarkModeReducer,
    currentPageTab: persistedCurrentPageTabReducer,
    content: contentReducer,
    payment: persistedPaymentReducer,
    wordpress: wordpressReducer,
    suggestion: suggestionSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: devToolsEnabled,
});

export const persistor = persistStore(store);
