// src/Utils/Payment/paymentSlice.js
import { loadStripe } from "@stripe/stripe-js";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customLog } from "../../helperFunctions/customLogger";
import toastMessage from "../../helperFunctions/toastMessage";
import {
  getApiUrl,
  getEnvironmentDependentStringVariable,
} from "../../helperFunctions/envVars";
import { store } from "../Store/Store";
import { resetLibrary } from "../Features/librarySlice";
import { getHeaders } from "../Features/authSlice";
import { pushToDataLayer } from "../../helperFunctions/gtm";

const backendEndpoint = getApiUrl();
const stripeApiKey = getEnvironmentDependentStringVariable(
  "REACT_APP_STRIPE_API_KEY"
);

const toastLength = 3000;

customLog("Payment: " + process.env.REACT_APP_NODE_ENV);
customLog("Payment: Stripe API key - " + stripeApiKey);

const stripePromise = loadStripe(stripeApiKey);

export const createCheckoutSession = createAsyncThunk(
  "payment/createCheckoutSession",
  async ({ subscriptionType }, thunkAPI) => {
    try {
      customLog(
        "Creating checkout session with subscriptionType " + subscriptionType
      );

      // Fetch the user data to check the current subscription type, get it explicitely in case of non updated slice
      const userprofile = await axios.get(
        `${backendEndpoint}/api/user/profile`,

        getHeaders()
      );

      const profile = userprofile.data.data;

      const currentSubscriptionType = profile.subscriptionType;

      // If the user is on a lifetime plan, prevent any further changes
      if (currentSubscriptionType === "lifetime") {
        toastMessage(
          "info",
          false,
          "You're a valued member of our Lifetime plan! Enjoy all the perks forever—no need to change a thing. We're grateful to have you with us!"
        );
        return thunkAPI.rejectWithValue(
          "User is already on the Lifetime plan and cannot switch to another plan."
        );
      }

      // Check if the user is already on the chosen subscription
      if (currentSubscriptionType === subscriptionType) {
        toastMessage(
          "info",
          toastLength,
          `You're already on the ${subscriptionType} plan.`
        );
        return thunkAPI.rejectWithValue(
          `User is already on the ${subscriptionType} plan.`
        );
      }

      // Check if the user is already on a paid subscription and wants to go free
      if (subscriptionType === "free" && profile.paymentStatus === "paid") {
        toastMessage(
          "info",
          toastLength,
          `You're on a paid plan, if you want to go free, cancel your plan in the billing portal.`
        );
        return thunkAPI.rejectWithValue(
          `User is already on the ${subscriptionType} plan.`
        );
      }

      // Check if the user is switching subscriptions
      if (
        currentSubscriptionType &&
        currentSubscriptionType !== subscriptionType
      ) {
        // TODO if monthly -> yearly remaining billed, yearly -> monthly remaining credited, check portal further info
        const userConfirmed = window.confirm(
          `You are currently on the ${currentSubscriptionType} plan. Are you sure you want to switch to the ${subscriptionType} plan?`
        );
        if (!userConfirmed) {
          toastMessage("info", toastLength, "Subscription change canceled");
          return thunkAPI.rejectWithValue(
            "User canceled the subscription change."
          );
        }
      }

      // Handle free subscription directly
      if (subscriptionType.toLowerCase() === "free") {
        customLog("Activating free subscription for user");
        return handleFreeSubscription(subscriptionType);
      } else {
        // Create the normal session for payment, anything that is not of type free
        return handlePaidSubscription(subscriptionType);
      }
    } catch (error) {
      return handleCheckoutError(error, thunkAPI);
    }
  }
);

const handleFreeSubscription = async (subscriptionType) => {
  const response = await axios.post(
    `${backendEndpoint}/api/payment/checkout`,
    { subscriptionType },
    getHeaders()
  );

  if (response.status !== 200) {
    throw new Error("Network response was not ok: " + response.statusText);
  }

  // refetch books so that changed privacy status is shown in library
  store.dispatch(resetLibrary());

  pushToDataLayer('plan_selection_success', { plan: subscriptionType });

  customLog("Free subscription activated: ", response.data);
  toastMessage("success", 2000, "Free subscription activated");

  return response.data;
};

const handlePaidSubscription = async (subscriptionType) => {
  try {
    const response = await axios.post(
      `${backendEndpoint}/api/payment/checkout`,
      { subscriptionType },
      getHeaders()
    );

    if (response.status !== 200) {
      throw new Error("Network response was not ok: " + response.statusText);
    }

    const session = response.data;
    customLog("Stripe session: ", session);

    const stripe = await stripePromise;

    customLog("Stripe before checkout info: " + stripe);

    // Display toast message with the session data message
    if (session.data.message) {
      toastMessage("success", toastLength, session.data.message);
    }

    if (session.data.id) {
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.data.id,
      });

      // explicite error with redirection of the checkout by stripe, proise resolves to an object, hence the catch would not see this error
      if (error) {
        customLog("Stripe redirect to checkout error: ", error);
        toastMessage(
          "error",
          2000,
          "Stripe redirect to checkout error:\n" + error
        );
      }
    }

    pushToDataLayer('plan_selection_success', { plan: subscriptionType });

    return session;
  } catch (error) {
    customLog("Error in handlePaidSubscription:", error);
    throw error;
  }
};

const handleCheckoutError = (error, thunkAPI) => {
  customLog("Error creating checkout session:", error);
  let errorMessage = "An error occurred during checkout.";

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    errorMessage =
      error.response.data.message || error.response.data || error.message;
    customLog("Error response data:", error.response.data);
    customLog("Error response status:", error.response.status);
    customLog("Error response headers:", error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response received from the server.";
    customLog("Error request:", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = error.message;
  }

  pushToDataLayer('plan_selection_failure', { error: error.message });
  toastMessage("error", 5000, errorMessage);
  return thunkAPI.rejectWithValue(errorMessage);
};

export const createPortalSession = createAsyncThunk(
  "payment/createPortalSession",
  async (thunkAPI) => {
    try {
      customLog("Creating portal session for user");

      const response = await axios.post(
        `${backendEndpoint}/api/payment/create-portal-session`,
        {},
        getHeaders()
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok: " + response.statusText);
      }

      const portalSession = response.data;
      customLog("Stripe portal session: ", portalSession);

      return portalSession.data.url;
    } catch (error) {
      // TODO check the error has status 404, then we just don't have a user yet -> so no billing, we can display this message instead of a generic error handler
      customLog("Error creating portal session: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Create a slice for handling checkout session state if necessary
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    session: null,
    portalSession: null,
    loading: false,
    loadingPortal: false,
    error: null,
    lastCheckoutSubscriptionType: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.lastCheckoutSubscriptionType = action.meta.arg.subscriptionType;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.stripe_session) {
          state.session = action.payload;
        }
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPortalSession.pending, (state) => {
        state.loadingPortal = true;
        state.error = null;
        customLog("createPortalSession pending");
      })
      .addCase(createPortalSession.fulfilled, (state, action) => {
        state.loadingPortal = false;
        state.portalSession = action.payload;
        customLog("createPortalSession fulfilled", action.payload);
      })
      .addCase(createPortalSession.rejected, (state, action) => {
        state.loadingPortal = false;
        state.error = action.payload;
        customLog("createPortalSession rejected", action.payload);
      });
  },
});

export default paymentSlice.reducer;
