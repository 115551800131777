import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ImageList, ImageListItem } from "@mui/material";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

import "./PexelImageSelect.css";
import Search from "../Common/Search";
import { useDebounce } from "../../helperFunctions/UseDebounce";
import { useIsMounted } from "../../helperFunctions/useIsMounted";

import {
  updateIdeaCard,
  getIdeaCardById,
  getCurrentIdeaCardId,
} from "../../Utils/Features/librarySlice";
import S3Storage from "../S3storage/s3storage";
import toastMessage from "../../helperFunctions/toastMessage";
import {
  getEnvironmentDependentStringVariable,
  getNumericEnvVariable,
} from "../../helperFunctions/envVars";

const s3 = new S3Storage();
const per_page_data = 6;

export const PexelImageSelect = ({
  onImageSelect,
  titleForSuggestion,
  setLoading,
  searchQuery,
  setSearchQuery,
  setShow,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [isPaste, setIsPaste] = useState(false);
  const [isUPload, setIsUPload] = useState(false);
  const fileInputRef = useRef();

  const isMounted = useIsMounted();

  // function to handle when user drag a image into present window.
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // function to handle when user drag a image into present window and drops the image.
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDragActive(false);
    }
  };

  const imagesPerPage = 9;

  const handleSearchChange = (event) => {
    const query = event?.target?.value?.toLowerCase() ?? "";
    setSearchQuery(query);
  };

  const selectedIdeaCardId = useSelector((state) =>
    getCurrentIdeaCardId(state)
  );

  const ideaCard = useSelector((state) =>
    getIdeaCardById(state, selectedIdeaCardId)
  );

  const searchWithDebounce = useDebounce(searchQuery, 500);

  const clearSearch = () => {
    setSearchQuery("");
    setResult([]);
  };
  const handleSelect = (imageLink) => {
    clearSearch();
    selectImage(imageLink);
  };

  const fetchPage = async (page) => {
    if (page && isMounted() && searchQuery?.length > 0) {
      try {
        const response = await fetch(
          `https://api.pexels.com/v1/search?query=${searchQuery}&per_page=${per_page_data}&page=${page}`,
          {
            headers: {
              Authorization: getEnvironmentDependentStringVariable(
                "REACT_APP_PEXELS_API_KEY_FOR_IMAGE_SEARCH"
              ),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (isMounted()) {
          setPage(data?.page);
          setTotalRecordCount(data?.total_results);
          if (data?.photos?.length > 0) {
            setResult(
              data?.photos?.map((photo) => {
                return photo?.src?.medium;
              })
            );
          }
        }
      } catch (error) {
        if (isMounted()) {
          console.error("Error fetching images:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (searchWithDebounce?.length > 0) {
      fetchPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWithDebounce]);

  useEffect(() => {
    if (titleForSuggestion) {
      setSearchQuery(titleForSuggestion);
    }
  }, [setSearchQuery, titleForSuggestion]);

  const selectImage = (link) => {
    onImageSelect(link);
    setShow(false);
    setResult([]);
  };

  const selectPage = (pageIndex) => {
    fetchPage(pageIndex);
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setResult([]);
    }
  }, [searchQuery]);

  const totalPages = Math.ceil(totalRecordCount / imagesPerPage);

  const renderPagination = () => {
    const maxPagesToShow = 10;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = page - halfMaxPagesToShow;
    let endPage = page + halfMaxPagesToShow;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxPagesToShow);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const paginationItems = [];

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <span
          key={i}
          style={{
            color: i === page ? "blue" : "black",
            cursor: "pointer",
          }}
          onClick={() => selectPage(i)}
        >
          &nbsp; {i}
        </span>
      );
    }

    return paginationItems;
  };

  function handleUpload(event, type) {
    let selectedFile;
    if (type === "paste") {
      selectedFile = event;
    } else {
      selectedFile = event.target.files[0];
    }

    const maxSize = getNumericEnvVariable(
      "REACT_APP_IDEA_IMAGE_MAX_SIZE",
      10485760
    );

    if (selectedFile) {
      // Check file size
      if (selectedFile.size > maxSize) {
        toastMessage(
          "error",
          5000,
          "File size exceeds 10 MB. Choose a smaller file."
        );
        setIsPaste(false);
        setIsUPload(false);
        setDragActive(false);
        return;
      }

      // Check file type
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(selectedFile.type)) {
        toastMessage(
          "error",
          5000,
          "Invalid file type. Please select a PNG, JPG, or JPEG."
        );

        return;
      }
      setDragActive(false);
      setLoading(true);
      setSearchQuery("");

      // Generate a unique key for the S3 object (e.g., using a timestamp, idea._id)
      const key = `${ideaCard._id}_${selectedFile.name}`;

      // Upload the file to S3
      s3.putObject(key, selectedFile)

        .then((data) => {
          const oldPictureLink = ideaCard.picture_link;
          dispatch(
            updateIdeaCard({
              ideaCardId: ideaCard._id,
              newData: {
                picture_link: key,
              },
            })
          );
          setLoading(false);
          setResult([]);
          setIsPaste(false);
          setIsUPload(false);
          setDragActive(false);
          setSearchQuery("");
          setShow(false);
          // need to clear the file input file name otherwise
          // upload picture file - paste picture - upload the just uploaded file again
          // -scenario will not work because the input element remembers the name of the file that it last uploaded
          fileInputRef.current.value = "";
          if (s3.isS3Link(oldPictureLink)) {
            if (
              oldPictureLink &&
              oldPictureLink.length > 0 &&
              oldPictureLink !== key
            ) {
              s3.deleteObject(oldPictureLink);
            }
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setLoading(false); // Set loading state to false if there's an error.
        });
    }
  }

  useEffect(() => {
    const handlePasteAnywhere = async (event) => {
      try {
        const clipboardItems = event.clipboardData.items;
        for (const item of clipboardItems) {
          if (item.type.indexOf("image") !== -1) {
            const blob = await item.getAsFile();
            handleUpload(blob, "paste");
            return;
          } else if (item.type === "text/plain") {
            const text = await new Promise((resolve) =>
              item.getAsString(resolve)
            );
            if (text.startsWith("data:image/")) {
              handleSelect(text);
            }
            if (
              !text ||
              (!text.startsWith("http://") &&
                !text.startsWith("https://") &&
                !text.startsWith("data:image/"))
            ) {
              toastMessage(
                "error",
                5000,
                "Invalid image URL, Please select a valid URL."
              );
              return;
            }
            if (isImageURL(text)) {
              handleSelect(text);
              return;
            } else {
              fetch(text)
                .then((response) => {
                  if (response.ok) {
                    const contentType = response.headers.get("Content-Type");
                    if (contentType && contentType.startsWith("image")) {
                      handleSelect(text);
                    } else {
                      toastMessage(
                        "error",
                        5000,
                        "Invalid image URL, Please select a valid URL."
                      );
                    }
                  } else {
                    toastMessage(
                      "error",
                      5000,
                      "Invalid image URL, Please select a valid URL."
                    );
                  }
                })
                .catch((error) => {
                  toastMessage(
                    "error",
                    5000,
                    "Invalid file type. Please select a PNG, JPG, or JPEG."
                  );
                });
            }
          } else {
            toastMessage(
              "error",
              5000,
              "Invalid file type. Please select a PNG, JPG, or JPEG."
            );
          }
        }
      } catch (error) {
        toastMessage(
          "error",
          5000,
          "Invalid file type. Please select a PNG, JPG, or JPEG."
        );
      }
    };

    const isImageURL = (url) => {
      return /\.(jpg|jpeg|png)(\?.*)?$/i.test(url);
    };

    window.addEventListener("paste", handlePasteAnywhere);

    return () => {
      window.removeEventListener("paste", handlePasteAnywhere);
    };
    // no need to pass handleUpload
    // (it was explained that this doesn't change functionality in testing and causes in some situations infilite loop)
    // ideaCard depency on the other had is needed otherwise will cause pasted files to be uploaded on
    // wrong ideacard when ideacard has been just switched and this useEffect has not been "refreshed"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaCard]);

  return (
    <div className="searchContainer relative" id="searchDiv">
      <div className="searchOption">
        <div className="searchBar  mb-[2px] py-[5px]">
          <div className="relative w-full">
            <Search
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder={"Add an image for better recognition"}
              handleSearchChange={handleSearchChange}
            />
          </div>
          <div
            className={`uploadContainer relative border-1 rounded-[12px] border border-[#E5E4E4] mt-2 p-2
            ${dragActive ? `border-[#FF6600] uploadContainer` : ``} 
            ${isPaste ? `border-[#FF6600] uploadContainer` : ``}
            ${isUPload ? `border-[#FF6600] uploadContainer` : ``}`}
            onClick={() => setIsPaste(true)}
          >
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              className="cursor-grab"
              onDragLeave={() => {
                setDragActive(false);
              }}
            >
              <label
                className={
                  dragActive
                    ? "custom-upload-file-active custom-file-upload"
                    : "custom-file-upload "
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setIsUPload(true);
                }}
                // htmlFor="dropzone-file"
              >
                <IconButton
                  className={`absolute left-0 flex w-[30px] h-[30px] bg-white ${
                    isPaste || isUPload ? "active" : ""
                  }`}
                >
                  <AddIcon className="bg-white" />
                </IconButton>

                {!ideaCard?.picture_link && (
                  <span
                    className="absolute top-0 right-[6px]"
                    title="Paste an image or image link by pressing Ctrl+V (or Cmd+V on Mac) from your clipboard."
                    style={{
                      fontSize: "12px",
                      color: "lightslategrey",
                    }}
                  >
                    <InfoIcon fontSize={"small"} className="mr-2" />
                  </span>
                )}

                <input
                  type="file"
                  onChange={handleUpload}
                  id="dropzone-file"
                  name="image"
                  accept=".jpg, .jpeg, .png"
                  className="absolute w-full h-full opacity-0 left-0 top-0"
                  ref={fileInputRef}
                />
              </label>
              <div className="text-center">
                {dragActive ? (
                  <p className="text-[#FF6600]">Drop image here to upload</p>
                ) : isPaste ? (
                  <p className="text-[#FF6600]">Paste image here to upload</p>
                ) : isUPload ? (
                  <p className="text-[#FF6600]">Upload image</p>
                ) : (
                  <p className="text-[color:var(--fontColor)]">
                    Upload, drag & drop, copy & paste
                  </p>
                )}
              </div>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
        </div>
      </div>

      {result?.length > 0 && (
        <ImageList
          sx={{
            marginTop: "12px",
            width: "100%",
            height: "100%",
            background: "#EBEBEB",
          }}
          cols={3}
          rowHeight={164}
        >
          {result.map((imageLink) => (
            <ImageListItem key={imageLink}>
              <img
                src={`${imageLink}`}
                onClick={() => {
                  handleSelect(imageLink);
                }}
                loading="lazy"
                alt="img"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      {result && result.length > 0 && (
        <div className="searchResult" style={{}}>
          {renderPagination()}
        </div>
      )}
    </div>
  );
};
