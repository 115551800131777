import { createSlice } from "@reduxjs/toolkit";

export const colorThemeEnum = {
  "LIGHT":"light",
  "DARK": "dark"
}

export const LightAndDarkModeSlice = createSlice({
  name: "LightAndDarkModeState",
  initialState: {
    value: null,
    currentMode:`${colorThemeEnum?.LIGHT}`,
  },
  reducers: {
    updateLightAndDarkModeState: (state, action) => {
      if (action.payload !== null) {
        state.value = action.payload;
      }
    },
    // used to set the current state 
    setcurrentMode: (state ,action) =>{
      if (action.payload !== null) {
        state.currentMode = action.payload;
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateLightAndDarkModeState, setcurrentMode } = LightAndDarkModeSlice.actions;

export default LightAndDarkModeSlice.reducer;