/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: sort the eslint warnings when the List and TilesView menus are refactored */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import highlightTester from "../helperFunctions/highlightTester";
import "./Pages_V2.css";
import { iconProvider } from "../helperFunctions/iconProvider";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Filter from "../components/Common/Filter";
import { updateTilesViewFilter } from "../Utils/Features/userConfigSlice";
import { ignoreOrderCompare } from "../helperFunctions/equalityChecker";
import CloseIcon from "@mui/icons-material/Close";
import { sortButtonsStyle } from "../components/Common/Filter";
import CommonInput from "../components/Common/CommonInput";
import { getAllIdeaIcons } from "../helperFunctions/getIdeacardIcons";

const routes = [
  {
    path: "/",
    name: "Highlights",
    icon: "highlights",
  },
  {
    path: "/users",
    name: "Idea cards",
    icon: "ideaCards",
    subRoutes: [
      {
        name: "KEYWORDS",
        icon: "vpn_key",
        isChecked: true,
      },
    ],
  },
];

let listLevels = [
  {
    label: "1st Level:Chapter",
    value: 1,
  },
];

const dropdownChipStyle = {
  width: "11.54rem",
  justifyContent: "flex-start",
  textTransform: "capitalize",
  // gap: "10px",dww
  paddingLeft: "5px",
  cursor: "pointer",
  fontSize: "var(--font-regular)",
  color: "var(--fontColor)",
  background: "var(--DropDownMenuBackgroundColor)",
  "& .MuiChip-icon": {
    color: "var(--fontColor)",
  },
};

const Structurerenderer = () => {
  let levelCount = useSelector((state) => state.levelCounterReducer.value);

  const tileViewData = useSelector((state) => state.library.listViewData);

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );
  const [listLevelState, setListLevelState] = useState(listLevels);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selectedItem, setSelectedItem] = useState(listLevels[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedItem({
      label: "1st Level:Chapter",
      value: 1,
    });
  }, [tileViewData]);

  const levelObjectConstructor = (level) => {
    switch (level) {
      case 1:
        return "1st Level:Chapter";
      case 2:
        return "2st Level:Sub-Chapter";
      case 3:
        return "3rd Level:Section";
      case 4:
        return "4th Level:Sub-Section";
      case 5:
        return "5th Level:Sub-Sub-Section";
      default:
        return "nth Level";
    }
  };

  //New DrowpdonLogics
  const handleSelectChange = (index) => {
    setSelectedItem(listLevelState[index]);
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    listLevels = [];
    for (let i = 1; i < levelCount; i++) {
      const obj = {
        label: levelObjectConstructor(i),
        value: i,
      };
      listLevels.push(obj);
      setListLevelState(listLevels);
    }
  }, [levelCount]);

  useEffect(() => {
    const filteredList = listLevels.filter(
      (item) => item.label !== selectedItem?.label
    );
    setListLevelState(filteredList);
    dispatch(updateTilesViewFilter({ chapterLevel: selectedItem }));
  }, [selectedItem]);

  return (
    <>
      <div className="pt-[15px]">
        <span className={`text-sm ${`text-primary-${lightDarkMode}`}`}>
          Tiles:
        </span>
      </div>
      <div className="viewMenuSpacing">
        {/* <TilesTilesSvg /> */}
        <div className="flex flex-col gap-[5px]">
          <div className="radioInputs ">
            <button
              id="tilesView-basic-button"
              aria-controls={open ? "tilesView-basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className={`activeState_Modified ${`background-filter-heading-${lightDarkMode}`} ${`text-primary-${lightDarkMode}`}`}
              // id={isOpen ? "active" : "activeCollapsible"}
              onClick={(e) => handleMenuClick(e)}
              style={{
                gap: "4px",
                width: "185px",
              }}
            >
              {iconProvider("Playlist_Add")}
              <div
                style={{ textTransform: "capitalize" }}
                className="link_text ellipsis_Style"
              >
                {selectedItem?.label}
              </div>
            </button>
          </div>
        </div>
      </div>
      <Menu
        elevation={0}
        id="tilesView-basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "tilesView-basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            "& .MuiList-root": {
              border: "none",
            },
            "& .MuiMenuItem-root": {
              paddingLeft: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        }}
      >
        {listLevelState.length > 0 ? (
          listLevelState.map((item, index) => {
            return (
              !item.activeNow && (
                <MenuItem
                  sx={{ paddingTop: "0" }}
                  key={item.label + index}
                  value={index}
                  onClick={() => handleSelectChange(index)}
                >
                  <Chip
                    sx={dropdownChipStyle}
                    // className="activeState_Modified"
                    icon={iconProvider("Playlist_Add")}
                    label={item.label}
                  />
                </MenuItem>
              )
            );
          })
        ) : (
          <Chip sx={dropdownChipStyle} label="Book has one level." />
        )}
      </Menu>
    </>
  );
};

const ContentListRenderer = () => {
  const dispatch = useDispatch();
  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );
  const { tilesViewFilter: filter } = useSelector((state) => state.userConfig);

  const [bookmarkState, setBookmarkState] = useState(false);
  const [ideaCardActiveState, setIdeaCardActiveState] = useState(true);
  const [counter, setCounter] = useState(0);
  const [selectedRoutes, setSelectedRoutes] = useState(false);
  const allIcons = getAllIdeaIcons();

  const stateCheckerLoopContent = () => {
    let selectCounter = 0;
    for (let i = 0; i < routes[1].subRoutes.length; i++) {
      if (routes[1].subRoutes[i].isChecked) {
        selectCounter++;
      }
    }

    if (selectCounter) {
      setIdeaCardActiveState(true);
    } else {
      setIdeaCardActiveState(false);
    }
  };

  const bookmarkClicked = () => {
    if (counter === 0) {
      stateCheckerLoopContent();
      setBookmarkState(true);
      setCounter(1);
    } else if (counter === 1) {
      setBookmarkState(false);
      setCounter(0);
      stateCheckerLoopContent();
    }
  };

  useEffect(() => {
    stateCheckerLoopContent();
    if (selectedRoutes?.length > 0) {
      const showIdeacards = {};
      selectedRoutes?.forEach((ideacardtype) => {
        showIdeacards[ideacardtype.title] = {
          isChecked: ideacardtype.isChecked,
          id: ideacardtype.id,
        };
      });
      if (!ignoreOrderCompare(filter.showIdeacards, showIdeacards)) {
        dispatch(
          updateTilesViewFilter({
            showIdeacards: showIdeacards,
          })
        );
      }
    }
  }, [selectedRoutes]);

  const showMenuClickHandler = (menuItem, id) => {
    if (menuItem === "Highlights") {
      const highlightButton = document.querySelector(`#${id}`);
      let showHighlights = !filter.showHighlights;
      dispatch(updateTilesViewFilter({ showHighlights: showHighlights }));

      if (showHighlights) {
        highlightButton.classList.remove(
          `link_Modified`,
          `bg-transparent`,
          `${`text-primary-${lightDarkMode}`}`
        );
        highlightButton.classList.add(
          `activeState_Modified`,
          `background-filter-heading-${lightDarkMode}`,
          `${`text-primary-${lightDarkMode}`}`
        );
      } else {
        highlightButton.classList.add(
          `link_Modified`,
          `bg-transparent`,
          `${`text-primary-${lightDarkMode}`}`
        );
        highlightButton.classList.remove(
          `activeState_Modified`,
          `background-filter-heading-${lightDarkMode}`,
          `${`text-primary-${lightDarkMode}`}`
        );
      }

      if (highlightTester()) {
        const liChilds = document.querySelectorAll(".highlightLi");
        const zeroItemInIdeaCard = document.querySelectorAll(".zeroIdeaCard");

        for (var i = 0; i < liChilds.length; ++i) {
          let item = liChilds[i].classList;
          if (item) {
            if (showHighlights) {
              liChilds[i].classList.remove("d-none");
            } else {
              liChilds[i].classList.add("d-none");
            }
          }
        }
        for (var j = 0; j < zeroItemInIdeaCard.length; ++j) {
          let item = zeroItemInIdeaCard[j].classList;
          if (item) {
            if (showHighlights) {
              zeroItemInIdeaCard[j].classList.remove("d-none");
            } else {
              zeroItemInIdeaCard[j].classList.add("d-none");
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    const stateFullAllIcons = allIcons?.map((item) => ({
      title: item.label.toLowerCase(),
      icon: item.label,
      isChecked: filter.showIdeacards
        ? filter.showIdeacards[item.label.toLowerCase()].isChecked
        : true,
      id: item._id,
    }));
    routes[1].subRoutes = stateFullAllIcons;

    if (highlightTester()) {
      const highlightButton = document.querySelector(`#Highlights-0`);
      highlightButton.classList.remove("link_Modified");
      highlightButton.classList.add("activeState_Modified");
    }
  }, []);
  return (
    <>
      <div className="pt-[15px]">
        <span className={`text-sm ${`text-primary-${lightDarkMode}`}`}>
          Content:
        </span>
      </div>
      <div className="viewMenuSpacing">
        <div className="flex flex-col gap-[5px]">
          {routes.map((route, index) => {
            if (route.subRoutes?.length) {
              return (
                <div key={route.title}>
                  {index === 0 && (
                    <button
                      key={index}
                      className={
                        ideaCardActiveState
                          ? `activeState_Modified ${`background-filter-heading-${lightDarkMode} ${`text-primary-${lightDarkMode}`}`}`
                          : `link_Modified`
                      }
                      // id={isOpen ? "active" : "activeCollapsible"}
                      onClick={bookmarkClicked}
                    >
                      {/* <span className="material-symbols-outlined"> */}
                      {iconProvider(route.icon)}
                      {/* </span> */}
                      <div className="link_text">{route.title}</div>
                      <span className="material-symbols-outlined" id="arrows">
                        {" "}
                        {!bookmarkState ? "chevron_right" : "expand_more"}
                      </span>
                    </button>
                  )}

                  <Filter
                    filterItemList={route.subRoutes}
                    FilterIcons={LightbulbOutlinedIcon}
                    filterByTitle={"Idea cards"}
                    enableSearch={false}
                    enableSelectAll={true}
                    showFilter={bookmarkState}
                    setShowFilter={setBookmarkState}
                    filterType={"ListView"}
                    setSelectedRoutes={setSelectedRoutes}
                    page={"ListView"}
                  />
                </div>
              );
            }

            return (
              <button
                key={index}
                className={
                  filter.showHighlights
                    ? `activeState_Modified ${`background-filter-heading-${lightDarkMode}`} ${`text-primary-${lightDarkMode}`}`
                    : `link_Modified bg-transparent `
                }
                id={route.name + "-" + index}
                onClick={() =>
                  showMenuClickHandler(route.name, route.name + "-" + index)
                }
              >
                {/* <span className="material-symbols-outlined"> */}{" "}
                {iconProvider(route.icon)}
                {/* </span> */}
                <div className="link_text">{route.name}</div>
                {filter.showHighlights ? (
                  <div className="pr-[6px] ml: 1 cursor-pointer h-[30px] flex items-center justify-center ml-auto">
                    <CloseIcon sx={sortButtonsStyle} />
                  </div>
                ) : (
                  <div className="checkbox ml-auto">
                    <CommonInput
                      type="checkobx"
                      label="custom-style"
                      inputClass="filter-checkbox-wrapper"
                      wrapperClass="filter-checkbox filter-checkbox-no-margin"
                      name="name"
                      labelClass="input-label"
                    />
                  </div>
                )}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default function TilesViewMenu() {
  return (
    <div>
      <Structurerenderer />
      <div className="mt-[15px]">
        <hr />
      </div>
      <ContentListRenderer />
    </div>
  );
}
