// ExtensionInstallationPrompt.js
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { customLog } from "../../helperFunctions/customLogger";
import { getEnvironmentDependentStringVariable } from "../../helperFunctions/envVars";

var extensionIdChrome = getEnvironmentDependentStringVariable(
  "REACT_APP_CHROME_EXTENSION_ID"
);
const extensionInstallLink = `https://chrome.google.com/webstore/detail/${extensionIdChrome}`;
// var extensionIdFire = '1234'; // TODO process.env.REACT_APP_FIREFOX_EXTENSION_ID
/* global chrome, browser*/ //browser is the firefox version

function isChromiumBasedBrowser() {
  return /Chrome|Chromium/.test(navigator.userAgent);
}

function isExtensionSupported() {
  const userAgent = navigator.userAgent.toLowerCase();
  // Check for common desktop browsers that support extensions
  return /chrome|chromium|firefox|edge/.test(userAgent);
}

function isExtensionInstalled() {
  return localStorage.getItem("extensionInstalled") === "true";
}

const ToastContainer = styled(Paper)({
  backgroundColor: "var(--palette-primary-main)",
  color: "var(--palette-primary-contrastText)",
  borderRadius: "8px",
  padding: "16px",
  fontSize: "8px",
  "& .MuiButton-containedPrimary": {
    backgroundColor: "#ff6600", // Change to your desired color
  },
});

const InstallButton = styled(Button)({
  padding: "8px 16px",
  borderRadius: "8px",
  cursor: "pointer",
});

const LinkButton = styled(Button)({
  padding: "2px 2px",
  borderRadius: "8px",
  cursor: "pointer",
  fontSize: "9px",
});

function ExtensionInstallationPrompt() {
  useEffect(() => {
    // Check if the extension is installed
    const checkExtensionInstalled = () => {
      if (typeof chrome !== "undefined" && chrome.runtime) {
        // For Chrome
        chrome.runtime.sendMessage(
          extensionIdChrome, // Replace with your actual extension ID
          { event: "buttonClicked" }, // easy case to check the extension is installed
          (response) => {
            if (!response) {
              localStorage.setItem("extensionInstalled", "false");
            } else {
              // Mark the extension as installed
              localStorage.setItem("extensionInstalled", "true");
            }
          }
        );
      } else if (typeof browser !== "undefined" && browser.runtime) {
        console.warn("Firefox extension support is not yet implemented.");
        localStorage.setItem("extensionInstalled", "false");

        // For Firefox
        /*
                browser.runtime.sendMessage(
                    extensionIdFire, // Replace with your actual extension ID
                    (response) => {
                        if (!response) {
                            setExtensionInstalled(false);
                        } else {
                            setExtensionInstalled(true);
                        }
                    }
                );
                */
      } else {
        // Handle other browsers, including Safari
        console.warn("Extension support not available for this browser.");
        localStorage.setItem("extensionInstalled", "false");
      }
    };

    checkExtensionInstalled();
  }, []);

  return null; // Return null to prevent rendering anything in the DOM
}

const handleInstallClick = () => {
  if (!isExtensionInstalled()) {
    // if (typeof chrome !== 'undefined' && chrome.webstore) { chrome.webstore is not accessible, // TODO check if same error happens in Firfox later
    if (typeof chrome !== "undefined") {
      // For Chrome
      window.open(
        extensionInstallLink,
        "_blank" // Open in a new tab or window
      );
      customLog("Chrome extension installation triggered.");
    } else if (typeof browser !== "undefined") {
      // && browser.webstore) {
      // For Firefox
      throw new Error(
        `Unfortunately, FireFox browser is not yet supported,\n but it will be available in the future, please use Chrome or Chromium based browser instead.`
      );
      /*
                window.open(
                    `https://addons.mozilla.org/firefox/addon/${extensionIdFire}`,
                    '_blank' // Open in a new tab or window
                );
                */
    } else {
      // Handle other browsers that do not support the installation
      // You can display a message or redirect to the extension store page.
      customLog("Extension installation not supported in this browser.");
      window.open(
        extensionInstallLink,
        "_blank" // Open in a new tab or window
      );
    }
  }
};

export const installExtensionToast = (
  position = toast.POSITION.BOTTOM_LEFT
) => {
  const evaluatedInstalledAndIsChromBased =
    !isExtensionInstalled() && isChromiumBasedBrowser();
  // customLog(`Before calling the toast check: ${evaluatedInstalledAndIsChromBased}`)
  if (evaluatedInstalledAndIsChromBased) {
    // Show the toast notification if the extension is not installed, and the browser is Chromium-based
    customLog("Toast condition met. Triggering toast...");
    toast.info(
      <ToastContainer>
        <Stack spacing={1}>
          <Typography>
            Please install the extension to use this synchronization feature.
          </Typography>
          <Typography variant="caption">
            (Chrome, Brave or Edge browser)
          </Typography>
          <InstallButton
            variant="contained"
            color="primary"
            onClick={handleInstallClick}
          >
            Install our DeepRead Extension
          </InstallButton>
          <LinkButton
            onClick={() => {
              navigator.clipboard.writeText(extensionInstallLink);
            }}
          >
            Copy the Installation link to clipboard
          </LinkButton>
        </Stack>
      </ToastContainer>,
      {
        position: position,
        autoClose: false, // The toast won't auto-close until the user clicks the button
        className: "custom-toast-container", // Add a custom class for the toast container (optional)
      }
    );
  }
  // specifically for people using for example firefox or another non chromium based browser
  else if (!isChromiumBasedBrowser()) {
    //if (isExtensionSupported()) {
      toast.info(
        "We're excited to announce that our extension is currently available for Chrome and Chromium browsers.\nSupport for other browsers is on our radar, and we'll keep you updated as we expand compatibility.\nThank you for using DeepRead!",
        {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: false,
        }
      );
    //}
  } else {
    // TODO don't show anything or create some different user notification
    customLog("Toast condition not met. Toast will not be triggered.");
  }
};


export default ExtensionInstallationPrompt;