// NewsPost.js
import React from 'react';
import DOMPurify from 'dompurify';
//import './NewsPost.css'; // Can be used, but just tried... and left it for later if needed

const NewsPost = ({ title, date, content }) => {

  // Regular expression to remove the <h1> tag containing the title
  const titleRegex = new RegExp(`<h1[^>]*>${title}</h1>`, 'i');
  const sanitizedContent = DOMPurify.sanitize(content);
  let contentWithoutTitle = sanitizedContent.replace(titleRegex, '').trim();

  // Remove excessive newlines or spaces
  contentWithoutTitle = contentWithoutTitle.replace(/(\n\s*\n)+/g, '\n'); // Replaces multiple newlines with a single newline
  contentWithoutTitle = contentWithoutTitle.replace(/(\s\s+)/g, ' '); // Replaces multiple spaces with a single space

  return (
    <div className="news-post">
      <h2 className="news-title">{title}</h2>
      <div className="news-date">{new Date(date).toLocaleDateString()}</div>
      <div
        className="news-content"
        dangerouslySetInnerHTML={{ __html: contentWithoutTitle }}
      />
    </div>
  );
};
export default NewsPost;
