import { useState } from "react";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";

/* STYLES */
import { CardBook, CardBookImg, CardBookTitle, CardBookAuthor } from "./styled";

import AllowBookSelect from "../Common/AllowBookSelect";
import { getCurrentBook } from "../../Utils/Features/librarySlice";

function BookDetails(props) {
  const [isOpenBookSelectionDropDown, setIsOpenBookSelectionDropDown] =
    useState(false);

  const book = useSelector((state) => getCurrentBook(state));

  return (
    <CardBook className="mt-1 pl-2 relative w-full h-[63px]">
      <div className="absolute w-[98%] z-[3] allowBookSelectCustom top-[8px]">
        <AllowBookSelect
          pageTitle={"bookDetails"}
          isOpenBookSelectionDropDown={isOpenBookSelectionDropDown}
          setIsOpenBookSelectionDropDown={setIsOpenBookSelectionDropDown}
        />
      </div>
      {!book && !isOpenBookSelectionDropDown ? (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{
            width: "85%",
            height: "56px",
            position: "absolute",
            zIndex: "2",
            paddingLeft: "20px",
            marginTop: "4px",
          }}
        >
          <CardBookTitle>Please select a book.</CardBookTitle>
        </Stack>
      ) : null}

      <>
        {book && !isOpenBookSelectionDropDown && (
          <>
            <div className=" w-full">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{
                  width: "85%",
                  height: "60px",
                  position: "absolute",
                  zIndex: "2",
                }}
              >
                <CardBookImg
                  classname="Card-image-w50-h50 h-[45px]"
                  src={book?.img_path}
                  alt={book?.title}
                />
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={0}
                  sx={{ width: "90%" }}
                >
                  <CardBookTitle pageTitle={props?.pageTitle}>
                    {book?.title || ""}
                  </CardBookTitle>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    position="relative"
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <CardBookAuthor>
                      {book?.author
                        ?.replace(/;/g, " & ")
                        .split(" & ")
                        .map((name) => name.split(", ").reverse().join(" "))
                        .join(" & ")}
                    </CardBookAuthor>
                  </Stack>
                </Stack>
              </Stack>
            </div>
          </>
        )}
      </>
    </CardBook>
  );
}

export default BookDetails;
