import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ApiStatus } from "./ApiStatus";
import { getHeaders } from "./authSlice";
import { getApiUrl } from "../../helperFunctions/envVars";
const apiUrl = getApiUrl();
const apiRoot = apiUrl + "/api";

export const fetchExport = createAsyncThunk(
  "content/export",
  async ({ bookId, exportType }, thunkAPI) => {
    const response = await axios.get(
      `${apiRoot}/content/export?book_id=${bookId}&type=${exportType}`,
      getHeaders()
    );
    return response.data.data;
  }
);

const initialState = {
  exportStatus: ApiStatus.NotRun,
  export: null,
};

export const contentSlice = createSlice({
  name: "contentSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchExport.pending, (state) => {
        state.exportStatus = ApiStatus.Pending;
      })
      .addCase(fetchExport.fulfilled, (state, { payload }) => {
        state.exportStatus = ApiStatus.Fulfilled;
        state.export = payload;
      })
      .addCase(fetchExport.rejected, (state) => {
        state.exportStatus = ApiStatus.Rejected;
      });
  },
});

export const contentReducer = contentSlice.reducer;
export default contentReducer;
