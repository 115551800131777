import React, { useEffect } from "react";
import {
    Authenticator,
    View,
    Image,
    Text,
    useTheme
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import deepreadLogo from "../../Assets/LogoHalfSvg_small.png"
import { useNavigate } from "react-router-dom"; // Import the useHistory hook

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Deepread logo"
          src={deepreadLogo}
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
}

function AmplifyLoginSignupUI() {
  const navigate = useNavigate(); // Initialize the useHistory hook

  // Redirect to the /library page when the component mounts, not needed as this should be done automatically,
  // but we can use this to navigate the user where we want on firt login ;)
  useEffect(() => {
     // navigate("/library"); <- here navigate to feed, library or tutorial for example on first time visit
   }, [navigate]);


  return (
    <Authenticator components={components}>
      {/* No need to show any content */}
    </Authenticator>
  );
}

export default AmplifyLoginSignupUI;