export const handleImageLoad = (event) => {
  const img = event.target;
  
  // remove already present class to add updated class.
  img.classList.remove("horizontal-image");
  img.classList.remove("vertical-image");
  const aspectRatio = img.width / img.height;

  // You can adjust the threshold based on your specific needs
  // This one is used to check that the image is vertical or horizontal and based on that it will add a class to display in full
  // width for horizontal images.
  const verticalThreshold = 1.0;

  const isVertical = aspectRatio < verticalThreshold;
  if (isVertical) {
    img.classList.add("vertical-image");
  } else {
    img.classList.add("horizontal-image");
  }
};