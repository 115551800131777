import { useEffect } from "react";
import NewsPage from "../../components/News/NewsPage";

import { setNewsLastRead } from "../../Utils/Features/userConfigSlice";
import { useDispatch } from "react-redux";

const News = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNewsLastRead());
  }, []);

  return (
    <div className="feedParentContainer">
      <NewsPage categorySlug="News" />{" "}
      {/* Fetch posts with the category slug "news" */}
      {/* Add more instances as needed with different endpoints */}
    </div>
  );
};

export default News;
