import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchIdeaCardData } from "../../../helperFunctions/apiFunctions";
import {
  getIdeaCardById,
  updateIdeaCardRelation,
} from "../../../Utils/Features/librarySlice";

import { IdeaCardIcon, RemoveIcon, DeleteIcon } from "./IconsStyled";

import { LinkIdeaCard } from "./LinkIdeaCard";
import Search from "../../Common/Search";
import "../../../pages/MyLibrary/MyLibrary.css";
import useOutsideClick from "../../../helperFunctions/useOutsideClick";

export const LinkStructure = ({ ideaCardId, tabHeight }) => {
  const dispatch = useDispatch();
  const ideaCard = useSelector((state) => getIdeaCardById(state, ideaCardId));

  const [link, setLink] = useState("");
  // linkData should never be null for the search to function
  const [linkData, setLinkData] = useState(ideaCard.linked_structure ?? []);

  const [ideaCardsSearchData, setIdeaCardsSearchData] = useState([]);
  const [deleteMode, setDeleteMode] = useState(null);
  const linkedIdeasRef = useRef(null);
  const deleteIconRef = useRef(null);

  useEffect(() => {
    setLinkData(ideaCard.linked_structure ?? []);
    setIdeaCardsSearchData([]);
    setLink("");
  }, [ideaCard]);

  useOutsideClick([linkedIdeasRef, deleteIconRef], setDeleteMode);

  const handleDelete = (idea_id) => {
    const tempLinkData = linkData.filter((idea) => idea.idea_id !== idea_id);

    dispatch(
      updateIdeaCardRelation({
        ideaCardId: ideaCardId,
        newData: {
          linked_structure: tempLinkData,
        },
      })
    );
    setLinkData(tempLinkData);
  };

  useEffect(() => {
    if (deleteMode) {
      setIdeaCardsSearchData([]);
    }
  }, [deleteMode]);

  const handleKeyDown = (event) => {};

  const handleTags = (event) => {
    const searchString = event?.target?.value ?? "";
    setLink(searchString);
    if (searchString.length) {
      fetchIdeaCardData({ title: searchString }).then((ideaCardData) => {
        setIdeaCardsSearchData(ideaCardData);
      });
    } else {
      setIdeaCardsSearchData([]);
    }
  };

  return (
    <>
      <div className="iconTopContainer">
        {!ideaCard?.read_only && (
          <DeleteIcon
            color={deleteMode ? "red" : undefined}
            onClick={(event) => {
              setDeleteMode(!deleteMode);
            }}
            ref={deleteIconRef}
          />
        )}
      </div>
      <div
        className="overflow-auto flex-start"
        style={{
          height: tabHeight,
        }}
      >
        {!ideaCard?.read_only && (
          <>
            <div
              style={{ display: "flex", alignItems: "center", gap: "2.7px" }}
            >
              <IdeaCardIcon />
              <div className="relative w-full ml-2">
                <Search
                  searchQuery={link}
                  setSearchQuery={setLink}
                  placeholder={"Write here"}
                  handleSearchChange={handleTags}
                  handlePressEnter={handleKeyDown}
                />
              </div>
            </div>
            <LinkIdeaCard
              ideaCardToLinkId={ideaCardId}
              resultCards={ideaCardsSearchData}
              linkedCardsData={linkData}
              setLinkedCardsCallback={setLinkData}
            />
          </>
        )}
        <div ref={linkedIdeasRef}>
          {linkData?.map((item, index) => {
            return (
              <div
                key={item.idea_id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "3px 0px",
                }}
                onClick={() => {
                  if (deleteMode) handleDelete(item.idea_id);
                }}
                className={deleteMode ? "deleteHighlight" : ""}
              >
                {deleteMode ? <RemoveIcon /> : <IdeaCardIcon />}
                <p
                  style={{
                    fontWeight: 600,
                    marginLeft: "8px",
                    color: "var(--fontDarkColor)",
                  }}
                >
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
