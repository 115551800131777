/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_identity_pool_id": "eu-north-1:a8fe3256-3c18-46de-bf18-fd6af14c5736",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_ThJQQn2kS",
    "aws_user_pools_web_client_id": "45nv65moucbg5at560vtc0r0rg",
    "oauth": {
        "domain": "deepreadlogin511ff20d-511ff20d-staging.auth.eu-north-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app.deepread.com,https://frontend.deepread.com,https://dev.deepread.com,https://deepread.demo1.bytestechnolab.com/,https://louse-credible-monkfish.ngrok-free.app/",
        "redirectSignOut": "http://localhost:3000/,https://app.deepread.com,https://frontend.deepread.com,https://dev.deepread.com,https://deepread.demo1.bytestechnolab.com/,https://louse-credible-monkfish.ngrok-free.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
