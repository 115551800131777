import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";

import "../../pages/MyLibrary/MyLibrary.css";

import {
  getBookById,
  getIdeaCardById,
  getChapterByHighlightId,
  updateChapterTags,
  fetchIdeaCardTags,
  fetchAllTags,
} from "../../Utils/Features/librarySlice";
import { ApiStatus } from "../../Utils/Features/ApiStatus.js";
import TagPicker from "../Common/TagPicker";

export const typeEnum = {
  IDEA_CARD: "ideaCard",
  BOOK: "book",
  CHAPTER: "chapter",
};

export const Topics = ({
  id,
  type,
  updateHandler,
  currentLabel,
  tagsBeingAddedToLabel,
  setTagsBeingAddedToLabel,
}) => {
  const dispatch = useDispatch();
  const [listOpen, setListOpen] = useState(false);

  const item = useSelector((state) => {
    if (type === typeEnum.IDEA_CARD) {
      return getIdeaCardById(state, id);
    }
    if (type === typeEnum.BOOK) {
      return getBookById(state, id);
    }
    if (type === typeEnum.CHAPTER) {
      return state.library.listViewData;
    }
  });

  const tagsList = useSelector((state) => {
    
    return state.library.allTags;
  });

  const allTagsStatus = useSelector(
    (state) => state.library.allTagsStatus
  );
  const parentBook = useSelector((state) =>
    type === typeEnum.IDEA_CARD ? getBookById(state, item.book_id) : null
  );
  const parentBookContent = useSelector((state) =>
    type === typeEnum.IDEA_CARD ? state.library.listViewData : null
  );
  const parentChapter = useSelector((state) =>
    type === typeEnum.IDEA_CARD
      ? getChapterByHighlightId(state, item.highlight_id)
      : null
  );
  const parentChapterTags =
    parentBookContent && parentChapter
      ? parentBookContent?.tags?.find(
          (tagItem) => tagItem.label === parentChapter.label
        )?.tags
      : [];

  const [tags, setTags] = useState();
  const [tagsData, setTagsData] = useState(
    type === typeEnum.CHAPTER
      ? item?.tags?.find((tagItem) => tagItem.label === currentLabel)?.tags
      : item?.tags
  );

  useEffect(() => {
    setTagsData(
      type === typeEnum.CHAPTER
        ? item?.tags?.find((tagItem) => tagItem.label === currentLabel)?.tags
        : item?.tags
    );
  }, [currentLabel, item, type]);

  useEffect(() => {
    if (
      type === typeEnum.IDEA_CARD &&
      tagsList?.length === 0 &&
      allTagsStatus === ApiStatus.NotRun
    ) {
      dispatch(fetchAllTags());
    }
  }, [dispatch, tagsList, type, allTagsStatus]);

  const handleDelete = (item) => {
    const tempTagsSet = new Set(tagsData ?? []);
    tempTagsSet.delete(item);
    const tempTags = Array.from(tempTagsSet);

    setTagsData(tempTags);

    if (type === typeEnum.CHAPTER) {
      dispatch(
        updateChapterTags({
          contentId: id,
          newData: { label: currentLabel, tags: tempTags },
        })
      );
    } else {
      updateHandler(id, {
        tags: tempTags,
      });
    }
  };

  useEffect(() => {
    if (tags?.length > 0) {
      // TODO: check if this if is correct (seems to work, but can currentLabel ever be different from tagsBeingAddedLabel, not sure):
      if (type !== typeEnum.CHAPTER || currentLabel === tagsBeingAddedToLabel) {
        setTagsData((oldTags) => [...(oldTags ?? []), tags]);
        setTags("");
        updateHandler(id, {
          tags: [...(tagsData ?? []), tags],
        });
      }
    }
    //  Passing all dependency leads to infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  return (
    <>
      {!item?.read_only ? (
        type === typeEnum.CHAPTER ? (
          <>
            {listOpen ? null : (
              <span
                className={`w-[30px] h-[30px] text-[14px] border border-1 rounded-[50%] flex justify-center items-center`}
                onClick={(e) => {
                  e.stopPropagation();
                  setTagsBeingAddedToLabel(currentLabel);
                  setListOpen(true);
                }}
              >
                #
              </span>
            )}
            {listOpen && (
              <div className="tagListWrapper">
                {/* TODO: could we do this with just one picker: */}
                <TagPicker
                  presentTags={tagsList}
                  setTags={setTags}
                  listOpen={listOpen}
                  setListOpen={setListOpen}
                  type={type}
                />
              </div>
            )}
          </>
        ) : (
          <div className="mb-4">
            {/* TODO: could we do this with just one picker: */}
            <TagPicker
              setTags={setTags}
              presentTags={tagsList}
              setListOpen={setListOpen}
              listOpen={listOpen}
              type={type}
            />
          </div>
        )
      ) : (
        <div className="mb-4"></div>
      )}
      {parentBook?.tags?.length > 0 && (
        <LevelTags level={typeEnum.BOOK} tagsData={parentBook?.tags} />
      )}
      {parentChapterTags?.length > 0 && (
        <LevelTags level={typeEnum.CHAPTER} tagsData={parentChapterTags} />
      )}
      <LevelTags
        level={typeEnum.IDEA_CARD}
        tagsData={tagsData}
        handleDelete={item?.read_only ? undefined : handleDelete}
      />
    </>
  );
};

const LevelTags = ({ level, tagsData, handleDelete }) => {
  const uniqueTagsData = [...new Set(tagsData)];
  return (
    <div className="flex items-center gap-[5px] flex-wrap">
      {uniqueTagsData &&
        uniqueTagsData?.map((item, index) => {
          const title =
            level === typeEnum.CHAPTER
              ? "Tag on chapter level. Please go to list view to adjust.​"
              : level === typeEnum.BOOK
              ? "Tag on book level Please go to book to adjust."
              : "";
          // const color = level === typeEnum.IDEA_CARD ? "#717171" : "#B2B2B2";
          const paddingRight = level === typeEnum.IDEA_CARD ? "" : "4px";
          return (
            <div
              key={item}
              style={{ margin: "3px 0" }}
              className="tagsHorizontal cursor-pointer"
              title={title}
              onClick={() => handleDelete && handleDelete(item)}
            >
              <Chip
                sx={{
                  fontWeight: 600,
                  color: "var(--fontColor)",
                  height: "30px",
                  fontSize: "14px",
                  paddingTop: "1px",
                  paddingRight: paddingRight,
                  opacity: level === typeEnum.IDEA_CARD ? "1" : "0.5",
                }}
                label={`# ${item}`}
                onDelete={() => {
                  handleDelete && handleDelete(item);
                }}
                deleteIcon={<TagIcon level={level} readOnly={!handleDelete} />}
              />
            </div>
          );
        })}
    </div>
  );
};

const TagIcon = ({ level, readOnly }) => {
  const tagIconStyle = {
    marginRight: "5px",
    marginLeft: "-6px",
    marginBottom: "3px",
    color: "var(--fontColor)",
  };
  if (level === typeEnum.IDEA_CARD) {
    if (readOnly) {
      return null;
    }
    return <CloseIcon sx={tagIconStyle} />;
  }
  if (level === typeEnum.BOOK) {
    return <MenuBookIcon sx={tagIconStyle} />;
  }
  return <FormatAlignRightIcon sx={tagIconStyle} />;
};
