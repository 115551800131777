import { amplifyLogout } from "../../helperFunctions/global";

export const Logout = ({ children }) => {
  return (
    <div className="feedParentContainer">
      <div className={`messageBoxLayout w-full`}>
        {children}
        <div style={{ padding: "20px" }}>
          <div
            style={{
              position: "absolute",
              left: "30%",
              padding: "5px 10px 5px 10px",
              border: "2px solid var(--primaryColor)",
              borderRadius: "30px",
              color: "var(--primaryColor)",
            }}
          >
            <button
              onClick={() => {
                amplifyLogout();
              }}
            >
              Log in again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
