import React, { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Component for keeping the feed tabs at fairly consistent sizes while switching between
const FeedTab = ({ tabMinHeight, children }) => {
  const myDivRef = useRef(null);
  const [scrollHeight, setScrollHeight] = useState(tabMinHeight);
  const [scrollImgHeight, setScrollImgHeight] = useState(tabMinHeight);
  const [readMore, setReadMore] = useState(false);

  const lightDarkMode = useSelector(
    (state) => state?.lightDarkModeSlice?.currentMode
  );

  useLayoutEffect(() => {
    setScrollHeight(myDivRef.current.scrollHeight);
    setScrollImgHeight(tabMinHeight);
  }, [tabMinHeight, readMore]);

  return (
    <div className="w-full">
      <ul
        style={{
          height: readMore ? `${scrollHeight}px` : `${scrollImgHeight}px`,
        }}
        className={` readmore`}
        ref={myDivRef}
        onClick={() => {
          setReadMore(!readMore);
        }}
      >
        {children}
        {scrollHeight && scrollHeight > tabMinHeight && (
          <button
            className={`readmore-link  flex justify-center items-center w-full background-shadow-${lightDarkMode}`}
          ></button>
        )}
      </ul>
    </div>
  );
};

export default FeedTab;
