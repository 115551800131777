
export const lowercaseAllButFirstLetter = (str) => {
    if(!str) return str;
    return str
    .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
    .replace(/^[^ ]/g, match => (match.toUpperCase()));
}

export const createKeyFromString = (str) => {
  let hash = 0;

  if (str.length === 0) return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};