export const amazonBookSyncIntervalMinutes = 5;
const hoursToMilliseconds = 1000 * 60 * 60;
const minutesToMilliseconds = 1000 * 60;

export const executeWithIncrementalDelay = (
  timer,
  setTimer,
  functionToExecute,
  timeInMilliseconds
) => {
  if (timer) {
    clearTimeout(timer);
    setTimer(null);
  }
  setTimer(
    setTimeout(() => {
      functionToExecute();
      setTimer(null);
    }, timeInMilliseconds)
  );
};

export const getHoursInMilliseconds = (hours) => {
  return hours * hoursToMilliseconds;
};

export const getMinutesInMilliseconds = (minutes) => {
  return minutes * minutesToMilliseconds;
};

export const hasIntervalPassed = (
  compareTime,
  intervalHours,
  intervalMinutes
) => {
  const timeNow = new Date().getTime();
  const timePassed = timeNow - compareTime;
  let intervalTime = getHoursInMilliseconds(intervalHours);
  if (intervalMinutes) {
    intervalTime += getMinutesInMilliseconds(intervalMinutes);
  }

  if (timePassed < intervalTime) return true;
  return false;
};

export const formatDateString = (date) => {
  if (date) return date.toString().substring(0, 24);
  return "";
};

export const formatTimeString = (date) => {
  if (date) return date.toString().substring(16, 24);
  return "";
};

export const formatDateMonthYear = (timestamp) => {
  //  Create a new Date object using the timestamp
  const date = new Date(timestamp);

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day, month, and year from the Date object
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Create the formatted date string
  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};
