import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { restartSyncProcess } from "../../Utils/Features/amazonSyncSlice";
import { store } from "../../Utils/Store/Store";
import { customLog } from "../../helperFunctions/customLogger";
import { loadExternalScript } from "../../helperFunctions/loadExternalScript";

class AmazonConnector {
  static SESSION_CHECK_INTERVAL = 30 * 60 * 1000; // 30 minutes
  static lastAuthTime = null;
  static refreshTimer = null;
  static isRefreshing = false;

  static async authenticate() {
    return new Promise(async (resolve, reject) => {
      try {
        // Check if we're already in a refresh operation
        if (AmazonConnector.isRefreshing) {
          await AmazonConnector.waitForRefresh();
        }

        let user = await Auth.currentAuthenticatedUser();
        if (user) {
          if (!user.username.includes("amazon")) {
            store.dispatch(restartSyncProcess(true));
            await Auth.federatedSignIn({ provider: "LoginWithAmazon" });
            resolve(null);
          } else {
            // Update last authentication time
            AmazonConnector.lastAuthTime = Date.now();
            AmazonConnector.setupRefreshTimer();
          }
        }

        resolve(user);
      } catch (error) {
        if (AmazonConnector.isSessionExpiredError(error)) {
          try {
            await AmazonConnector.handleSessionExpiration();
            // Retry authentication after handling expiration
            const user = await Auth.currentAuthenticatedUser();
            resolve(user);
          } catch (refreshError) {
            AmazonConnector.showRefreshErrorToast();
            reject(refreshError);
          }
        } else {
          AmazonConnector.showAuthErrorToast();
          reject(error);
        }
      }
    });
  }

  static async clearUserAndReauthenticate() {
    AmazonConnector.isRefreshing = true;
    try {
      // Show a non-intrusive toast notification
      toast.info("Refreshing your Amazon connection...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });

      // Clear the cached user without signing out
      await Auth.cleanCachedItems();
      
      // Clear any existing timers
      if (AmazonConnector.refreshTimer) {
        clearTimeout(AmazonConnector.refreshTimer);
      }

      // Clear last auth time
      AmazonConnector.lastAuthTime = null;

      // Force a new federated sign in with Amazon
      await Auth.federatedSignIn({ provider: "LoginWithAmazon" });

      toast.success("Please sign in with your Amazon account", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
    } finally {
      AmazonConnector.isRefreshing = false;
    }
  }

  static isSessionExpiredError(error) {
    // Add specific error checks based on Amazon's error responses
    return (
      error.name === "NotAuthorizedException" ||
      error.message?.includes("expired") ||
      error.message?.includes("token") ||
      error.code === "TokenExpiredError"
    );
  }

  static async handleSessionExpiration() {
    AmazonConnector.isRefreshing = true;
    try {
      // Show a non-intrusive toast notification
      toast.info("Refreshing your Amazon connection...", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000
      });

      // Attempt to refresh the session
      //const session = await Auth.currentSession();
      await Auth.currentSession();
      await Auth.currentAuthenticatedUser({ bypassCache: true });
      
      AmazonConnector.lastAuthTime = Date.now();
      AmazonConnector.setupRefreshTimer();

      toast.success("Connection refreshed successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000
      });
    } finally {
      AmazonConnector.isRefreshing = false;
    }
  }

  static async waitForRefresh() {
    // Wait for the current refresh operation to complete
    return new Promise(resolve => {
      const checkRefresh = () => {
        if (!AmazonConnector.isRefreshing) {
          resolve();
        } else {
          setTimeout(checkRefresh, 100);
        }
      };
      checkRefresh();
    });
  }

  static setupRefreshTimer() {
    // Clear any existing timer
    if (AmazonConnector.refreshTimer) {
      clearTimeout(AmazonConnector.refreshTimer);
    }

    // Set up new timer
    AmazonConnector.refreshTimer = setTimeout(async () => {
      try {
        await AmazonConnector.handleSessionExpiration();
      } catch (error) {
        customLog("Auto-refresh failed:", error);
      }
    }, AmazonConnector.SESSION_CHECK_INTERVAL);
  }

  static showRefreshErrorToast() {
    toast.error(
      <div>
        Unable to refresh your Amazon connection. Please{" "}
        <strong>log out</strong> and sign in again.
      </div>,
      { position: toast.POSITION.TOP_RIGHT, autoClose: false }
    );
  }

  static showAuthErrorToast() {
    toast.error(
      <div>
        Authentication failed. Please <strong>log out</strong> and try
        again.
      </div>,
      { position: toast.POSITION.TOP_RIGHT, autoClose: false }
    );
  }

  static async getCurrentUserCredentials() {
    try {
      const credentials = await Auth.currentCredentials();
      customLog(`User credentials: ${JSON.stringify(credentials)}`);
      return credentials;
    } catch (error) {
      console.error("Error retrieving user credentials:", error);
      throw error;
    }
  }

  static async getTokenAndSession() {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    return [accessToken.getJwtToken(), session];
  }

  // This method can replace the old disconnect() or be used alongside it
  static async clearUserState() {
    try {
      // Clear cached items including tokens
      await Auth.cleanCachedItems();
      
      // Clear local state
      if (AmazonConnector.refreshTimer) {
        clearTimeout(AmazonConnector.refreshTimer);
      }
      AmazonConnector.lastAuthTime = null;
      AmazonConnector.isRefreshing = false;

      customLog("User state cleared successfully");
      return true;
    } catch (error) {
      console.error("Error clearing user state:", error);
      return false;
    }
  }

  static async disconnect() {
    if (AmazonConnector.refreshTimer) {
      clearTimeout(AmazonConnector.refreshTimer);
    }
    await Auth.signOut();
  }

  static async authenticateFlowAndTriggerExtension(user) {
    try {
      // Check if we need to clear and reauthenticate first
      if (!user || !user.username.includes("amazon")) {
        await AmazonConnector.clearUserAndReauthenticate();
        user = await Auth.currentAuthenticatedUser();
      }

      await AmazonConnector.getCurrentUserCredentials();
      const [aws_auth_token, session] = await AmazonConnector.getTokenAndSession();

      await loadExternalScript(
        "https://api-cdn.amazon.com/sdk/login1.js",
        function () {}
      );

      customLog(
        "authenticateFlowAndTriggerExtension, should be an amazon user",
        session,
        user
      );

      const { userId } = store.getState().auth;
      customLog(`before calling Sync-Books => userId: ${userId}`);

      if (!aws_auth_token) {
        throw new Error("Error retrieving aws_auth_token");
      }

      return aws_auth_token;
    } catch (error) {
      console.error("Error in authenticateFlowAndTriggerExtension:", error);
      throw error;
    }
  }
}

export default AmazonConnector;