import { styled, css } from "@mui/material/styles";

export const CardStructureBook = styled("div")(
  ({ theme }) =>
    css`
      border: 1px solid var(--borderColors);
      border-radius: 12px;
      background-color: var(--cardBackgroundColor);
      padding: 7px;
      overflow: hidden auto;
      overflow-y: auto;
      width: 100%;
    `
);

export const TilesCardStructureBook = styled("div")(
  () =>
    css`
      border: 1px solid var(--borderColors);
      border-radius: 12px;
      background-color: var(--cardBackgroundColor);
      padding: 7px;
      padding-right: 0px;
      overflow: hidden auto;
      overflow-y: auto;
      width: 100%;

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: var(--primaryDarkColor);
        border-radius: 10px;
        border: 3px solid var(--primaryDarkColor);
      }
    `
);

export const ChaptersUl = styled("ul")(
  ({ theme }) =>
    css`
      padding: 0;
      margin: 0;
      margin-left: 15px;
      border-left: 1px solid var(--greyColor);
    `
);

export const ChaptersLi = styled("li")(
  ({ theme }) => css`
    cursor: pointer;
    color: var(--fontDarkColor);
    list-style: none;
    padding: 8px;
    // margin-bottom: 10px;
    // margin-left: 20px;
  `
);
